<template>
    <el-dialog
        :title="addSubscribeShow ? '订阅到邮箱' : `已订阅（${subscribedCount}）`"
        :visible.sync="dialogVisible"
        width="650px"
        :show-close="false"
    >
        <div class="subscribe-modal-content">
            <div v-show="!addSubscribeShow" class="subscribed-close-btn" @click="handleCancel"></div>
            <div v-if="addSubscribeShow && subscribedCount > 0" class="add-subscrib-btn has-subscribed" @click="toggleAddSubscribeShow(false)" id="subscribedBtn">已订阅({{subscribedCount}})</div>
            <div v-if="!addSubscribeShow" class="add-subscrib-btn" @click="toggleAddSubscribeShow(true)">
                <i class="icon-new icon-add-new"></i>
                添加订阅
            </div>
            <div v-show="addSubscribeShow" class="add-subscribe">
                <ul>
                    <li class="add-subscribe-item">
                        <label><i>*</i>报表名称</label>
                        <input
                            class="add-subscribe-item-input"
                            type="text"
                            placeholder="请输入报表名称"
                            v-model="subscriptionName"
                        >
                    </li>
                    <li class="add-subscribe-item" id="includeDataItem">
                        <label><i>*</i>所含数据</label>
                        <div class="drop-down">
                            <div class="drop-down-header" @click="toogleDropdownExpand">
                                <span v-if="!dropdownSelectedText" class="drop-down-header-placeholder">请选择数据</span>
                                <span v-if="dropdownSelectedText"
                                      class="drop-down-header-text">{{dropdownSelectedText}}</span>
                                <i :class="{expand: dropdownExpand}"></i>
                            </div>
                            <div class="drop-down-content" v-show="dropdownExpand">
                                <ul>
                                    <li v-for="(dropdown, index) in dropdownList"
                                        :key="'dropdown_'+ index"
                                        :class="['drop-down-content-item', dropdown.checked ? 'checked' : '']">
                                        <el-checkbox v-model="dropdown.checked" @change="handleDropdownCheck">{{dropdown.text}}</el-checkbox>
                                        <!-- <Checkbox :checked="dropdown.checked"
                                                  v-on:on-click="handleDropdownCheck(index)"/>
                                        <span @click="handleDropdownCheck(index)">{{dropdown.text}}</span> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="add-subscribe-item team-item" id="includeTeamItem">
                        <label><i>*</i>所含团队</label>
                        <!--<input type="text" placeholder="请选择团队">-->
                        <div class="add-subscribe-item-team">
                            <input
                                class="add-subscribe-item-input"
                                readonly
                                data-val="true"
                                data-val-required="请选择团队"
                                name="parentTeam"
                                type="text"
                                placeholder="请选择所属团队"
                                v-model="teamSelectedText"
                                @click="toggleTeamTreeExpand"
                            />
                            <i :class="{expand: teamTreeExpand}" @click="toggleTeamTreeExpand"></i>
                            <div class="team-tree" v-show="teamTreeExpand">
                                <Tree
                                    ref="teamTree"
                                    :child-nodes="teamData"
                                    alias-child-nodes="childTeams"
                                    :row-width="rowWidth"
                                    :default-expand-all="false"
                                    key="add-team"
                                    :select-single="false"
                                    :hide-count="true"
                                    v-on:current-node-change="handleCurrentNodeChange"
                                ></Tree>
                            </div>
                            <div class="team-selected" v-if="teamSelectedList.length > 0">
                                <ul v-if="!teamSelectedListExpand">
                                    <li class="team-selected-item"
                                        :key="'team_'+ index"
                                        v-for="(team, index) in teamSelectedList.slice(0, 5)">
                                        <span :title="team.teamName">{{team.teamName}}</span>
                                        <i @click="unSelectTeam(team)"></i>
                                    </li>
                                    <li
                                        class="team-selected-item expand"
                                        v-if="teamSelectedList.length > 5"
                                        @click="toogleTeamSelectedList"
                                    >
                                        等剩余{{ teamSelectedList.length - 5 }}组
                                    </li>
                                </ul>
                                <ul v-if="teamSelectedListExpand">
                                    <li class="team-selected-item" :key="'teamb_'+ index" v-for="(team,index) in teamSelectedList">
                                        <span :title="team.teamName">{{team.teamName}}</span>
                                        <i @click="unSelectTeam(team)"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="add-subscribe-item" id="emailAddressItem">
                        <label><i>*</i>邮箱地址</label>
                        <input
                            readonly
                            class="add-subscribe-item-input email-input"
                            type="text"
                            placeholder="请输入邮箱"
                            v-model="userInfo.email + '；'"

                        />
                    </li>
                    <li class="add-subscribe-item" id="sendPeriodItem">
                        <label><i>*</i>发送周期</label>
                        <ul class="period-list">
                            <li :class="['period-list-item', periodSelectedIndex.indexOf(0) != -1 ? 'checked' : '']">
                                <el-checkbox :value="periodSelectedIndex.indexOf(0) != -1" @change="(val) => handleTogglePeriod(val, 0)">日报</el-checkbox>
                            </li>
                            <li :class="['period-list-item', periodSelectedIndex.indexOf(1) != -1 ? 'checked' : '']">
                                <el-checkbox :value="periodSelectedIndex.indexOf(1) != -1" @change="(val) => handleTogglePeriod(val, 1)">周报（每周一）</el-checkbox>
                            </li>
                            <li :class="['period-list-item', periodSelectedIndex.indexOf(2) != -1 ? 'checked' : '']">
                                <el-checkbox :value="periodSelectedIndex.indexOf(2) != -1" @change="(val) => handleTogglePeriod(val, 2)">月报（每月一号）</el-checkbox>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <subscribed-list
                ref="subscribedList"
                v-show="!addSubscribeShow"
                v-loading="subscribedListLoading"
                :listData="subscribedListData"
                v-on:close-subscribe-modal="handleCancel"
                v-on:show-add-subscribe="reset();toggleAddSubscribeShow(true)"
                v-on:delete-subscribe="handleDeleteList"
            >
            </subscribed-list>
        </div>
        <span v-if="addSubscribeShow" slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import Tree from '#/component/common/tree/tree.vue';
    import SubscribedList from "./subscribedList.vue";

    import { statistics as statisticsUrl } from '#/js/config/api.json';

    export default {
        components: {
            Tree,
            SubscribedList,
        },
        data() {
            return {
                dialogVisible: false,
                subscriptionName: '',
                addSubscribeShow: true,
                subscribedListLoading: false,
                subscribedListData: [],
                dropdownExpand: false,
                dropdownList: [],
                dropdownSelectedList: [],
                periodSelectedIndex: [],
                teamTreeExpand: false,
                teamData: [],
                teamSelectedList: [],
                teamSelectedListExpand: false,
                rowWidth: 416,
                addSubscribeGuideData: [
                    {
                        el:'#subscribedBtn',
                        width: 100,
                        height: 50,
                        text:'点此查看已订阅的邮件内容'
                    },
                    {
                        el:'#includeDataItem',
                        width: 580,
                        height: 56,
                        text:'推荐全选所有数据，全方位帮您分析工作情况'
                    },
                    {
                        el:'#includeTeamItem',
                        width: 580,
                        height: 56,
                        text:'当前暂不支持个别成员数据订阅，删除团队中成员会导致删除所选团队，请谨慎操作'
                    },
                    {
                        el:'#emailAddressItem',
                        width: 580,
                        height: 56,
                        text:'仅支持发送至当前账号企业邮箱，请注意查收'
                    },
                    {
                        el:'#sendPeriodItem',
                        width: 580,
                        height: 56,
                        text:'根据您的周期设置，系统将自动发送邮件'
                    }
                ],
                subscribedGuide:[
                    {
                        el:'#cancelSubcribedBtn',
                        width: 100,
                        height: 36,
                        text:'取消订阅后无法撤回，且系统不再发送相关订阅邮件，请谨慎操作'
                    }
                ]
            }
        },
        props: {
            subscribedCount: Number,
        },
        watch: {
            addSubscribeShow:function(val,oldVal){
                if(!val){
                    this.getSubscribedList();
                }
            }
        },
        computed: {
            dropdownSelectedText() {
                let textArr = [];
                this.dropdownSelectedList.forEach(item =>{
                    textArr.push(item.text);
                })
                return textArr.join('；')
            },
            teamSelectedText() {
                let textList = [];
                this.teamSelectedList.forEach(item => {
                    textList.push(item.teamName);
                })

                return textList.join('；');
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            }
        },
        mounted() {
            this.getDataTypes();
            this.getTeamData();

            document.addEventListener('click', (ev) => {
                if (!$(ev.target).closest(this.$el.querySelector('.drop-down')).length) {
                    this.hideDropDown();
                }
            }, true);

            document.addEventListener('click', (ev) => {
                if (!$(ev.target).closest(this.$el.querySelector('.add-subscribe-item-team')).length) {
                    this.hideTeamTree();
                }
            }, true);
        },
        methods: {
            getDataTypes() {
                _request({
                    url: statisticsUrl.subscription_data_types,
                    method: 'GET'
                }).then(res => {
                    Object.keys(res).forEach(key => {
                        let obj = { text: res[key], value: key, checked: true };
                        this.dropdownList.push(obj);
                        this.dropdownSelectedList.push(obj);//默认全部选中
                    })
                })
            },
            getTeamData() {
                _request({
                    url: '/MyFirm/UsersFirmTeams',
                    method: 'GET'
                }).then(res => {
                    this.teamData = res;
                })
            },
            getSubscribedList() {
                this.subscribedListLoading = true;
                _request({
                    url: statisticsUrl.subscribe_list,
                    method: 'POST'
                }).then(res => {
                    let listData = [];
                    res.map((item)=>{
                        item.isFold = true;//用于成员展开收起
                        listData.push(item)
                    })
                    this.subscribedListData = listData;
                }).finally(() => {
                    this.subscribedListLoading = false;
                })
                let url = statisticsUrl.subscribe_list;
            },
            toggleAddSubscribeShow(status) {
                this.addSubscribeShow = status;

            },
            handleCurrentNodeChange(node) {
                this.teamSelectedList = this.$refs.teamTree.checkedNodes;
            },
            hideDropDown() {
                this.dropdownExpand = false;
            },
            toogleDropdownExpand(ev) {
                ev.stopPropagation();
                this.dropdownExpand = !this.dropdownExpand;
            },

            handleDropdownCheck(index) {
                // this.dropdownList[index].checked = !this.dropdownList[index].checked;
                this.dropdownSelectedList = [];
                this.dropdownList.forEach(item => {
                    if (item.checked) {
                        this.dropdownSelectedList.push(item);
                    }
                })
            },
            toggleTeamTreeExpand(ev) {
                ev.stopPropagation();
                this.teamTreeExpand = !this.teamTreeExpand;
            },
            hideTeamTree() {
                this.teamTreeExpand = false;
            },
            handleTogglePeriod(checked, value) {
                if(checked) {
                    this.periodSelectedIndex.push(value);
                }else {
                    let index = this.periodSelectedIndex.indexOf(value);
                    if(index != -1) {
                        this.periodSelectedIndex.splice(index, 1);
                    }else {
                        this.periodSelectedIndex.push(value);
                    }
                }
            },
            toogleTeamSelectedList() {
                this.teamSelectedListExpand = true;
            },
            unSelectTeam(team) {
                team.checked = false;
                let list = this.$refs.teamTree.checkedNodes;
                let targetList = list.filter((item) => {
                    return item.teamId != team.teamId;
                });
                this.$refs.teamTree.checkedNodes = targetList;
                this.teamSelectedList = this.$refs.teamTree.checkedNodes;
            },
            handleConfirm() {
                if(!this.subscriptionName.trim()) {
                    shortTips('报表名称不能为空');
                    return false;
                }

                let dropdownSelectedList = this.dropdownSelectedList;
                if(this.dropdownSelectedList.length == 0) {
                    shortTips('所含数据不能为空');
                    return false;
                }

                if(this.teamSelectedList.length == 0) {
                    shortTips('所含团队不能为空');
                    return false;
                }

                if(this.periodSelectedIndex.length == 0) {
                    shortTips('发送周期不能为空');
                    return false;
                }

                let subscribeDataTypes = [];
                this.dropdownSelectedList.forEach(item => {
                    subscribeDataTypes.push(item.value);
                })

                let teamIds = [];
                this.teamSelectedList.forEach(item => {
                    teamIds.push(item.teamId);
                })

                let paramJson = {
                    SubscriptionName: this.subscriptionName,
                    SubscribeDataTypes: subscribeDataTypes,
                    TeamIds: teamIds,
                    Email: this.userInfo.email
                };

                if(this.periodSelectedIndex.indexOf(0) != -1) {
                    paramJson.IsSubscriptionDay = true;
                }
                if(this.periodSelectedIndex.indexOf(1) != -1) {
                    paramJson.IsSubscriptionWeek = true;
                }
                if(this.periodSelectedIndex.indexOf(2) != -1) {
                    paramJson.IsSubscriptionMonth = true;
                }

                // const upaParam = {
                //     "UPASession": JSON.stringify({
                //         "PageViewId" : this.uuid
                //     })
                // };

                _request({
                    url: statisticsUrl.add_subscription,
                    data: paramJson,
                    method: 'POST'
                }).then(res => {
                    shortTips('添加成功');
                    this.reset();
                    this.$refs.subscribedList.getListData();
                    this.handleRefreshList();
                    this.dialogVisible = false;
                }).catch(err => {})

            },
            handleRefreshList() {
                this.$emit('refresh-list');
            },
            handleDeleteList(itemId, index) {
                this.subscribedListLoading = true;
                let url = statisticsUrl.cancel_subscribe.replace('%p', itemId);
                let dataJson = {
                    id: itemId
                }
                _request({
                    url: url,
                    method: 'DELETE',
                    data: dataJson
                }).then((res) => {
                    this.subscribedListData.splice(index, 1);
                    this.handleRefreshList(true);
                }).finally(() => {
                    this.subscribedListLoading = false;
                })
            },
            showModal() {
                this.dialogVisible = true;
            },
            handleCancel() {
                this.reset();
                this.dialogVisible = false;
            },
            reset() {
                this.subscriptionName = '';
                this.dropdownList.forEach(item =>{
                    item.checked = false;
                })
                this.dropdownSelectedList = [];
                this.periodSelectedIndex = [];
                this.teamSelectedList.forEach(item =>{
                    this.unSelectTeam(item);
                });
            },
            closeSubscribeModal() {
                this.dialogVisible = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '#/css/scss/mixins/_clearfix.scss';
    @import '#/css/scss/mixins/_ellipsis.scss';

    .subscribe-modal {

        &-content {
            .subscribed-close-btn{
                position: absolute;
                right: -36px;
                top: -10px;
                width: 20px;
                height: 20px;
                background: url('~@src/assets/images/icon/icon_modal_close.png') center no-repeat;
                cursor: pointer;
                border-radius: 50%;
                box-shadow: 0 0 20px 6px rgba(255,255,255,.4);
            }
            .add-subscrib-btn{
                position: absolute;
                width: 90px;
                height: 36px;
                line-height: 36px;
                background: rgba(56,188,157,.2);
                font-size: 14px;
                color: #38BC9D;
                text-align: center;
                right: 20px;
                top: 10px;
                border-radius: 2px;
                cursor: pointer;
            }
            .icon-add-new{
                // background: url('~@/icon/icon_add.png') center no-repeat;
                margin-right: 6px;
                margin-top: -2px;
            }

            .add-subscribe {
                width: 608px;
                padding: 0 26px;

                > ul {
                    margin: 0;
                }

                &-item {
                    margin-bottom: 10px;
                    line-height: 40px;

                    &.team-item {
                        @include clearfix();
                    }

                    > label {
                        font-size: 14px;
                        color: #000000;
                        margin: 0;

                        i {
                            color: #FF6564;
                        }
                    }

                    &-input, .drop-down {
                        float: right;
                        width: 418px;
                        height: 40px;
                        padding: 0 20px 0 16px;
                        font-size: 14px;
                        color: #666 !important;
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius: 2px;

                        &:focus {
                            outline: none;
                            border-color: $primary;
                        }

                        &::-webkit-input-placeholder {
                            color: #999 !important;
                        }

                        &:-moz-placeholder {
                            color: #999 !important;;
                        }

                        &::-moz-placeholder {
                            color: #999 !important;;
                        }

                        &:-ms-input-placeholder {
                            color: #999 !important;;
                        }

                        &.email-input {
                            cursor: default;
                            border: none;
                            &:focus {
                                outline: none;
                                border-color: #ddd;
                            }
                        }

                    }

                    .drop-down {
                        position: relative;
                        padding: 0;

                        &-header {
                            width: 100%;
                            height: 40px;
                            padding: 0 20px 0 16px;
                            cursor: pointer;

                            &-placeholder {
                                color: #999;
                            }

                            &-text {
                                display: inline-block;
                                max-width: 353px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            > i {
                                float: right;
                                display: inline-block;
                                width: 14px;
                                height: 18px;
                                background: url("~@src/assets/images/icon/statistics/icon_up_arrow.png") no-repeat 0 0;
                                background-size: 14px 8px;
                                transform: rotate(-180deg);
                                margin-top: 7px;

                                &.expand {
                                    transform: none;
                                    margin-top: 15px;
                                }
                            }
                        }

                        &-content {
                            position: absolute;
                            top: 43px;
                            left: -1px;
                            min-width: 100%;
                            width: 418px;
                            height: 137px;
                            padding-left: 30px;
                            background: #FFFFFF;
                            border: 1px solid #DDDDDD;
                            border-radius: 2px;
                            z-index: 10;

                            &-item {
                                float: left;
                                width: 125px;
                                padding: 0 10px;
                                text-align: left;
                                height: 45px;
                                line-height: 47px;
                                /* &.checked {
                                    color: $primary;
                                } */

                                .mod-checkbox {
                                    vertical-align: middle;
                                }

                                > span {
                                    padding-left: 6px;
                                    cursor: pointer;
                                }

                                /deep/ .el-checkbox__inner {
                                    width: 20px;
                                    height: 20px;
                                    vertical-align: top;

                                    &::after {
                                        top: 2px;
                                        left: 6px;
                                        width: 5px;
                                        height: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .period-list {
                        width: 418px;
                        float: right;

                        &-item {
                            float: left;
                            padding-right: 30px;
                            height: 40px;
                            line-height: 40px;

                            .mod-checkbox {
                                vertical-align: middle;
                            }

                            > span {
                                padding-left: 6px;
                                cursor: pointer;
                            }

                            &.checked > span {
                                //color: $primary;
                            }

                            /deep/ .el-checkbox__inner {
                                width: 20px;
                                height: 20px;
                                vertical-align: top;

                                &::after {
                                    top: 2px;
                                    left: 6px;
                                    width: 5px;
                                    height: 10px;
                                }
                            }
                        }
                    }

                    &-team {
                        position: relative;
                        float: right;
                        width: 418px;

                        > i {
                            position: absolute;
                            top: 7px;
                            right: 20px;
                            width: 14px;
                            height: 18px;
                            background: url("~@src/assets/images/icon/statistics/icon_up_arrow.png") no-repeat 0 0;
                            background-size: 14px 8px;
                            transform: rotate(-180deg);

                            &.expand {
                                transform: none;
                                top: 15px;
                            }
                        }

                        .add-subscribe-item-input {
                            float: initial;
                            padding-right: 40px;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #666;
                        }

                        .team-tree {
                            position: absolute;
                            top: 44px;
                            left: 0;
                            width: 418px;
                            max-height: 160px;
                            overflow: auto;
                            background: #FFFFFF;
                            border: 1px solid #DDDDDD;
                            border-radius: 2px;
                            z-index: 10;
                        }

                        .team-selected {
                            font-size: 12px;
                            color: #999;

                            > ul {
                                @include clearfix();

                                padding-top: 5px;
                                padding-bottom: 21px;
                            }

                            &-item {
                                float: left;
                                width: 119px;
                                margin-right: 30px;
                                line-height: 27px;

                                &.expand {
                                    color: $primary;
                                    cursor: pointer;
                                }

                                &:nth-child(3n+3) {
                                    margin: 0;
                                }

                                span {
                                    @extend %ellipsis;

                                    display: inline-block;
                                    width: 77px;
                                    vertical-align: top;
                                }

                                i {
                                    float: right;
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    background: url("~@src/assets/images/icon/statistics/icon_delete.png") no-repeat center center;
                                    background-size: 12px 12px;
                                    cursor: pointer;
                                    vertical-align: top;
                                    margin-top: 3px;

                                    &:hover {
                                        background-image: url("~@src/assets/images/icon/statistics/icon_delete_green.png");
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .subscribed-list-wrap {
                padding: 0 20px;
            }

        }
        .drop-down-header-text{
            color: #666;
        }
    }
</style>
