<template>
    <div class="statistics-param-time">
        <div class="date-range">
            <el-date-picker
                size="large"
                align="left"
                type="daterange"
                format="yyyyMMdd"
                :editable="false"
                :clearable="false"
                range-separator="-"
                class="performance-date-input"
                v-model="performanceDate"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changePerformanceDate"
                :picker-options="performanceDateOption"
                popper-class="performance-date-range"
            ></el-date-picker>
            <div class="date-input-icon"></div>
        </div>
        <div class="employees">
            <el-popover
                popper-class="team-member-popover static-team-member-popover"
                placement="bottom-end"
                width="700"
                trigger="click"
                v-model="teamMemberPopoverVisible"
            >
                <team-member-select
                    ref="teamSelect"
                    :isAllMembers="true"
                    :isShowDimission="false"
                    :date="performanceDate"
                    :isHistoryData="true"
                    :visible="teamMemberPopoverVisible"
                    :selected="selectedUserIds"
                    @item-select-cb="employeeSelect"
                ></team-member-select>
                <div slot="reference" class="employee-select-text">
                    <i class="icon-font icon-user-select doll visible"></i>
                    <span class="text">{{selectedMembersText}}</span>
                    <i v-if="!teamMemberPopoverVisible" class="el-icon-caret-bottom"></i>
                    <i v-else class="el-icon-caret-top"></i>
                </div>
            </el-popover>
        </div>
        <div v-if="customerSelectVisible" class="customers">
            <customer-select
                ref="customerSelect"
                placeholder="搜索客户简称/全称"
                @customerSelected="handleCustomerSelected"
            ></customer-select>
        </div>
        <div v-if="subscribedCountFetched && hasSubscribeBtn" class="subscribe" @click="handleShowSubscribe" id="subscribeEntry">
            <span></span>报表订阅<i v-if="subscribedCount > 0">（{{ subscribedCount }}）</i>
        </div>
        <subscribe-dialog ref="subscribeDialog" :subscribed-count="subscribedCount" v-on:refresh-list="getSubscribeData"></subscribe-dialog>
    </div>
</template>

<script>
    import { statistics as statisticsUrl } from '#/js/config/api.json';
    import moment from 'moment';

    import TeamMemberSelect from '#/component/common/team-member-select.vue';
    import SubscribeDialog from './subscribeDialog.vue';
    import CustomerSelect from '#/component/common/customer-select.vue';

    const YESTODAY = moment().subtract(1, 'days').format('YYYY-MM-DD');


    export default {
        name: "statisticsParamTime",
        components: {
            TeamMemberSelect,
            SubscribeDialog,
            CustomerSelect,
        },
        data() {
            let _minTime = null,
                _maxTime = new Date().getTime();
            return {
                teamMemberPopoverVisible: false,
                selectedMembersText: '全部成员',
                startDate: YESTODAY,
                endDate: YESTODAY,
                subscribedCount: 0,
                subscribedCountFetched: false,
                subscribeEntryGuide:[
                    {
                        el:'#subscribeEntry',
                        width: 140,
                        height: 46,
                        text:'点击添加订阅，实时获取成员工作情况',
                        isBottom: true
                    }
                ],
                performanceDate: [this.dataRangeData.startDate || YESTODAY, this.dataRangeData.endDate || YESTODAY],
                performanceDateOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let _nDays = 365;
                            let timeRange = _nDays*24*60*60*1000, // *天
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        // onPick后触发
                        // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    // disabledDate(date) {
                    //     return date.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近7天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本周',
                        onClick(picker) {
                            const start = moment().weekday(0).format('YYYY-MM-DD');
                            const end = moment().weekday(6).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                            const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '上月',
                        onClick(picker) {
                            const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                            const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                }
            }
        },
        props: {
            customerSelectVisible: Boolean,
            teamSelectVisible: Boolean,
            employeeLoaded: Function,
            employeeSelect: Function,
            selectedUserIds: {
                type: Array,
                default: function() {
                    return []
                }
            },
            dataRangeData: {
                type: Object,
                default: () => {}
            },
            hasSubscribeBtn: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            }
        },
        mounted() {
            this.employees = this.$refs.employees;
            this.subscribeDialog = this.$refs.subscribeDialog;
            this.$refs.teamSelect.getHistoryTeamData(true);
            this.getSubscribeData();
            /* Promise.all([this.getEmployees(),this.getSubscribeData()])
                .then((result)=>{
                    if(this.selectBoxVisible && this.subscribedCountFetched){
                        this.$refs.subscribeEntry.initStepGuide();
                    }

                }).catch((error)=>{
                    console.log(error);
                }) */
            //this.handleShowSubscribe()
        },
        methods: {
            handleCustomerSelected(val){
                this.$emit('customer-select-cb', val)
            },
            getSubscribeData() {
                let url = statisticsUrl.subscribe_list;
                _request({
                    url: url,
                    method: 'POST'
                }).then(res =>{
                    this.subscribedCountFetched = true;
                    this.subscribedCount = res.length;
                })
            },

            handleShowSubscribe() {
                if(this.subscribedCount > 0) {
                    this.subscribeDialog.addSubscribeShow = false;
                }else {
                    this.subscribeDialog.addSubscribeShow = true;
                }
                this.subscribeDialog.showModal();
                this.$refs.subscribeEntry && this.$refs.subscribeEntry.handleKnow();
                $('#subscribeEntry').css('zIndex','unset');
            },
            changePerformanceDate(date) {
                this.startDate =  moment(date[0]).format("YYYY-MM-DD");
                this.endDate = moment(date[1]).format("YYYY-MM-DD");
                this.$emit('date-select', this.startDate, this.endDate);
            }
        }
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';
 .statistics-param-time{
    padding: 20px 40px;
    background-color: #fff;
    @include clearfix();
    .date-range{
        .calendar{
            background: url('~@src/assets/images/icon/calendar.png');
            position: absolute;
            width: 22px;
            height: 22px;
            top: 9px;
            left: 15px;
        }

        input {
            padding: 10px 32px 10px 42px;
            width: 250px;
            cursor: pointer;
        }

        .fa-caret-down {
            position: absolute;
            top: 14px;
            left: 230px;
        }
    }

    .employees {
        margin-left: -30px;
    }

    .customers{
        .tree-select {
            width: auto;
        }
    }

     .subscribe {
         float: right;
         min-width: 129px;
         padding: 0 8px;
         height: 36px;
         line-height: 36px;
         text-align: center;
         font-size: 14px;
         color: #fff;
         background: $primary;
         border-radius: 2px;
         cursor: pointer;

         &:hover {
             background-color: #36B495;
         }

         span {
             display: inline-block;
             width: 12px;
             height: 16px;
             background: url('~@src/assets/images/icon/icon-subscribe.png') no-repeat 0 0;
             background-size: 12px 16px;
             vertical-align: middle;
             margin-right: 6px;
         }
     }
    
    .hot-date-range {
        margin-top: 3px;
        margin-left: 40px;
        .selection-item.active {
            background-color: $primary;
            color: #fff;
            border-radius: 20px;
        }

        .selection-item {
            border: none;
            color: #666;
        }
        
    }

     .dropmenu-select .dropmenu-select-text{
        max-width: 100px;
    }

    .dropmenu-select .dropmenu-select-icon {
        margin-right: 4px;
    }


    .dropmenu-select .dropmenu-select-icon .icon-font {
        font-size: 20px;
        position: relative;
        top: -2px;
    }
 }


.statistics-param-time {
    .date-range input, .employees {
        height: 42px;
    }
    .subscribe{
        position: relative;
    }
}
</style>

<style lang="scss" scope>
.statistics-param-time {
    .date-range {
        line-height: 42px;
        position: relative;
        display: inline-block;
        .performance-date-input.el-date-editor {
            width: auto;
            height: 41px;
            border: 1px solid #d3d3d3;
            .el-range__icon {
                line-height: 34px;
            }
            input.el-range-input {
                width: 90px;
                height: auto;
                padding: 0;
            }
            & + .date-input-icon {
                content: '';
                top: 50%;
                display: block;
                position: absolute;
                right: 16px;
                transform: translateY(-50%);
                border-top: 6px solid #a3afb7;
                border-bottom: none;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
            &.is-active {
                & + .date-input-icon {
                    border-top: none;
                    border-bottom: 6px solid #a3afb7;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }
            }
        }
    }
    .employees {
        position: relative;
        display: inline-block;
        margin-left: 30px;
        width: 190px;
        height: 42px;
        line-height: 42px;
        padding: 0 12px;
        border: 1px solid #d3d3d3;
        border-radius: 4px;

        .employee-select-text {
            display: flex;
            align-items: center;

            .text {
                flex: 1;
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .employee-select-arrow {
            margin-right: 0;
        }
    }
    .customers {
        display: inline-block;
        margin-left: 30px;
        width: 200px;
    }
}
</style>
<style lang="scss">
.team-member-popover.static-team-member-popover {
    transform: translateX(380px);
}
</style>
