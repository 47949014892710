var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statistics-param-time" },
    [
      _c(
        "div",
        { staticClass: "date-range" },
        [
          _c("el-date-picker", {
            staticClass: "performance-date-input",
            attrs: {
              size: "large",
              align: "left",
              type: "daterange",
              format: "yyyyMMdd",
              editable: false,
              clearable: false,
              "range-separator": "-",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "picker-options": _vm.performanceDateOption,
              "popper-class": "performance-date-range",
            },
            on: { change: _vm.changePerformanceDate },
            model: {
              value: _vm.performanceDate,
              callback: function ($$v) {
                _vm.performanceDate = $$v
              },
              expression: "performanceDate",
            },
          }),
          _c("div", { staticClass: "date-input-icon" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "employees" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                "popper-class":
                  "team-member-popover static-team-member-popover",
                placement: "bottom-end",
                width: "700",
                trigger: "click",
              },
              model: {
                value: _vm.teamMemberPopoverVisible,
                callback: function ($$v) {
                  _vm.teamMemberPopoverVisible = $$v
                },
                expression: "teamMemberPopoverVisible",
              },
            },
            [
              _c("team-member-select", {
                ref: "teamSelect",
                attrs: {
                  isAllMembers: true,
                  isShowDimission: false,
                  date: _vm.performanceDate,
                  isHistoryData: true,
                  visible: _vm.teamMemberPopoverVisible,
                  selected: _vm.selectedUserIds,
                },
                on: { "item-select-cb": _vm.employeeSelect },
              }),
              _c(
                "div",
                {
                  staticClass: "employee-select-text",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("i", {
                    staticClass: "icon-font icon-user-select doll visible",
                  }),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.selectedMembersText)),
                  ]),
                  !_vm.teamMemberPopoverVisible
                    ? _c("i", { staticClass: "el-icon-caret-bottom" })
                    : _c("i", { staticClass: "el-icon-caret-top" }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.customerSelectVisible
        ? _c(
            "div",
            { staticClass: "customers" },
            [
              _c("customer-select", {
                ref: "customerSelect",
                attrs: { placeholder: "搜索客户简称/全称" },
                on: { customerSelected: _vm.handleCustomerSelected },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.subscribedCountFetched && _vm.hasSubscribeBtn
        ? _c(
            "div",
            {
              staticClass: "subscribe",
              attrs: { id: "subscribeEntry" },
              on: { click: _vm.handleShowSubscribe },
            },
            [
              _c("span"),
              _vm._v("报表订阅"),
              _vm.subscribedCount > 0
                ? _c("i", [_vm._v("（" + _vm._s(_vm.subscribedCount) + "）")])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("subscribe-dialog", {
        ref: "subscribeDialog",
        attrs: { "subscribed-count": _vm.subscribedCount },
        on: { "refresh-list": _vm.getSubscribeData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }