<template>
    <div class="sort-table-item" :class="{selected: selected}">
        <span class="item">
            <avatar
                class="user-avatar"
                size="mg"
                :userId="data.userId"
                :enableName="true"
                :enableRefImage="false"
                :realName="data.realName"
                :enableCard="true"
                :enableLink="true"
            ></avatar>
        </span>
        <!-- <span class="item name user-stack" v-bind="{title: data.realName, 'data-id': data.userId}"> -->
            <!-- <Trigger
                ref="trigger"
                type="checkbox"
                v-if="enableCheck"
                :label="data.realName"
                v-on:change="handleChange"
            ></Trigger> -->
            <!-- <a v-if="!enableCheck" class="user-center-link" v-text="data.realName" v-bind="_getAnchorAttrBind()"></a> -->
        <!-- </span> -->

        <!--所在团队链条 -->
        <span class="item">
            <!-- <a href="javascript:void(0)"><span class="orders" :title="data.teamChain" v-text="data.teamChain.join()"></span></a> -->
            <span class="orders" :title="data.teamChain" v-text="data.teamChain.join()"></span>
        </span>

        <!--人才入库-->
        <span class="item" @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '1'})">
            <a href="javascript:void(0)"><span class="candidate" v-text="data.candidateCount"></span></a>
        </span>

        <!--寻访记录-->
        <!-- <span class="item" v-if="headerItems[2].value == 2" @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '2'})"> -->
        <span class="item" v-if="headerItems[3].value == 2" @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '2'})">
            <a href="javascript:void(0)"><span class="accept" v-text="data.inquiryLogCount"></span></a>
        </span>
        <!-- <span class="item" v-if="headerItems[2].value == 3" @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '1'})"> -->
        <span class="item" v-if="headerItems[3].value == 3" @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '1'})">
            <a href="javascript:void(0)"><span class="accept" v-text="data.validInquiryLogCount"></span></a>
        </span>

        <!--通话记录-->
        <!-- <span class="item" v-if="headerItems[3].value == 4" @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '1'})"> -->
        <span class="item" v-if="headerItems[4].value == 4" @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '1'})">
            <a href="javascript:void(0)"><span class="callRecord" v-text="data.callRecordCount"></span></a>
        </span>
        <!-- <span class="item" v-if="headerItems[3].value == 5" @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '2'})"> -->
        <span class="item" v-if="headerItems[4].value == 5" @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '2'})">
            <a href="javascript:void(0)"><span class="callRecord" v-text="data.validCallRecordCount"></span></a>
        </span>

        <!--创建职位-->
        <span class="item" @click="goToDetail($event, {key: 'jobDetails', achievementDetailType: '1'})">
            <a href="javascript:void(0)"><span class="interview" v-text="data.jobCount"></span></a>
        </span>

        <!--推荐人才-->
        <!-- <span class="item" v-if="headerItems[5].value == 7" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '1'})"> -->
        <span class="item-has-sub" 
            v-if="headerItems[6].value == 7" 
            >
            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="offer" v-text="data.recommendCandidateM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="offer" v-text="data.recommendCandidateM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '1'})">
                        <span class="offer" v-text="data.recommendCandidateCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '1'})">
                <span class="offer" v-text="data.recommendCandidateCount"></span>
            </a>
            
        </span>
        <!-- <span class="item" v-if="headerItems[5].value == 8" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '2'})"> -->
        <!-- 推荐接受 -->
        <span class="item-has-sub" v-if="headerItems[6].value == 8" >
            <!-- <a href="javascript:void(0)"><span class="offer" v-text="data.recommendAcceptCandidateCount"></span></a> -->

            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="offer" v-text="data.recommendAcceptCandidateM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="offer" v-text="data.recommendAcceptCandidateM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '2'})">
                        <span class="offer" v-text="data.recommendAcceptCandidateCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '2'})">
                <span class="offer" v-text="data.recommendAcceptCandidateCount"></span>
            </a>

        </span>

        <!--人才推荐-->
        <!-- <span class="item" v-if="headerItems[6].value == 9" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '4'})"> -->
        <span class="item-has-sub"  v-if="headerItems[7].value == 9" >
            <!-- <a href="javascript:void(0)"><span class="injob" v-text="data.recommendCount"></span></a> -->
            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="injob" v-text="data.recommendM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="injob" v-text="data.recommendM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '4'})">
                        <span class="injob" v-text="data.recommendCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '4'})">
                <span class="offer" v-text="data.recommendCount"></span>
            </a>
        </span>
        <!-- <span class="item" v-if="headerItems[6].value == 10" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '5'})"> -->
        <span class="item-has-sub"  v-if="headerItems[7].value == 10">
            <!-- <a href="javascript:void(0)"><span class="injob" v-text="data.recommendAcceptCount"></span></a> -->

            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="injob" v-text="data.recommendAcceptM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="injob" v-text="data.recommendAcceptM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '5'})">
                        <span class="injob" v-text="data.recommendAcceptCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '5'})">
                <span class="offer" v-text="data.recommendAcceptCount"></span>
            </a>

        </span>



        <!--面试-->
        <!-- <span class="item" v-if="headerItems[7].value == 11" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '7'})"> -->
        <span class="item-has-sub" v-if="headerItems[8].value == 11" >
            <!-- <a href="javascript:void(0)"><span class="candidate" v-text="data.interviewCount"></span></a> -->

            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="candidate" v-text="data.interviewM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="candidate" v-text="data.interviewM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '7'})">
                        <span class="candidate" v-text="data.interviewCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '7'})">
                <span class="offer" v-text="data.interviewCount"></span>
            </a>

        </span>
        <!-- <span class="item" v-if="headerItems[7].value == 12" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '8'})"> -->
        <span class="item-has-sub" v-if="headerItems[8].value == 12" >
            <!-- <a href="javascript:void(0)"><span class="candidate" v-text="data.interviewApprovedCount"></span></a> -->
            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="candidate" v-text="data.interviewApprovedM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="candidate" v-text="data.interviewApprovedM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '8'})">
                        <span class="candidate" v-text="data.interviewApprovedCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '8'})">
                <span class="offer" v-text="data.interviewApprovedCount"></span>
            </a>

        </span>

        <!--offer-->
        <span class="item-has-sub" >
            <!-- <a href="javascript:void(0)"><span class="searchlogs" v-text="data.offerCount"></span></a> -->
            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="candidate" v-text="data.offerM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="candidate" v-text="data.offerM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '10'})">
                        <span class="candidate" v-text="data.offerCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '10'})">
                <span class="offer" v-text="data.offerCount"></span>
            </a>
        </span>

        <!--入职 -->
        <span class="item-has-sub">
            <!-- <a href="javascript:void(0)"><span class="orders" v-text="data.onboardCount"></span></a> -->
            <div class="sub-cell-wrap" v-if="isCFUser">
                <div class="sub-cell">
                    <span class="candidate" v-text="data.onboardM1Count"></span>
                </div>
                <div class="sub-cell">
                    <span class="candidate" v-text="data.onboardM2Count"></span>
                </div>
                <div class="sub-cell">
                    <a href="javascript:void(0)" @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '11'})">
                        <span class="candidate" v-text="data.onboardCount"></span>
                    </a>
                </div>
            </div>
            <a
                v-else  
                class="sub-single-item"
                href="javascript:void(0)" 
                @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '11'})">
                <span class="offer" v-text="data.onboardCount"></span>
            </a>
        </span>
        
        <!--所在团队链条 -->
        <!-- <span class="item">
            <a href="javascript:void(0)"><span class="orders" :title="data.teamChain" v-text="data.teamChain.join()"></span></a>
        </span> -->
    </div>
</template>

<script>

    // import Trigger from '@/component/base/trigger/index';
    import Avatar from '#/component/common/avatar.vue';

    export default {
        components: {
            // Trigger
            Avatar
        },
        data() {
            return {
                activeIndex: 0,
                selected: false
            }
        },
        // constructor(opts) {
        //     if(this.hasQuit){
        //         this.realName = this.realName + '(已离职)';
        //     }
        // },
        props: {
            enableCheck: {
                type: Boolean,
                default: true
            },
            data: Object,
            index: Number,
            headerItems: Array,
            jumpDetail: Function,
            handleCheck: Function
        },
        computed: {
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
        },
        mounted() {
        },
        methods: {
            handleChange(active) {
                this.selected = active;
                this.handleCheck(this.data, active, this.index);
                return false;
            },
            goToDetail(ev, json){
                ev.stopPropagation();
                json.userIds = [this.data.userId];
                this.jumpDetail(json);
            },
            _getAnchorAttrBind() {
                return {
                    href: `/User/${this.data.userId}`
                };
            }
        },
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_ellipsis.scss';
@import '#/css/scss/mixins/_clearfix.scss';
 .sort-table-item{
    @include clearfix();

    .item {
        @extend %ellipsis;

        height: 45px;

        float: left;
        display: block;
        //width: 105px;
        text-align: center;
        padding: 8px;
        line-height: 29px;
        border-right: 1px solid #ddd;

        background-color: #fff;

        .user-base-info .user-name {
            display: inline-block;
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: default;
        }

        a {
            color: #666;

            &:hover{
                color: $primary;

                .icon-forward-square-o {
                    color: $primary;
                }
            }
        }

        &:last-child {
            border: 0 none;
            padding-right: 8px;
        }

        // //&:nth-child(1) { width: 82px; }
        // &:nth-child(1) { width: 112px; }
        // &:nth-child(2) { width: 104px; }
        // // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 134px; }
        // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 104px; }
        // &:nth-child(5) { width: 107px; }
        // &:nth-child(9) { width: 82px; }
        // &:nth-child(10) { width: 82px; }
        // &:nth-child(11) { width: 150px; }

        // &:nth-child(1) { width: 112px; }
        // //&:nth-child(1) { width: 82px; }
        // &:nth-child(2) { width: 104px; }
        // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 134px; }
        // &:nth-child(5) { width: 107px; }
        // &:nth-child(9) { width: 82px; }
        // &:nth-child(10) { width: 82px; }
        // &:nth-child(11) { width: 150px; }

        &:nth-child(1) { 
            width: 112px;
            position: sticky;
            left: 0;
            z-index: 100; 
            border-left: 1px solid #d3d3d3;
        }
        &:nth-child(2) { width: 150px; }
        &:nth-child(3) { width: 104px; }
        &:nth-child(4), &:nth-child(5), &:nth-child(7), &:nth-child(8), &:nth-child(9) { width: 134px; }
        &:nth-child(6) { width: 107px; }
        &:nth-child(10) { width: 82px; }
        &:nth-child(11) { width: 82px; }


        &.name {
            text-align: left;
            padding-left: 20px;
            padding-right: 16px;
        }

        .icon-forward-square-o {
            font-size: 15px;
            margin-left: 5px;
        }

    }
    .item-has-sub{
        width: 174px;
        @extend %ellipsis;

        height: 45px;

        float: left;
        display: block;
        //width: 105px;
        text-align: center;
        // padding: 8px;
        line-height: 29px;
        border-right: 1px solid #ddd;
        .sub-cell-wrap{
            height: 45px;
            .sub-cell{
                display: inline-block;
                box-sizing: border-box;
                border-right: 1px solid #ddd;
                width: 30%;
                height: 45px;
                line-height: 29px;
                padding: 8px;
                vertical-align: top;
                >a:hover{
                    color: #38BC9C;
                }
                &:last-child{
                    width: 40%;
                    border-right: 0;
                }

            }
        }

        .sub-single-item{
            display: inline-block;
            box-sizing: border-box;
            padding: 8px;
            line-height: 29px;
            &:hover{
                color: #38BC9C;
            }
        }
    }
    // .has-sub{
    //     padding: 0;
    // }

    .user-center-link:hover {
        text-decoration: underline;
    }

    .trigger-label {
        @extend %ellipsis;
        width: 50px;
    }

 }
.user-profile-popover.active {
    margin-top: 16px;
}

</style>
