<template>
    <el-dialog 
        title="订阅到邮箱" 
        width="650px"
        class="subscribe-modal"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogFormVisible"
        >
        <el-form 
            label-position="left"
            :model="form" 
            :rules="rules" 
            ref="subscribeForm" 
            v-loading="loading"
            label-width="110px">
            <el-row class="dialog-header">
                <i class="icon-font icon-dingyue1"></i>
                <div class="subscribe-describition" v-html="describitionHtml"></div>
            </el-row>
            <el-form-item label="邮箱地址" prop="emails">
                <el-input 
                    type="textarea" 
                    rows="3"
                    placeholder='请输入订阅的邮箱，多个邮箱以分号“;"隔开'
                    v-model="form.emails"></el-input>
            </el-form-item>
            <el-form-item label="发送周期">
                <el-checkbox 
                    v-if="form.isSubscriptionDay!=null" 
                    v-model="form.isSubscriptionDay">每天</el-checkbox>
                <el-checkbox 
                    v-if="form.isSubscriptionWeek!=null" 
                    v-model="form.isSubscriptionWeek">每周一</el-checkbox>
                <el-checkbox 
                    v-if="form.isSubscriptionMonth!=null" 
                    v-model="form.isSubscriptionMonth">每月一号</el-checkbox>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="submitSubscribeForm">确定</el-button>
        </div>
    </el-dialog>
    <!-- <modal class="subscribe-modal" enable-modal="true" enable-header="true" enable-center="true" size="lg" enable-confirm-spinner="true" caption="订阅到邮箱"> -->
    <!-- ko with:$parents[1] -->
    <!-- <form id="subscribeForm" class="form-normal">
        <div class="form-group clearfix">
            <div class="col-md-12 dialog-header" style="padding: 0">
                <i class="icon-font icon-dingyue1"></i>
                <div class="subscribe-describition" data-bind="html:describitionHtml"></div>
            </div>
        </div>
        <div class="form-group clearfix">
            <label class="col-md-2" style="padding: 0">
            邮箱地址
        </label>
            <div class="col-md-10">
                <textarea id="emails" name="emails" class="form-control" data-val="true" data-val-required="请输入邮箱" type="text" placeholder='请输入订阅的邮箱，多个邮箱以分号“;"隔开'></textarea>
            </div>
        </div>
        <div class="form-group clearfix">
            <label class="col-md-2" style="padding: 0">
            发送周期
        </label>
            <div class="col-md-10">
                <div class="checkbox col-md-3" data-bind="visible:enableDailyBtn">
                    <checkbox checked="false" label="每天" identity="daily"></checkbox>
                </div>
                <div class="checkbox col-md-3" data-bind="visible:enableWeeklyBtn">
                    <checkbox checked="false" label="每周一" identity="weekly"></checkbox>
                </div>
                <div class="checkbox col-md-3" data-bind="visible:enableMonthlyBtn">
                    <checkbox checked="false" label="每月一号" identity="monthly"></checkbox>
                </div>
            </div>
        </div>
    </form>
    <div class="modal-box-foot">
        <btn class="modal-box-confirm" data-bind="click: onConfirm" k-text="confirmBtnText" size="lg" theme="primary" shape="round" border="solid"></btn>
        <btn class="modal-box-cancel" data-bind="click: onCancel" k-text="cancelBtnText" size="lg" shape="round" border="solid"></btn>
    </div> -->
    <!-- /ko -->
<!-- </modal> -->
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            dialogFormVisible: false,
            form: {
                emails: '',
                isSubscriptionMonth: false,
                isSubscriptionWeek: false,
                isSubscriptionDay: false,
            },
            rules: {
                emails: [
                    { required: true, message: "请输入订阅的邮箱", trigger: 'blur' },
                    {
                        validator: (rules, value, callback) => {
                            let arr = value.split(';');
                            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
            
                            if(arr.length==1 && !!arr[0]) {
                                if(mailReg.test(arr[0])) {
                                    callback();
                                } else {
                                    callback(new Error('请输入正确的邮箱格式'))
                                }
                            } 
                            if(arr.length > 1) {
                                for(let i=0; i<arr.length; i++){
                                    if(!mailReg.test(arr[i])) {
                                        callback(new Error(`${arr[i]}不是有效的邮箱`))
                                    }
                                }
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
            loading: false,

            describitionHtml: '',
            postUrl: '',
            getUrl: '',
            extraParam: {},
            confirmBtnText: '',
            cancelBtnText: '',
            enableDailyBtn: '',
            enableWeeklyBtn: '',
            enableMonthlyBtn: '',
        }
    },
    computed: {
    },
    mixins: [
    ],
    mounted() {
    },
    methods: {
        initSubscribeDialog(opt){
            // this.describitionHtml(opt.describitionHtml);
            // this.getUrl(opt.getUrl);
            // this.postUrl(opt.postUrl);
            // this.extraParam(opt.extraParam||{});
            this.describitionHtml = opt.describitionHtml;
            this.getUrl = opt.getUrl;
            this.postUrl = opt.postUrl;
            this.extraParam = opt.extraParam || {};
            this.getDefaultValue();
            this.dialogFormVisible = true;
        },
        submitSubscribeForm(){
            this.$refs['subscribeForm'].validate((valid) => {
                if (valid) {
                    if(!this.postUrl) return;
                    const params = {
                        ...this.form,
                        ...this.extraParam
                    };
                    _request({
                        method: "POST",
                        url: this.postUrl,
                        data: params || null
                    }).then(res => {
                        shortTips('订阅成功！');
                    }).finally(_ => {
                        this.dialogFormVisible = false
                    });
                }
            });

            // let self = this;
            // if(!self.postUrl()){
            //     console.error('订阅邮箱的post url为空');
            //     self.modal.spinner.sleep();
            //     return;
            // }

            // this.modal.spinner.wake();

            // $.post(self.postUrl(),Object.assign(formObj,self.extraParam()),function(result){
            //     if(!result.succeeded){
            //         shortTips(result.message);
            //     }else{
            //         self.onCancel();
            //         shortTips('订阅成功！');
            //     }
            //     self.modal.spinner.sleep();
            // })
        },
        getDefaultValue() {
            if(!this.getUrl) return;
            this.loading = true;
            _request({
                method: "GET",
                url: this.getUrl,
            }).then(res => {
                if(res) {
                    this.form.emails = res.emails;
                    this.form.isSubscriptionMonth = res.isSubscriptionMonth;
                    this.form.isSubscriptionWeek = res.isSubscriptionWeek;
                    this.form.isSubscriptionDay = res.isSubscriptionDay;
                } else {
                    this.form.emails = '';
                    // this.form.isSubscriptionMonth = null;
                    // this.form.isSubscriptionWeek = null;
                    // this.form.isSubscriptionDay = null;
                }
            }).finally(_ => {
                this.loading = false;
            });
        },
    }
}
</script>

<style lang="scss">
.subscribe-modal{
    .dialog-header{
        padding: 0;
        margin-bottom: 30px;
    }
    .icon-dingyue1{
        float: left;
        font-size: 34px;
        color: #38bc9c;
        margin-right: 16px;
    }
    .subscribe-describition{
        line-height: 34px;
        .text-grey{
            font-size:14px;
            color:#9b9b9b;
        }
        .text-regular{
            color: #333;
        }
        .text-ellipsis{
            max-width: 80px;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            overflow: hidden;
            vertical-align: top;
        }
    }
    .checkbox{
        margin: 0;
        padding-left: 0;
        label{
            padding-left: 0;
        }
    }
    .text-grey {
        color: #ccc;
    }
    input[type=checkbox]{
        margin: 0;
    }

    .modal-box-wrapper{
        >.modal-box-foot{
            // display: none;
        }
        .modal-box-body{
            padding-bottom: 0;
            .modal-box-foot{
                margin-top: 50px;
            }
        }
    }
}
</style>
