var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statistics-charts" }, [
    _c(
      "div",
      { staticClass: "tab" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "border-card" },
            on: { "tab-click": _vm.handleTabChange },
            model: {
              value: _vm.activeShow,
              callback: function ($$v) {
                _vm.activeShow = $$v
              },
              expression: "activeShow",
            },
          },
          [
            _c(
              "div",
              { staticClass: "param" },
              [
                _c("statistics-param", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeShow != "0",
                      expression: "activeShow != '0'",
                    },
                  ],
                  ref: "statisticsParam",
                  attrs: {
                    "show-unit-tab": _vm.showUnitTab,
                    "is-multiple-employee": _vm.isMultipleEmployee,
                  },
                }),
              ],
              1
            ),
            _c("el-tab-pane", [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    "\n                    推荐进展情况\n                    "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        title: "",
                        width: "300",
                        trigger: "hover",
                        content:
                          "在选定时间内的推荐在后续业务中的转化情况，其中的接受、面试、offer是由这段时间内推荐产生的业务跟进，不一定在选定时间内发生",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-info",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("el-tab-pane", [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    "\n                    推荐转化分析\n                    "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        title: "",
                        width: "300",
                        trigger: "hover",
                        content:
                          "在选定时间内的推荐在后续业务中的转化情况，其中的接受、面试、offer是由这段时间内推荐产生的业务跟进，不一定在选定时间内发生",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-info",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("el-tab-pane", [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    "\n                    工作内容统计\n                    "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        title: "",
                        width: "300",
                        trigger: "hover",
                        content:
                          "在选定时间内产生的推荐、接受、面试、offer等工作内容的数据，其中接受、面试、offer等数据不一定由推荐数据产生，但是在选定时间发生",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-info",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("recommended-progress-table", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeShow == "0",
              expression: "activeShow == '0'",
            },
          ],
          ref: "recommendedProgressTable",
        }),
        _c("statistics-conversion-funnel-chart", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeShow == "1",
              expression: "activeShow == '1'",
            },
          ],
          ref: "statisticsConversionFunnelChart",
          attrs: { "is-multiple-employee": _vm.isMultipleEmployee },
        }),
        _c("statistics-work-content-chart", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeShow == "2",
              expression: "activeShow == '2'",
            },
          ],
          ref: "statisticsWorkContentChart",
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }