<template>
    <div class="chart">

    </div>
</template>

<script>
    import { isString, isObject, extendObject, defaults } from '#/js/util/tools.js';
    import '#/component/third/highcharts/highcharts.src.js';

    const DEFAULT_PARAM = {
        chart: {
            type: "line", //默认是折线图
            animation: true
        },
        credits: {
            enabled: false
        }
    }

    function fixCharParam (param) {
        param = param || {};
        param.chart = param.chart || {};
        defaults(param.chart || {}, DEFAULT_PARAM.chart);
        param.credits = defaults(param.credits || {}, DEFAULT_PARAM.credits);
        return param;
    }

    export default {
        name: "chart",
        data() {
            return {
                param: fixCharParam(Object.assign({}, this.defaultParam, {})) || {}
            }
        },
        props: {
            defaultParam: Object,
            deferShow: Boolean
        },
        // constructor(opts, info) {
        //     // TODO 覆盖highchart的大部分api
        //     this.param.chart.renderTo = info.element.children[0];
        // },

        mounted() {
            this.param.chart.renderTo = this.$el;
            if(!this.deferShow){
                this.renderChart();
            }
        },
        methods: {
            renderChart(param){
                this.updateParam(param);
                this.chart = new Highcharts.Chart(this.param);
                this.param = this.chart.options;
            },
            updateParam(param){
                extendObject(this.param, param);
            },
            // 图表选项太多，分不同类型选项进行控制
            update(type, opts){
                if(isString(type) && isObject(opts)){
                    extendObject(this.param[type], opts);
                }else if(isObject(type)){
                    opts = type;
                    extendObject(this.param, type);
                }
            },
            updateChart(opts){
                extendObject(this.param.chart, opts);
            },
            updateColors(opts){
                extendObject(this.param.colors, opts);
            },
            updateCredits(opts){
                extendObject(this.param.credits, opts);
            },
            updateLabels(opts){
                extendObject(this.param.labels, opts);
            },
            updateLegend(opts){
                extendObject(this.param.legend, opts);
            },
            updateLoading(opts){
                extendObject(this.param.loading, opts);
            },
            updateXAxis(opts){
                extendObject(this.param.xAxis, opts);
            },
            updateYAxis(opts){
                extendObject(this.param.yAxis, opts);
                // TODO 目前只更新一个yAxis
                this.chart.yAxis[0].update(this.param.yAxis);
            },
            updateTooltip(opts){
                extendObject(this.param.tooltip, opts);
            },
            updateTitle(opts){
                extendObject(this.param.title, opts);
            },
            updateSubTitle(opts){
                extendObject(this.param.subtitle, opts);
            },
            updateSeries(opts){
                const series = this.chart.series;
                if(Array.isArray(opts)) {
                    opts.forEach((opt, i) => {
                        series[i].update(opt);
                    })
                } else {
                    series.forEach((serie, i) => {
                        serie.update(opts);
                    })
                }
            },
            setTitle(text){
                this.chart.setText({
                    text: text
                });
            },
            updateData(data){
                const series = this.chart.series;
                // 判断多种格式的数据
                if(Array.isArray(data)){
                    if(Array.isArray(data[0])){
                        data.forEach((v, k) => {
                            series[k] && series[k].setData(v);
                        })
                    }else{
                        series[0] && series[0].setData(data, true);
                    }
                }else if(isObject(data)){
                    // 对于对象，按键值更新
                    Object.keys(data).forEach((key) => {
                        if(series[key] && Array.isArray(data[key])){
                            series[key].setData(data[key]);
                        }
                    })
                }
            },
            setXAxisCategories(opts) {
                this.chart.xAxis[0].setCategories(opts);
            },
            updateTooltipValueSuffix(str = '') {
                let opts = {
                    tooltip: {
                        valueSuffix: str
                    }
                }
                this.updateSeries(opts);
            },
            refresh(){
                // 强制刷新图表
                this.chart.isDirtyBox = true;
                this.chart.isDirtyLegend = true;
                this.chart.redraw();
            }
        }
    }
</script>

<style lang="scss" scoped>
.chart {
    position: relative;
}
</style>
