<template>
    <div class="subscribed-list-wrap">
        <!--<div class="add-subscrib-btn"><i class="icon-new icon-add-new"></i>添加订阅</div>-->
        <ul class="subscribed-list" v-if="listData.length>0">
            <li class="subscribed-item" v-for="(item, index) in listData" :key="index" >
                <div class="subscribed-item-title">
                    <span class="title-text">{{`${index+1}、${item.subscriptionName}`}}</span>
                    <span class="subscribed-btn" @click="cancelSubscribe(item.id,index)" :id="index==0?'cancelSubcribedBtn':null"><i class="icon-new icon-cancel"></i>取消订阅</span>
                </div>
                <div class="subscribed-item-time">
                    {{`周期：${item.subscriptionType}/订阅时间：${item.subscriptionDate}`}}
                </div>
                <div class="subscribed-item-email"><span class="item-label">收件邮箱：</span> {{item.emails}}</div>
                <div class="subscribed-item-member">
                    <span class="item-label">所含团队({{item.teams.length}})：</span>
                    <div class="item-member-list">
                        <span v-if="item.isFold" v-for="(memberItem, index) in item.teams.slice(0,2)" :key="index">{{memberItem}}</span>
                        <span v-if="!item.isFold" v-for="(memberItem, index) in item.teams" :key="index">{{memberItem}}</span>

                        <span class="toggle-btn" v-if="item.teams.length>2" @click="handleFold(item,index)">{{item.isFold?`展开剩余${item.teams.length-2}个group`:'收起所含团队'}}</span>
                    </div>
                </div>
            </li>
        </ul>
        <div class="subscribed-default" v-else>
            <img class="default-img" :src="require('@src/assets/images/icon/statistics/subscrib_default.png')" alt="">
            <p class="default-title">暂无订阅数据</p>
            <p class="default-tips">添加订阅，才能及时掌握工作情况哦～</p>
            <div class="default-btn-wrap">
                <span class="btn-new cancel-btn" @click="closeSubscribeModal">稍后再议</span>
                <span class="btn-new confirm-btn" @click="showAddSubscribe"><i class="icon-new icon-subscrib"></i>去订阅</span>
            </div>
        </div>

    </div>
</template>
<script>
import { statistics as statisticsUrl } from '#/js/config/api.json';
export default {
    name: "subscribedList",
    components: {
    },
    data() {
        return {
        }
    },
    props: {
        listData: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    mounted() {
    },
    methods: {
        cancelSubscribe(itemId, index){
            this.$emit('delete-subscribe', itemId, index);
        },
        handleFold(item,index){
            let isFold = item.isFold;
            item.isFold = !isFold;
        },
        closeSubscribeModal(){
            //关闭订阅弹窗
            this.$emit('close-subscribe-modal');
        },
        showAddSubscribe(){
            //添加订阅
            this.$emit('show-add-subscribe');
        }
    },
}
</script>
<style lang="scss" scoped>
.subscribed-list-wrap{
    font-size: 14px;
    color: #444;
    margin-left: -20px;
    margin-right: -20px;
    .subscribed-list{
        /* padding: 20px; */
        max-height: 506px;
        overflow-y: auto;
        .subscribed-item{
            //margin-bottom: 20px;
            &-title{
                position: relative;
                height: 24px;
                .title-text{
                    display: inline-block;
                    max-width: 400px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .subscribed-btn{
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    background-color: #fff;
                    border-radius: 12px;
                    height: 24px;
                    line-height: 24px;
                    width: 94px;
                    text-align: center;
                    cursor: pointer;
                    &:hover{
                        background-color: rgba(56,188,157,.2);
                        color: #38BC9D;
                    }
                    .icon-cancel{
                        margin-top: -2px;
                        margin-right: 4px;
                        background: url('~@src/assets/images/icon/icon_cancel.png') center no-repeat;
                    }
                }
            }
            &-time{
                font-size: 12px;
                color: #FF7E5C;
                margin-bottom: 6px;
            }
            .item-label{
                display: inline-block;
                color: #444;
            }
            &-email,&-member{
                color: #666;
                margin-bottom: 6px;
                word-break: break-all;
            }
            .subscribed-item-member{
                .item-label{
                    vertical-align: top;
                }
            }
            .item-member-list{
                display: inline-block;
                padding-top: 2px;
                width: 490px;
                font-size: 0;
                >span{
                    font-size: 14px;
                    width: 135px;
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    margin: 0 12px 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .toggle-btn{
                    color: #38BC9D;
                    cursor: pointer;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #fff;
        }
       //定义滑块 内阴影+圆角
        &::-webkit-scrollbar-thumb {
            border-radius: 8px !important;
            background-color: #eee;
            border: 1px solid #eee !important;
        }
    }
    .subscribed-default{
        text-align: center;
        .default-img{
            display: block;
            margin: 35px auto 10px;

        }
        >P{
            margin-bottom: 10PX;
        }
        .default-title{
            font-size: 18px;
            color: #444;
        }
        .default-tips{
            font-size: 16px;
            color: #666666;
        }
        .default-btn-wrap{
            text-align: center;
            margin-top: 30px;
            .btn-new{
                display: inline-block;
                width: 130px;
                height: 36px;
                line-height: 36px;
                border-radius: 2px;
                margin: 0 6px;

                .icon-subscrib{
                    width: 16px;
                    height: 16px;
                    background: url('~@src/assets/images/icon/icon-subscribe.png') center no-repeat;
                }
                &.cancel-btn{
                    color: #999;
                    background: #eee;
                }
                &.confirm-btn{
                    background: #38BC9C;
                    color: #fff;
                }
            }
        }

    }
    /* .subscribed-close-btn{
        position: absolute;
        right: 0;
        top: -90px;
        width: 16px;
        height: 16px;
        background: url('../../../../icon/icon_modal_close.png') center no-repeat;
        cursor: pointer;
    } */
    .add-subscrib-btn{
        position: absolute;
        width: 90px;
        height: 36px;
        line-height: 36px;
        background: rgba(56,188,157,.2);
        font-size: 14px;
        color: #38BC9D;
        text-align: center;
        right: 0;
        top: -42px;
        border-radius: 2px;
        cursor: pointer;
    }
    .icon-add-new{
        background: url('~@src/assets/images/icon/icon_add.png') center no-repeat;
        margin-right: 6px;
        margin-top: -2px;
    }
}

</style>
