<template>
    <div class="statistics-selected-items" v-show="visible">
        <div class="item" v-for="item in items">
            <sort-table-item :data="item" :header-items="headerItems" :visible="visible" :enable-check="false"></sort-table-item>
        </div>
    </div>
</template>

<script>

    import SortTableItem from './sortTableItem.vue';

    export default {
        components: {
            SortTableItem
        },
        data() {
            return {
                items: [],
                headerItems: [],
                visible: false
            }
        },
        methods: {
            setData(data){
                let arr = [];
                let invalid_keys = ['parent', 'constructor', 'componentInfo', 'on', 'off', 'trigger', 'broadcast', 'ready', 'refs', 'render', '__manual_render_flag__', '_callbacks'];
                data.forEach(item => {
                    let target = {};
                    target = Object.assign({}, target, item);
                    Object.keys(target).forEach(key => {
                        if(invalid_keys.indexOf(key) > -1 || key.indexOf('$') === 0){
                            delete target[key];
                        }else if(typeof target[key] === "object"){
                            delete target[key];
                        }
                    })
                    arr.push(target);
                })
                this.items = arr;
            },
            clear(){
                this.items = [];
                this.visible = false;
            }
        },
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';

.statistics-selected-items-wrapper {
    padding: 30px 30px 40px;
}

.statistics-selected-items {
    @include clearfix();
    border: 1px solid #d3d3d3;
    min-height: 96px;

    .sort-table-item .item.name checkbox {
        display: none;
    }
    > .item {
        span {
            border-right: 0;
        }

        &:after {
            content: "";
            display: block;
            width: 98%;
            height: 1px;
            background-color: #eee;
            position: relative;
            margin: auto;
        }
        &:last-child:after {
            display: none;
        }
    }
    .sort-table-item .item.name {
        text-align: left;
        padding-left: 20px;
    }
}

</style>
