<template>
    <div>
        <div class="table-box">
            <div class="sort-table" :class="{'enable-check': enableCheck}" v-loading="loading">
                <div class="sort-table-header" v-if="listData.length > 0">
                    <!--<sort-table-header ref="tableHeader" id="tableHeader" :items="items" :header-active-index="headerActiveIndex">-->
                    <!--</sort-table-header>-->
                    <ul class="sort-table-header-list">
                        <li 
                            class="sort-table-header-list-item" 
                            v-for="(item, index) in items" 
                            @click="handleHeaderClick(item, index)" 
                            :class="{'active': index == headerActiveIndex, 'heigit-40': [6,7,8, 9, 10].includes(index) && isCFUser }" 
                            :key="'item'+index" >
                           
                            <template v-if="index == 3 ||index == 4 ||index == 6 ||index == 7 ||index == 8||index == 9||index == 10">
                                <el-dropdown 
                                    placement="bottom" 
                                    @command="(subIndex) => sortBySubItem(index, subIndex)">
                                    <div class="item-content el-dropdown-link">
                                        <span class="text">
                                            {{item.text}}
                                            <i class="arrow"></i>
                                        </span>
                                    </div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="(obj, subIndex) in item.subItems" 
                                            :key="obj.text + obj.value" 
                                            :command="subIndex"
                                            >{{obj.text}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <template v-if="[6, 7, 8, 9, 10].includes(index) && isCFUser">
                                    <div class="header-sub-wrap">
                                        <div class="sub-cell">
                                            M1
                                        </div>
                                        <div class="sub-cell">
                                            M2
                                        </div>
                                        <div class="sub-cell">
                                            全部
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="item-content">
                                    <span class="text">{{item.text}}</span>
                                </div>
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="order-container table-content" :class="'active-' + headerActiveIndex" v-if="listData.length > 0">
                    <div class="order-wrapper">
                        <ul>
                            <li class="order-item" v-for="(item, index)  in listData" :key="index + item.userId">
                                <sort-table-item
                                    :data="item"
                                    :index="index"
                                    :enable-check="enableCheck"
                                    :header-items="items"
                                    :jump-detail="jumpDetail"
                                    :handle-check="handleCheck"
                                ></sort-table-item>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="empty-data" v-if="!loading && page.total == 0">
                    <span class="empty-img"></span>
                    <p>暂无数据</p>
                </div>
            </div>
        </div>
        <el-pagination
            v-if="page.total != 0"
            class="pagination-common"
            :current-page="page.current"
            layout="total, prev, pager, next, slot"
            :total="page.total"
            @current-change="handleCurrentChange">
            <span class="pagination-text">
                <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                <span @click="handlePagerJump">跳转</span>
            </span>
        </el-pagination>
    </div>
</template>

<script>
    import {statistics as statisticsUrl} from '#/js/config/api.json';
    import SortTableItem from './sortTableItem.vue';

    const DEFAULT_QUERY_PARAM = {
        startDate: '',
        endDate: '',
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1,
        orderBy: 1
    }

    export default {
        components: {
            // SortTableHeader,
            SortTableItem,
        },
        data() {
            return {
                loading: false,
                enableCheck: false,
                selects: [],
                param: Object.assign({}, DEFAULT_QUERY_PARAM),
                // headerActiveIndex: 1,
                headerActiveIndex: 2,
                items: [
                    { value: '', icon: '', text: '顾问姓名' },
                    { value: 0, icon: '', text: '所在团队链条' },
                    { value: 1, icon: '', text: '人才入库' },
                    { value: 3, icon: '', text: '有效寻访', subItems: [
                            { value: 2, text: '全部寻访' },
                            { value: 3, text: '有效寻访' }
                        ]
                    },
                    { value: 5, icon: '', text: '有效电话', subItems: [
                            { value: 4, text: '全部电话' },
                            { value: 5, text: '有效电话' }
                        ]
                    },
                    { value: 6, icon: '', text: '创建职位' },
                    { value: 7, icon: 'talent-book', text: '推荐人才', subItems: [
                            { value: 7, text: '推荐人才' },
                            { value: 8, text: '推荐接受' }
                        ]
                    },
                    { value: 9, icon: 'log-o', text: '人才推荐' , subItems: [
                            { value: 9, text: '人才推荐' },
                            { value: 10, text: '推荐接受' }
                        ]
                    }
                    ,
                    { value: 12, icon: 'list-check-o', text: '面试通过' , subItems: [
                            { value: 11, text: '全部面试' },
                            { value: 12, text: '面试通过' }
                        ]
                    },
                    { value: 13, icon: 'carte-edit-o', text: 'offer' },
                    { value: 14, icon: '', text: '入职' },
                    // { value: 15, icon: '', text: '所在团队链条' },
                ],
                pagerJump: 0,
                page: {
                    current: 1,
                    size: 10,
                    total: 0
                },
                total: 0,
                listData: []
            }
        },
        props: {
            isMultipleEmployee: Boolean,
            jumpDetail: Function
        },
        computed: {
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
        },
        mounted() {
            // this.getListData();
        },
        methods: {
            clearSelect(){
                this.selects = [];
                // let items = this.$refs('sort-table-item');
                // eachArray(items, function(item){
                //     item.selected(false);
                // });
            },
            getListData(slient){
                if(!slient) {
                    this.page.current = 1;
                }
                this.loading = true;
                _request({
                    url: statisticsUrl.url_summary_statistics_detail,
                    method: 'POST',
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    data: Object.assign({ 
                        offset: (this.page.current - 1) * this.page.size, 
                        pageSize: this.page.size 
                    }, this.param)
                }).then(res =>{
                    this.page.total = res.count || 0;
                    this.listData = res.data || [];
                }).finally(() => {
                    this.loading = false;
                })
            },

            handleCurrentChange(val) {
                this.page.current = val;
                this.getListData(true);
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.handleCurrentChange(currentPager)
                }
            },

            handleCheck(vm, selected, index) {
                if(selected){
                    this.addSelectedItem(vm);
                }else{
                    this.removeSelectedItem(vm);
                }
                this.updateSelectedItem(vm);
                this.$emit('item-check', this.selects, this.items);
                return false;
            },

            handleHeaderClick(item, index) {
                if(index == 0) {
                    return false
                }
                // if(index == 10) {
                //     return false
                // }
                if(index == 1) {
                    return false
                }
                this.headerActiveIndex = index;
                this.$emit('sort-change', item.value);
                this.refresh({
                    orderBy: item.value
                });
            },

            sortBySubItem(index, subIndex) {
                let item = this.items[index];
                let subItem = item.subItems[subIndex];

                item.value = subItem.value;
                item.text = subItem.text;

                this.handleHeaderClick(item, index);
            },

            onFetchSucceed(){
                let items = this.list.items, selects = this.selects;
                let selectIds = [];
                selects.forEach(item => {
                    selectIds.push(item.userId);
                })
                if(selectIds.length){
                    items.forEach(item => {
                        let idx = selectIds.indexOf(item.userId);
                        if(idx > -1){
                            item.selected = true;
                            selectIds.splice(idx, 1);
                            if(!selectIds.length){
                                return false;
                            }
                        }
                    })
                }
            },
            refresh(param){
                if (param.startDate) {
                    Object.assign(this.param, {...param,
                        startDate: param.startDate.replace(/\//g, '-'),
                        endDate: param.endDate.replace(/\//g, '-'),
                    });
                } else {
                    Object.assign(this.param, param);
                }
                this.getListData();
            },
            resetHeader(){
                // this.headerActiveIndex = 0;
                this.headerActiveIndex = 1;
                this.tableHeader.reset();
            },
            addSelectedItem(item){
                this.selects.push(item);
            },
            removeSelectedItem(item){
                let idx = this.selects.indexOf(item);
                if(!~idx){
                    let selects = this.selects;
                    selects.forEach((v, k) => {
                        if(item.id === v.id){
                            idx = k;
                            return false;
                        }
                    })
                }
                if(~idx){
                    this.selects.splice(idx, 1);
                }
            },
            updateSelectedItem(item){
                var length, removes;
                if((length = this.selects.length) > 2){
                    removes = this.selects.splice(0, length - 2)
                    this.unCheck(removes);
                }
            },
            unCheck(removes){
                let items = this.list.items;
                items.forEach(item =>{
                    removes.forEach((r) =>{
                        if(item.userId == r.userId) {
                            item.selected = false;
                        }
                    })
                })
            },
            clearSelectedItem(){
                this.selects = [];
            },
            switchUrl(index) {
                this.list.url = index ? statisticsUrl.url_member_performance : statisticsUrl.url_conversion_statistics;
                this.refresh();
            }
        }
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';

$gridColumns: 10;
$selectionItemColor: #f0f0f0;
$headerHoverColor: rgba(255, 255, 255, .15);
.table-box {
    // width: 1200px;
    overflow-x: scroll;
    .pagination-common {
        margin: 40px 0;
    }
}

.sort-table {
    @include clearfix();

    // width: 1312px; 
    // width: 1372px; +60 +92 +92
    width: 1614px;

    .order {
        min-height: auto;
    }

    .empty-data {
        text-align: center;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 160px;
            height: 160px;
        }
        & > p {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
        }
    }

    &-header {
        border: 1px solid #d3d3d3;
        border-left: none;
        background-color: #f8f8f8;

        @include clearfix();

        &-list {
            margin: 0;

            .heigit-40{
                height: 40px;
                line-height: 40px;
                .item-content:before {
                    margin-top: 11px!important;
                }
            }
            &-item {
                position: relative;
                border: none;
                color: #444;
                font-weight: bold;
                //width: 105px;
                // padding: 0 10px;
                
                height: 80px;
                line-height: 80px;

                margin: 0;
                float: left;
                background-color: #f8f8f8;
                margin: 0;
                text-align: center;
                border-right: 1px solid #d3d3d3;
                cursor: pointer;

                .el-dropdown {
                    width:100%;
                }

                .item-content {
                    // width: 95px;
                    padding: 0 10px;
                }

                //&:first-child {
                //    visibility: hidden;
                //}

                &:first-child {
                    cursor: default;
                }

                &:last-child {
                    border: 0 none;
                }

                // &:nth-child(1) .item-content { width: 112px; }
                // &:nth-child(11) .item-content{ width: 150px; }

                // &:nth-child(1) { width: 112px; }
                // //&:nth-child(1) { width: 82px; }
                // &:nth-child(2) { width: 104px; }
                // // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 134px; }
                // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 104px; }
                // &:nth-child(5) { width: 107px; }
                // &:nth-child(9) { width: 82px; }
                // &:nth-child(10) { width: 82px; }
                // &:nth-child(11) { width: 150px; }

                // &:nth-child(1) { width: 112px; }
                // //&:nth-child(1) { width: 82px; }
                // &:nth-child(2) { width: 104px; }
                // &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) { width: 134px; }
                // &:nth-child(5) { width: 107px; }
                // &:nth-child(9) { width: 82px; }
                // &:nth-child(10) { width: 82px; }
                // &:nth-child(11) { width: 150px; }

                &:nth-child(1) { 
                    width: 112px;
                    position: sticky;
                    left: 0;
                    z-index: 100;
                    border-left: 1px solid #ddd;
                }
                &:nth-child(2) { width: 150px; }
                &:nth-child(3) { width: 104px; }
                &:nth-child(4), &:nth-child(5) { width: 134px; }
                &:nth-child(7), &:nth-child(8), &:nth-child(9) { width: 174px; }
                &:nth-child(6) { width: 107px; }
                &:nth-child(10) { width: 174px; }
                &:nth-child(11) { width: 174px; }

                .icon {
                    display: inline-block !important;
                }

                .icon-font {
                    display: inline-block !important;
                    width: 21px;
                    text-align: center;
                    margin-right: 4px;
                    color: $primary;

                }

                .text {
                    color: #333;
                }

                .arrow {
                    display: inline-block;
                    width: 14px;
                    height: 8px;
                    background: url('~@src/assets/images/icon/statistics/icon_up_arrow.png') no-repeat 0 0;
                    background-size: 14px 8px;
                    transform: rotate(-180deg);
                }

                &.active,
                &:hover {
                    .text {
                        color: $primary;
                    }

                    .arrow {
                        transform: none;
                    }
                }

                &-dropdown {
                    display: none;
                    position: absolute;
                    top: 46px;
                    left: 50%;
                    width: 124px;
                    margin-left: -62px;
                    z-index: 10;
                }
                
                &:hover {
                    .sort-table-header-list-item-dropdown {
                        display: block;
                    }
                }


                .header-sub-wrap{
                    box-sizing: border-box;
                    border-top: 1px solid #D3D3D3;
                    position: relative;
                    &::after{
                        display: inline-block;
                        content: '';
                        width: 1px;
                        height: 40px;
                        background: #d3d3d3;
                        position: absolute;
                        right: -1px;
                        top: 0;
                    }
                    .sub-cell{
                        display: inline-block;
                        width: 30%;
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        border-right: 1px solid #D3D3D3;
                        &:last-child{
                            border: 0;
                            width: 40%;
                        }
                    }
                }
            }
        }
    }

    .order-wrapper {
        border: 1px solid #d3d3d3;
        border-left: none;
        border-top: none;
    }

    .pager {
        margin-top: 40px;
        margin-bottom: 38px;
    }

    .table-content {
        .order-wrapper > ul {
            margin: 0;
        }
        .order-item {
            padding: 0;
            border-bottom: 1px solid #d3d3d3;

            // &:nth-child(even) {
            //     background-color: #f8f8f8;
            // }
            &:nth-child(even) .sort-table-item .item {
                background-color: #f8f8f8;
            }

            &:first-child {
                border-top: 0 none;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        @for $i from 1 through $gridColumns {
            &.active-#{$i} {
                //.sort-table-item {
                //    > .item:nth-child(#{$i + 1}) {
                //        background-color: $white;
                //        box-shadow: 0 2px 3px 0 $border-dark;
                //        border: solid 1px $border-lighter;
                //        border-width: 0 1px;
                //    }
                //}

                .sort-table-item .item-has-sub:last-child() {
                    border-right: 0;
                }

                .order-item:nth-child(1),
                .order-item:nth-child(2),
                .order-item:nth-child(3) {
                    .item:nth-child(#{$i + 1}),
                    .item-has-sub:nth-child(#{$i + 1}) .sub-cell-wrap .sub-cell:last-child {
                        padding-left: 10px;

                        &:before {
                            content: '';
                            display: inline-block;
                            background-image: url('~@src/assets/images/icon/statistics/icon_rank_1.png');
                            width: 20px;
                            height: 20px;
                            position: relative;
                            color: #fff;
                            background-position: 0 0;
                            background-repeat: no-repeat;
                            vertical-align: top;
                            margin-top: 4px;
                            margin-right: 4px;
                            text-align: center;
                        }
                    }

                }

                .order-item:nth-child(2) .item:nth-child(#{$i + 1}):before,
                .order-item:nth-child(2) .item-has-sub:nth-child(#{$i + 1}) .sub-cell-wrap .sub-cell:last-child:before {
                    background-image: url('~@src/assets/images/icon/statistics/icon_rank_2.png');
                }

                .order-item:nth-child(3) .item:nth-child(#{$i + 1}):before,
                .order-item:nth-child(3) .item-has-sub:nth-child(#{$i + 1}) .sub-cell-wrap .sub-cell:last-child:before {
                    background-image: url('~@src/assets/images/icon/statistics/icon_rank_3.png');
                }

                .order-item:first-child {
                    .item:nth-child(#{$i + 1}) {
                        // margin-top: -1px;
                        margin-top: 0;
                        padding-top: 9px;
                    }
                }
            }
        }
    }

    &.enable-check {
        .sort-table-header ul li.active {
            background-color: #f0f0f0;
            background-image: none !important;

            .text {
                color: #333;
            }

            .icon {
                display: inline-block;
            }
        }

        .sort-table-header ul li:hover {
            background-image: none;
            color: #333;
            padding-bottom: 10px;
            margin-bottom: 0;
        }

        .table-content .sort-table-item > .item {
            background-color: inherit !important;
            border-left: none !important;
            border-right: none !important;
            box-shadow: none !important;
        }

        .table-content .sort-table-item > .item:before {
            visibility: hidden;
        }

        .sort-table-item {
            &.selected {
                background-color: #d9f4ec;
            }
        }
    }
}


.sort-table:not(.enable-check) {
    .sort-table-header {
        &-list {
            &-item:not(:first-child) {
                // &.active .item-content {
                //     width: 126px;
                // }
                &.active, &:hover {
                    background-color: rgba(56,188,157,.12);
                    //background-image: linear-gradient(-45deg, transparent 0, transparent 25%, $headerHoverColor 25%, $headerHoverColor 50%, transparent 50%, transparent 75%, $headerHoverColor 75%);
                    background-size: 6px 6px;
                    color: $primary;

                    .icon-font:before {
                        color: #fff;
                    }
                }

                &.active .item-content:before {
                    content: '';
                    display: inline-block;
                    background-image: url('~@src/assets/images/icon/statistics/icon_sort.png');
                    width: 20px;
                    height: 20px;
                    position: relative;
                    color: #fff;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    vertical-align: top;
                    margin-top: 32px;
                    margin-right: 6px;
                    text-align: center;
                }

                &.active .icon-font:before {
                    @extend .icon-bookmark-star:before !optional;
                    font-size: 21px;
                }
            }
        }
    }
}

</style>
