<template>
    <div class="statistics-conversion-funnel-chart" v-loading="workContentLoading">
        <chart ref="chart" :default-param="param"></chart>
    </div>
</template>

<script>
    import { statistics as statisticsUrls } from '#/js/config/api.json';
    import { extendObject } from '#/js/util/tools.js';
    import chartParam from './work-chart-setting.js';
    import statisticsDate from './work-chart-statistics.json';

    import Chart from '#/component/common/chart.vue';

    const DEFAULT_QUERY_PARAM = {
        startDate: '',
        endDate: '',
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1,
        scale: 1
    }

    export default {
        components: {
            Chart
        },
        data() {
            return {
                recommendationUrl: statisticsUrls.url_recommendation_statistics,
                additionalUrl: statisticsUrls.url_require_field_statistics,
                param: extendObject({}, chartParam),
                queryParam: extendObject({}, DEFAULT_QUERY_PARAM),
                recommendationCountArray: [],
                acceptCountArray: [],
                arrangeInterviewCountArray: [],
                interviewCountArray: [],
                offerCountArray: [],
                onboardCountArray: [],
                candidateCountArray: [],
                inquiryLogCountArray: [],
                orderCountArray: [],
                series: [],
                xAxisCategories: [],
                hasCandidateCount: false,
                hasInquiryLogCount: false,
                hasOrderCount: false,
                dateRange: 0,
                workContentLoading: false,
            }
        },
        mounted() {
            this.chart = this.$refs.chart;
            this.param.plotOptions.series.events.legendItemClick = function(event) {
                if(!this.visible) {
                    let index = event.target.index + 1;
                    return  _self.updateMinordate(index);
                } else {
                    return true;
                }
            };
        },
        methods: {

            update() {
                const _self = this;
                let params = Object.assign({}, this.queryParam);
                params.memberIds = params.userIds;
                delete params.userIds;

                _self.workContentLoading = true;
                _request({
                    url: _self.recommendationUrl,
                    method: 'POST',
                    data: params
                }).then(res => {
                    let recommendationCountArray =[],
                        acceptCountArray = [],
                        arrangeInterviewCountArray = [],
                        interviewCountArray = [],
                        offerCountArray = [],
                        onboardCountArray = [],
                        dataArray = [];

                    res.forEach((data, i) => {
                        dataArray[i] = data.timeNode;
                        recommendationCountArray[i] = data.recommendationCount;
                        acceptCountArray[i] = data.acceptCount;
                        arrangeInterviewCountArray[i] = data.arrangeInterviewCount;
                        interviewCountArray[i] = data.interviewCount;
                        offerCountArray[i] = data.offerCount;
                        onboardCountArray[i] = data.onboardCount;
                    })
                    _self.recommendationCountArray = recommendationCountArray;
                    _self.acceptCountArray = acceptCountArray;
                    _self.arrangeInterviewCountArray = arrangeInterviewCountArray;
                    _self.interviewCountArray = interviewCountArray;
                    _self.offerCountArray = offerCountArray;
                    _self.onboardCountArray = onboardCountArray;
                    _self.xAxisCategories = dataArray;
                    _self.updateChart();
                }).finally(() => {
                    _self.workContentLoading = false;
                })
            },

            updateMinordate(index) {
                if(index >= 7) {
                    const _self = this,
                        queryParam = _self.queryParam;
                    Object.assign(queryParam, {field: statisticsDate[Object.keys(statisticsDate)[index-1]].id});

                    _request({
                        url: _self.additionalUrl,
                        method: 'POST',
                        data: queryParam
                    }).then(res => {
                        let dataArray = [],
                            dataKeys = Object.keys(res);
                        dataKeys.forEach((key, i) => {
                            dataArray[i] = res[key];
                        })
                        switch(queryParam.field) {
                            case statisticsDate.candidate.id:
                                _self.candidateCountArray = dataArray;
                                _self.hasCandidateCount = true;
                                break;
                            case statisticsDate.candidateInquiryLog.id:
                                _self.inquiryLogCountArray = dataArray;
                                _self.hasInquiryLogCount = true;
                                break;
                            case statisticsDate.order.id:
                                _self.orderCountArray = dataArray;
                                _self.hasOrderCount = true;
                                break;
                        }
                        _self.updateChart();
                    })
                }
            },

            updateChart() {
                this.series = [this.recommendationCountArray, this.acceptCountArray, this.arrangeInterviewCountArray, this.interviewCountArray, this.offerCountArray, this.onboardCountArray, this.candidateCountArray, this.inquiryLogCountArray, this.orderCountArray];
                this.chart.updateData(this.series);
                this.chart.setXAxisCategories(this.xAxisCategories);
            },

            updateTooltipUnit() {
                if(this.queryParam.dimension === 1) {
                    this.chart.updateTooltipValueSuffix('（次）');
                } else if(this.queryParam.dimension === 2) {
                    this.chart.updateTooltipValueSuffix('（人）');
                }
            },

            refresh(param) {
                Object.assign(this.queryParam, param);
                // this.dateRange(this.queryParam.endDate, this.queryParam.startDate);
                this.dateRange = this.queryParam.endDate || this.queryParam.startDate;
                if(this.hasCandidateCount) {
                    this.updateMinordate(7);
                }
                if(this.hasInquiryLogCount) {
                    this.updateMinordate(8);
                }
                if(this.hasOrderCount) {
                    this.updateMinordate(9);
                }
                this.update();
                this.updateTooltipUnit();
            }
        }
    };

</script>

<style lang="scss">
.chart-tooltip-header {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 26px;
}

.chart-tooltip-body {
    font-size: 14px;
    line-height: 26px;
}

.chart-tooltip-value {
    padding-left: 10px;
}
</style>
