<template>
    <div class="job-dimension">
        <div class="card-list" v-loading="fakeParamLoading">
            <!-- <statistics-param-time customer-select-visible="true" team-select-visible="true"></statistics-param-time> -->
            <statistics-param-time 
                ref="statisticsParamTime" 
                :dataRangeData="dataRangeData"
                :selectedUserIds="param.userIds"
                :hasSubscribeBtn="false"
                :employee-select="employeeSelect"
                :customerSelectVisible="true"
                @date-select="dateSelectCb"
                @customer-select-cb="customerSelectCb"
            ></statistics-param-time>
        </div>

        <div class="card-list">
            <div class="chart-title-top">订单数量变化</div>
            <job-order-status-chart
                ref="jobOrderStatusChart"
                @subscirbe-click="jobChartSubscribe"
            ></job-order-status-chart>
        </div>

        <div class="card-list">
            <div class="chart-title-top">订单推荐状况</div>
            <a href="javascript:;" class="recommend-subscribeBtn col-2" @click="handleSubscribe">订阅数据</a>
            <job-recommend-change-info
                ref="jobRecommendInfo"
                :loading="recommendLoading"
            ></job-recommend-change-info>
        </div>

        <div class="card-list jobCountList" v-loading="listLoading">
            <div class="card col-7">
                <h2 class="title">
                    <i class="icon-font icon-building-o"></i> 客户订单量
                </h2>
                <!-- ko if: customerOrders().length > 0-->
                <ul class="best-order" v-if="customerOrders.length > 0">
                    <li class="order-list"
                        v-for="(item, index) in customerOrders"
                        :key="'customerOrders_'+index"
                        >
                        <a class="cell-l" 
                            @click="openCustomerItem(item.customerId)"
                            data-bind="attr: { href: customerId | tpl '/MyCompany/Customer/%p' }" target="_blank">
                            <i class="icon-font icon-building-o"></i>
                            <span class="customer-name" v-text="item.customerName"></span>
                        </a>
                        <a class="cell-r" 
                            href="javascript:;" 
                            @click="linkMyJob(item.customerId, item.userId)"
                            data-bind="click:$parents[0].linkMyJob">
                            <span class="job-count" v-text="item.orderCount"></span>
                        </a>
                    </li>
                </ul>
                <a 
                    href="javascript:;" 
                    class="loading-more" 
                    @click="setTake(0)"
                    v-if="customerTake <= customerOrders.length"
                    >加载更多</a>
                <!-- /ko -->
                <!-- ko if: customerOrders().length == 0-->
                <ul class="best-recommend" v-if="customerOrders.length == 0">
                    <li class="empty">暂无数据</li>
                </ul>
                <!-- /ko -->
            </div>

            <div class="card col-7 card-center">
                <h2 class="title">
                    <i class="icon-font icon-renyuan"></i> 成员订单量
                </h2>
                <!-- ko if: memberOrders().length > 0-->
                <ul class="best-recommend" data-bind="foreach:memberOrders" v-if="memberOrders.length > 0">
                    <li class="order-list"
                        v-for="(item, index) in memberOrders"
                        :key="'memberOrders_'+index">
                        <a class="cell-l" 
                            @click="openMemberItem(item.userId)"
                            data-bind="attr: $parents[0]._getRootAttrBind(userId)" target="_blank">
                            <i class="icon-font icon-renyuan"></i>
                            <span class="customer-name" data-bind="text:nickname" v-text="item.nickname"></span>
                        </a>
                        <a class="cell-r" 
                            href="javascript:;;" 
                            @click="linkMyJob(item.customerId, item.userId)"
                            data-bind="click:$parents[0].linkMyJob">
                            <span class="job-count" data-bind="text:orderCount" v-text="item.orderCount"></span>
                        </a>
                    </li>
                </ul>
                <a 
                    href="javascript:;" 
                    class="loading-more" 
                    @click="setTake(1)"
                    v-if="memberTake <= memberOrders.length"
                    >加载更多</a>
                <!-- /ko -->
                <!-- ko if: memberOrders().length == 0-->
                <ul class="best-recommend" v-if="memberOrders.length == 0">
                    <li class="empty">暂无数据</li>
                </ul>
                <!-- /ko -->
            </div>

            <div class="card col-7 pull-right">
                <h2 class="title">
                    <i class="icon-font icon-address"></i> 地区订单量
                </h2>
                <!-- ko if: areaOrders().length > 0-->
                <ul class="best-recommend" data-bind="foreach:areaOrders" v-if="areaOrders.length > 0">
                    <li class="order-list"
                        v-for="(item, index) in areaOrders"
                        :key="'areaOrders_'+index">
                        <a class="cell-l localtion" href="javascript:;" >
                            <i class="icon-font icon-address"></i>
                            <span class="customer-name" data-bind="text:areaName" v-text="item.areaName"></span>
                        </a>
                        <a class="cell-r localtion" href="javascript:;">
                            <span class="job-count" data-bind="text:orderCount" v-text="item.orderCount"></span>
                        </a>
                    </li>
                </ul>
                <a 
                    href="javascript:;" 
                    class="loading-more" 
                    v-if="areaTake <= areaOrders.length"
                    @click="setTake(2)">加载更多</a>
                <!-- /ko -->

                <!-- ko if: areaOrders().length == 0-->
                <ul class="best-recommend" v-if="areaOrders.length == 0">
                    <li class="empty">暂无数据</li>
                </ul>
                <!-- /ko -->
            </div>
        </div>
        <subscribe-dialog
            ref="subscribeDialog"
        ></subscribe-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import JobOrderStatusChart from './job-order-status-chart.vue';
import JobRecommendChangeInfo from './job-recommend-change-info.vue';
import SubscribeDialog from './subscribe-dialog.vue';
import StatisticsParamTime from '../statisticsParamTime.vue';

const DEFAULT_DATERANGE_FORMAT = "YYYY-MM-DD";
const STARTDATE = moment().subtract(29, 'days').format(DEFAULT_DATERANGE_FORMAT);
const TODAY = moment().format(DEFAULT_DATERANGE_FORMAT);
const DEFAULT_PARAM = {
    startDate: STARTDATE,
    endDate: TODAY,
    teamId: 0,
    teamIndex:0
};
const lastMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');

export default {
    name: "statisticJob",
    components: {
        JobOrderStatusChart,
        JobRecommendChangeInfo,
        SubscribeDialog,
        StatisticsParamTime,
    },
    data() {
        return {
            customerOrders: [],
            memberOrders: [],
            areaOrders: [],
            customerTake: 7,
            memberTake: 7,
            areaTake: 7,
            param: Object.assign({}, DEFAULT_PARAM, {
                creatorIds: '',
                customerIds: '',
                primaryCustomerIds: '',
                userIds: []
            }),
            dataRangeData: {startDate: lastMonth},
            selectCustomer: null,

            recommendLoading: false,
            listLoading: false,

            fakeParamLoading: false,
        }
    },
    computed: {
        
    },
    mixins: [
        
    ],
    created(){
        this.getStorageParam();
    },
    mounted() {
        // this.refresh();
        this.fakeParamLoading = true;
    },
    methods: {
        refresh(param) {
            let params = Object.assign({}, this.param, param);
            const creatorIds = params.userIds.length ==0 ? null: params.userIds;
            params.creatorIds = creatorIds;
            
            this.$refs.jobOrderStatusChart.setData(params);
            // this.jobOrderStatusChart.updateData();
            this.getRecommendChangeData(params);
            this.getOrderCounts(params, 7, 0);
            this.getOrderCounts(params, 7, 1);
            this.getOrderCounts(params, 7, 2);
            // this.customerTake(7);
            // this.memberTake(7);
            // this.areaTake(7);
        },
        // 员工选取
        employeeSelect(userIds, text) {
            if(userIds && userIds.length > 0) {
                this.param.userIds = userIds;
                this.refresh();
            } else {
                this.param.userIds = [this.userInfo.id];
                this.refresh();
            }
            // console.log(userIds, text)
            this.$refs.statisticsParamTime.teamMemberPopoverVisible = false;
            this.$refs.statisticsParamTime.selectedMembersText = text || '全部成员';
        },
        dateSelectCb(start, end) {
            this.param.startDate = start;
            this.param.endDate = end;
            this.refresh();
        },
        customerSelectCb(customer) {
            this.selectCustomer= customer;
            this.param.primaryCustomerIds = customer.customerId;
            this.param.customerIds = customer.customerId;
            this.refresh();
        },
        getOrderCounts(param, take, type = 0){
            const creatorIds = param.userIds.length ==0 ? null: param.userIds;
            let params = {
                startDate: param.startDate,
                endDate: param.endDate,
                teamId: param.teamId,
                teamIndex: param.teamIndex,
                take: take || 7,
                creatorIds: creatorIds,
                customerIds: param.customerIds || null,
            };
            this.listLoading = true;
            
            if(type>=0){
                params = {...params, requestType: type};
                _request({
                    method: "POST",
                    url: '/MyCompany/OrdersCountTop',
                    data: {
                        ...params,
                        customerIds: this.param.primaryCustomerIds || null,
                        }
                }).then(res => {
                    if(type == 0) {
                        this.customerOrders = res;
                    } else if(type == 1){
                        this.memberOrders = res;
                    } else if(type == 2) {
                        this.areaOrders = res;
                    }
                }).finally(() => {
                    this.listLoading = false;
                    if(this.$route.query.hasOwnProperty('fromMore')) {
                        const el = document.querySelector('.tab-view');
                        if(el) el.scrollTop = 1200;
                    }
                });
            }

                // $.post('/MyCompany/OrdersCountTop',
                // Object.assign(customerParams,{requestType: 0,customerIds:self.param.primaryCustomerIds()}),function (rtn) {
                //     if (rtn.succeeded) {
                //         let $card1 = $('.jobCountList .card').eq(0);
                //         this.customerOrders(rtn.data);
                //         if(rtn.data.length<this.customerTake()){
                //             $card1.find('.loading-more').hide();
                //         }else{
                //             $card1.find('.loading-more').show();
                //         }
                //     }else{
                //         shortTips(rtn.message||'获取数据失败，请重试')
                //     }
                // }.bind(this));
            
            // if(!type || type==1){
            //     $.post('/MyCompany/OrdersCountTop',Object.assign({requestType:1},param),function (rtn) {
            //         if (rtn.succeeded) {
            //             let $card2 = $('.jobCountList .card').eq(1);
            //             this.memberOrders(rtn.data);
            //             if(rtn.data.length<this.memberTake()){
            //                 $card2.find('.loading-more').hide();
            //             }else{
            //                 $card2.find('.loading-more').show();
            //             }
            //         }else{
            //             shortTips(rtn.message||'获取数据失败，请重试')
            //         }
            //     }.bind(this));
            // }
            
            // if(!type || type==2){
            //     $.post('/MyCompany/OrdersCountTop',Object.assign({requestType:2},param),function (rtn) {
            //         if (rtn.succeeded) {
            //             let $card3 = $('.jobCountList .card').eq(2);
            //             this.areaOrders(rtn.data);
            //             if(rtn.data.length<this.areaTake()){
            //                 $card3.find('.loading-more').hide();
            //             }else{
            //                 $card3.find('.loading-more').show();
            //             }
            //         }else{
            //             shortTips(rtn.message||'获取数据失败，请重试')
            //         }
            //     }.bind(this));
            // }            
        },
        setTake(type){
            switch(type){
                case 0: 
                    this.customerTake += 7;
                    this.getOrderCounts(this.param, this.customerTake, type);
                    break;
                case 1:
                    this.memberTake += 7;
                    console.log(this.memberTake)
                    this.getOrderCounts(this.param, this.memberTake, type);
                    break;
                case 2:
                    this.areaTake += 7;
                    this.getOrderCounts(this.param, this.areaTake, type);
                    break;
                default: break;
            }
        },

        getStorageParam(){
            let statisticsJobParamsStr = localStorage.getItem('statisticsJobParams');
            if (statisticsJobParamsStr) {
                let statisticsJobParams = JSON.parse(statisticsJobParamsStr);
                localStorage.removeItem('statisticsJobParams');
                this.param = {
                    ...statisticsJobParams,
                    ordersCountType: statisticsJobParams.type,
                }
            }
            if(this.$route.query.hasOwnProperty('fromMore')) {
                this.$parent.activeNav = 1;
                this.isFromJobRightMore = true;
            }
        },
        getRecommendChangeData(param){
            const creatorIds = param.userIds && param.userIds.length==0 ? null: param.userIds;
            let params = {
                startDate: param.startDate,
                endDate: param.endDate,
                teamId: param.teamId,
                teamIndex: param.teamIndex,
                creatorIds: creatorIds,
                customerIds: param.customerIds || null,
            };
            
            if (!param.startDate) {
                let today = new Date();
                params.endDate = today.format('yyyy-MM-dd');
                params.startDate = new Date(today.setTime(today.getTime() - 30 * 24 * 60 * 60 * 1000)).format('yyyy-MM-dd');
            }
            this.recommendLoading = true;

            _request({
                method: "POST",
                url: '/MyCompany/SatisticDeliveryList',
                // headers:{
                //     'Content-type': 'application/x-www-form-urlencoded'
                //     },
                data: params || null
            }).then(res => {
                this.$refs.jobRecommendInfo.setData(res, this.param.customerIds, this.param.teamIndex, this.param.creatorIds);
            }).finally(() => {
                this.recommendLoading = false;
                this.fakeParamLoading = false;
            });
        },
        handleSubscribe() {
            let creatorIdsText = this.$refs.statisticsParamTime.selectedMembersText;
            let customerText = this.selectCustomer ? this.selectCustomer.customerName : '所有客户';

            this.$refs.subscribeDialog.initSubscribeDialog({
                describitionHtml: '<span class="text-grey">你正在订阅 <span class="text-regular"><span class="text-ellipsis" title="'+creatorIdsText+'">'+creatorIdsText+'</span>、'+ customerText +'、前一个月 </span>的职位交付效率。</span>',
                getUrl: '/MyCompany/GetJobSubscription?JobSubscriptionType=2',
                postUrl: '/MyCompany/CreateJobSubscription',
                extraParam: Object.assign(this.param, {
                    jobSubscriptionType: 2
                })
            })

        },
        jobChartSubscribe() {
            let creatorIdsText = this.$refs.statisticsParamTime.selectedMembersText;
            let customerText = this.selectCustomer ? this.selectCustomer.customerName : '所有客户';

            this.$refs.subscribeDialog.initSubscribeDialog({
                describitionHtml:'<span class="text-grey">你正在订阅 <span class="text-regular"><span class="text-ellipsis" title="'+creatorIdsText+'">'+creatorIdsText+'</span>、'+ customerText +'、前一个月 </span>的职位增长数据。</span>',
                getUrl: '/MyCompany/GetJobSubscription?JobSubscriptionType=1',
                postUrl: '/MyCompany/CreateJobSubscription',
                extraParam:{
                    customerIds: this.param.customerIds,
                    creatorIds: this.param.creatorIds,
                    teamId: this.param.teamId,
                    jobSubscriptionType: 1
                }
            });
        },
        linkMyJob(_customerId, _userId){
            let customerId = _customerId, creatorId = _userId;

            // param = ko.mapping.toJS(this.param);
            localStorage.setItem('myCompanyJobsListParams', JSON.stringify({
                startDate: !this.param.startDate ? undefined : new Date(this.param.startDate).format('yyyy-MM-dd'),
                endDate: !this.param.endDate ? undefined: new Date(this.param.endDate).format('yyyy-MM-dd'),
                customerIds: customerId ? [customerId] : this.param.customerIds||[],
                creatorIds: creatorId ? [creatorId] : this.param.creatorIds || [],
                teamIndex: this.param.teamIndex || 0,
            }));
            // window.open('/Headhunting/MyCompany.html#/jobList', '_blank');
            window.open('/#/jobList', '_blank');
        },
        
        _getRootAttrBind(userId){
            const attrs = {
                href: userId ? (userId === server.userId ? '/My/Index' : `/User/${userId}`) : 'javascript: void(0);'
            };
            return attrs;
        },
        openCustomerItem(customerId) {
            // window.open(`/Headhunting/MyCompany.html#/Customer/${customerId}`, '_blank');
            window.open(`/#/Customer/${customerId}`, '_blank');
        },
        openMemberItem(userId) {
            window.open(`/User/${userId}`, '_blank');
        },
    }
}
</script>

<style lang="scss">

.job-dimension{
    // width: 1732px;
    // .card-top{
    //     width: 100%;
    // }
    .card-list{
        // width: 1732px;
        // display: inline-block;
        // width: 844px;
        // overflow: hidden;
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        background: #fff;
        position: relative;
        .job-order-status{
            padding: 0 100px 40px;
            margin: 0;
            .info div{
                display: none;
            }
            .subscribeBtn {
                position: absolute;
                right: 50px;
                top: 20px;
            }
        }
        .chart-title {
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            color: #666666;
            font-weight: bold;
            padding-bottom: 30px;
        }
        .chart-title-top{
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            color: #666;
            font-weight: 700;
            padding-top: 40px;
        }
        .hot-date-range{
            width: 420px;
            margin-left: 30px;
        }
        .card{
            margin-bottom: 20px;
            // width: 30%;
            // display: inline-block;
            // margin: 0 1%;
            flex: 1;
            max-width: 30%;
            vertical-align: top;
            .title{
                color: #333;
                font-size: 16px;
                position: relative;
                padding-top: 10px;
                >i{
                color: #38bc9c;
                margin-right: 5px;
                }
            }
            
            ul{
                border: 1px solid #cccccc;
                border-radius: 2px;
                padding: 10px 20px;
                background: #fff;
                
                li{
                    height: 45px;
                    line-height: 45px;
                    font-size: 0;
                    a{
                        display: inline-block;
                        padding: 0;
                        &:hover span{
                            color: #38bc9c;
                        } 
                        &.target-unclick:hover span{
                            color:#666;
                        }
                        &.cell-l{
                            width: 85%;
                            font-size: 16px;
                            cursor: pointer;
                        }
                        &.cell-r{
                            width: 15%;
                            font-size: 16px;
                            text-align: right;
                        }
                    }
                    .localtion{
                        &:hover span{
                            cursor: default;
                            color:#666;
                        }
                        &.cell-l{cursor: default;}
                        &.cell-r{cursor: default;}
                    }
                    >span{
                        color:#666
                    }
                } 
                .empty {
                    font-size: 16px;
                    text-align: center;
                }
                
                li + li{
                    border-top:1px solid #eeeeee;
                }
                span{
                    color: #666;
                }
                .customer-name{
                    margin: 0 10px;
                    display: inline-block;
                    max-width: 130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: top;
                }
                .customer-location {
                    color: #b9b9b9;
                }
            }

            .loading-more{
                width: 100%;
                display: inline-block;
                border: 1px solid #cccccc;
                border-radius: 2px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                margin-top: 10px;
                font-size: 14px;
                color: #b9b9b9;
                background: #fff;
                &:hover{
                    color: #38bc9c;
                } 
            }
            // &.card-center{
            //     margin: 0 75px;
            // }
        }
        .job-recommend-change-info{
            width: 800px;
            margin: 0 auto;
            padding: 30px 0;
        }
        .recommend-subscribeBtn {
            width: 100px;
            height: 35px;
            line-height: 35px;
            background: #38bc9c;
            color: #fff;
            text-align: center;
            border-radius: 2px;
            position: absolute;
            right: 50px;
            top: 30px;
        }
    }
    .jobCountList{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }
}
</style>
