var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sort-table-item", class: { selected: _vm.selected } },
    [
      _c(
        "span",
        { staticClass: "item" },
        [
          _c("avatar", {
            staticClass: "user-avatar",
            attrs: {
              size: "mg",
              userId: _vm.data.userId,
              enableName: true,
              enableRefImage: false,
              realName: _vm.data.realName,
              enableCard: true,
              enableLink: true,
            },
          }),
        ],
        1
      ),
      _c("span", { staticClass: "item" }, [
        _c("span", {
          staticClass: "orders",
          attrs: { title: _vm.data.teamChain },
          domProps: { textContent: _vm._s(_vm.data.teamChain.join()) },
        }),
      ]),
      _c(
        "span",
        {
          staticClass: "item",
          on: {
            click: function ($event) {
              return _vm.goToDetail($event, {
                key: "candidateDetails",
                achievementDetailType: "1",
              })
            },
          },
        },
        [
          _c("a", { attrs: { href: "javascript:void(0)" } }, [
            _c("span", {
              staticClass: "candidate",
              domProps: { textContent: _vm._s(_vm.data.candidateCount) },
            }),
          ]),
        ]
      ),
      _vm.headerItems[3].value == 2
        ? _c(
            "span",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.goToDetail($event, {
                    key: "candidateInquiryLogDetails",
                    achievementDetailType: "2",
                  })
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c("span", {
                  staticClass: "accept",
                  domProps: { textContent: _vm._s(_vm.data.inquiryLogCount) },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.headerItems[3].value == 3
        ? _c(
            "span",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.goToDetail($event, {
                    key: "candidateInquiryLogDetails",
                    achievementDetailType: "1",
                  })
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c("span", {
                  staticClass: "accept",
                  domProps: {
                    textContent: _vm._s(_vm.data.validInquiryLogCount),
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.headerItems[4].value == 4
        ? _c(
            "span",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.goToDetail($event, {
                    key: "callRecordDetails",
                    achievementDetailType: "1",
                  })
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c("span", {
                  staticClass: "callRecord",
                  domProps: { textContent: _vm._s(_vm.data.callRecordCount) },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.headerItems[4].value == 5
        ? _c(
            "span",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.goToDetail($event, {
                    key: "callRecordDetails",
                    achievementDetailType: "2",
                  })
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c("span", {
                  staticClass: "callRecord",
                  domProps: {
                    textContent: _vm._s(_vm.data.validCallRecordCount),
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "item",
          on: {
            click: function ($event) {
              return _vm.goToDetail($event, {
                key: "jobDetails",
                achievementDetailType: "1",
              })
            },
          },
        },
        [
          _c("a", { attrs: { href: "javascript:void(0)" } }, [
            _c("span", {
              staticClass: "interview",
              domProps: { textContent: _vm._s(_vm.data.jobCount) },
            }),
          ]),
        ]
      ),
      _vm.headerItems[6].value == 7
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendCandidateM1Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendCandidateM2Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "1",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "offer",
                          domProps: {
                            textContent: _vm._s(
                              _vm.data.recommendCandidateCount
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendCandidateCount),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm.headerItems[6].value == 8
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(
                          _vm.data.recommendAcceptCandidateM1Count
                        ),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(
                          _vm.data.recommendAcceptCandidateM2Count
                        ),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "2",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "offer",
                          domProps: {
                            textContent: _vm._s(
                              _vm.data.recommendAcceptCandidateCount
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(
                          _vm.data.recommendAcceptCandidateCount
                        ),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm.headerItems[7].value == 9
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "injob",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendM1Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "injob",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendM2Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "4",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "injob",
                          domProps: {
                            textContent: _vm._s(_vm.data.recommendCount),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "4",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendCount),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm.headerItems[7].value == 10
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "injob",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendAcceptM1Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "injob",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendAcceptM2Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "5",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "injob",
                          domProps: {
                            textContent: _vm._s(_vm.data.recommendAcceptCount),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "5",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.recommendAcceptCount),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm.headerItems[8].value == 11
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewM1Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewM2Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "7",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "candidate",
                          domProps: {
                            textContent: _vm._s(_vm.data.interviewCount),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "7",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewCount),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm.headerItems[8].value == 12
        ? _c("span", { staticClass: "item-has-sub" }, [
            _vm.isCFUser
              ? _c("div", { staticClass: "sub-cell-wrap" }, [
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewApprovedM1Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewApprovedM2Count),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "sub-cell" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "recommendationDetails",
                              achievementDetailType: "8",
                            })
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "candidate",
                          domProps: {
                            textContent: _vm._s(
                              _vm.data.interviewApprovedCount
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "sub-single-item",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "8",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "offer",
                      domProps: {
                        textContent: _vm._s(_vm.data.interviewApprovedCount),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _c("span", { staticClass: "item-has-sub" }, [
        _vm.isCFUser
          ? _c("div", { staticClass: "sub-cell-wrap" }, [
              _c("div", { staticClass: "sub-cell" }, [
                _c("span", {
                  staticClass: "candidate",
                  domProps: { textContent: _vm._s(_vm.data.offerM1Count) },
                }),
              ]),
              _c("div", { staticClass: "sub-cell" }, [
                _c("span", {
                  staticClass: "candidate",
                  domProps: { textContent: _vm._s(_vm.data.offerM2Count) },
                }),
              ]),
              _c("div", { staticClass: "sub-cell" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "10",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: { textContent: _vm._s(_vm.data.offerCount) },
                    }),
                  ]
                ),
              ]),
            ])
          : _c(
              "a",
              {
                staticClass: "sub-single-item",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.goToDetail($event, {
                      key: "recommendationDetails",
                      achievementDetailType: "10",
                    })
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "offer",
                  domProps: { textContent: _vm._s(_vm.data.offerCount) },
                }),
              ]
            ),
      ]),
      _c("span", { staticClass: "item-has-sub" }, [
        _vm.isCFUser
          ? _c("div", { staticClass: "sub-cell-wrap" }, [
              _c("div", { staticClass: "sub-cell" }, [
                _c("span", {
                  staticClass: "candidate",
                  domProps: { textContent: _vm._s(_vm.data.onboardM1Count) },
                }),
              ]),
              _c("div", { staticClass: "sub-cell" }, [
                _c("span", {
                  staticClass: "candidate",
                  domProps: { textContent: _vm._s(_vm.data.onboardM2Count) },
                }),
              ]),
              _c("div", { staticClass: "sub-cell" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "11",
                        })
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "candidate",
                      domProps: { textContent: _vm._s(_vm.data.onboardCount) },
                    }),
                  ]
                ),
              ]),
            ])
          : _c(
              "a",
              {
                staticClass: "sub-single-item",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.goToDetail($event, {
                      key: "recommendationDetails",
                      achievementDetailType: "11",
                    })
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "offer",
                  domProps: { textContent: _vm._s(_vm.data.onboardCount) },
                }),
              ]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }