var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-dimension" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fakeParamLoading,
              expression: "fakeParamLoading",
            },
          ],
          staticClass: "card-list",
        },
        [
          _c("statistics-param-time", {
            ref: "statisticsParamTime",
            attrs: {
              dataRangeData: _vm.dataRangeData,
              selectedUserIds: _vm.param.userIds,
              hasSubscribeBtn: false,
              "employee-select": _vm.employeeSelect,
              customerSelectVisible: true,
            },
            on: {
              "date-select": _vm.dateSelectCb,
              "customer-select-cb": _vm.customerSelectCb,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-list" },
        [
          _c("div", { staticClass: "chart-title-top" }, [
            _vm._v("订单数量变化"),
          ]),
          _c("job-order-status-chart", {
            ref: "jobOrderStatusChart",
            on: { "subscirbe-click": _vm.jobChartSubscribe },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-list" },
        [
          _c("div", { staticClass: "chart-title-top" }, [
            _vm._v("订单推荐状况"),
          ]),
          _c(
            "a",
            {
              staticClass: "recommend-subscribeBtn col-2",
              attrs: { href: "javascript:;" },
              on: { click: _vm.handleSubscribe },
            },
            [_vm._v("订阅数据")]
          ),
          _c("job-recommend-change-info", {
            ref: "jobRecommendInfo",
            attrs: { loading: _vm.recommendLoading },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "card-list jobCountList",
        },
        [
          _c("div", { staticClass: "card col-7" }, [
            _vm._m(0),
            _vm.customerOrders.length > 0
              ? _c(
                  "ul",
                  { staticClass: "best-order" },
                  _vm._l(_vm.customerOrders, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: "customerOrders_" + index,
                        staticClass: "order-list",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "cell-l",
                            attrs: {
                              "data-bind":
                                "attr: { href: customerId | tpl '/MyCompany/Customer/%p' }",
                              target: "_blank",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openCustomerItem(item.customerId)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon-font icon-building-o",
                            }),
                            _c("span", {
                              staticClass: "customer-name",
                              domProps: {
                                textContent: _vm._s(item.customerName),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "cell-r",
                            attrs: {
                              href: "javascript:;",
                              "data-bind": "click:$parents[0].linkMyJob",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkMyJob(
                                  item.customerId,
                                  item.userId
                                )
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "job-count",
                              domProps: {
                                textContent: _vm._s(item.orderCount),
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.customerTake <= _vm.customerOrders.length
              ? _c(
                  "a",
                  {
                    staticClass: "loading-more",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.setTake(0)
                      },
                    },
                  },
                  [_vm._v("加载更多")]
                )
              : _vm._e(),
            _vm.customerOrders.length == 0
              ? _c("ul", { staticClass: "best-recommend" }, [
                  _c("li", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "card col-7 card-center" }, [
            _vm._m(1),
            _vm.memberOrders.length > 0
              ? _c(
                  "ul",
                  {
                    staticClass: "best-recommend",
                    attrs: { "data-bind": "foreach:memberOrders" },
                  },
                  _vm._l(_vm.memberOrders, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: "memberOrders_" + index,
                        staticClass: "order-list",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "cell-l",
                            attrs: {
                              "data-bind":
                                "attr: $parents[0]._getRootAttrBind(userId)",
                              target: "_blank",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openMemberItem(item.userId)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "icon-font icon-renyuan" }),
                            _c("span", {
                              staticClass: "customer-name",
                              attrs: { "data-bind": "text:nickname" },
                              domProps: { textContent: _vm._s(item.nickname) },
                            }),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "cell-r",
                            attrs: {
                              href: "javascript:;;",
                              "data-bind": "click:$parents[0].linkMyJob",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkMyJob(
                                  item.customerId,
                                  item.userId
                                )
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "job-count",
                              attrs: { "data-bind": "text:orderCount" },
                              domProps: {
                                textContent: _vm._s(item.orderCount),
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.memberTake <= _vm.memberOrders.length
              ? _c(
                  "a",
                  {
                    staticClass: "loading-more",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.setTake(1)
                      },
                    },
                  },
                  [_vm._v("加载更多")]
                )
              : _vm._e(),
            _vm.memberOrders.length == 0
              ? _c("ul", { staticClass: "best-recommend" }, [
                  _c("li", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "card col-7 pull-right" }, [
            _vm._m(2),
            _vm.areaOrders.length > 0
              ? _c(
                  "ul",
                  {
                    staticClass: "best-recommend",
                    attrs: { "data-bind": "foreach:areaOrders" },
                  },
                  _vm._l(_vm.areaOrders, function (item, index) {
                    return _c(
                      "li",
                      { key: "areaOrders_" + index, staticClass: "order-list" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "cell-l localtion",
                            attrs: { href: "javascript:;" },
                          },
                          [
                            _c("i", { staticClass: "icon-font icon-address" }),
                            _c("span", {
                              staticClass: "customer-name",
                              attrs: { "data-bind": "text:areaName" },
                              domProps: { textContent: _vm._s(item.areaName) },
                            }),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "cell-r localtion",
                            attrs: { href: "javascript:;" },
                          },
                          [
                            _c("span", {
                              staticClass: "job-count",
                              attrs: { "data-bind": "text:orderCount" },
                              domProps: {
                                textContent: _vm._s(item.orderCount),
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.areaTake <= _vm.areaOrders.length
              ? _c(
                  "a",
                  {
                    staticClass: "loading-more",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.setTake(2)
                      },
                    },
                  },
                  [_vm._v("加载更多")]
                )
              : _vm._e(),
            _vm.areaOrders.length == 0
              ? _c("ul", { staticClass: "best-recommend" }, [
                  _c("li", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c("subscribe-dialog", { ref: "subscribeDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("i", { staticClass: "icon-font icon-building-o" }),
      _vm._v(" 客户订单量\n            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("i", { staticClass: "icon-font icon-renyuan" }),
      _vm._v(" 成员订单量\n            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("i", { staticClass: "icon-font icon-address" }),
      _vm._v(" 地区订单量\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }