var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.funnelChartLoading,
          expression: "funnelChartLoading",
        },
      ],
      staticClass: "statistics-conversion-funnel-chart",
    },
    [
      _c("chart", {
        ref: "chart",
        attrs: { "default-param": _vm.param, "defer-show": _vm.deferShow },
      }),
      _c("div", { staticClass: "desc" }, [
        _c("span", { staticClass: "legend-title" }, [
          _c("span", [
            _c("i", { staticClass: "icon reverse-triangle" }),
            _c("span", { domProps: { textContent: _vm._s(_vm.legendTitle) } }),
          ]),
          _c("span", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm._f("tpl")(_vm.totalConversionRate, "%p%"))),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }