<template>
    <!-- 团队数据Team Data -->
    <div class="statistics-page">
        <!-- 成员维度和职业维度的tab切换 -->
        <div class="tab-wrapper">
            <div class="tab-panel">
                <a 
                    class="tab pull-left" 
                    href="javascript:;"
                    v-for="(item,index) in topNavs"
                    :key="'topNavs_'+ index"
                    :class="{'active': index == activeNav}"
                    @click="handleNavTap(index)"
                    v-text="item.navText"
                >成员维度</a>
                <!-- <a class="tab pull-right" href="/MyCompany/Performances/Job">职位维度</a> -->
            </div>
        </div>

        <!-- 成员维度 -->
        <div class="member-dimension" v-show="activeNav == 0">
            <div class="shortcuts">
                <div>
                    <statistics-param-time 
                        ref="statisticsParamTime" 
                        :dataRangeData="dataRangeData"
                        :employee-loaded="employeeLoaded" 
                        :employee-select="employeeSelect"
                        :selectedUserIds="param.userIds"
                        @date-select="handleDateSelect"
                    ></statistics-param-time>
                </div>
                <statistics-total
                    ref="statisticsTotal"
                    page='teamData'
                ></statistics-total>
                    <!-- :jump-detail="jumpDetail"
                        :start-date="param.startDate"
                        :end-date="param.endDate" -->
            </div>
            <div class="data-detail">
                <div class="charts">
                    <statistics-charts ref="statisticsCharts" :is-multiple-employee="isMultipleEmployee"></statistics-charts>
                </div>
                <div class="selected">
                    <statistics-selected-items ref="statisticsSelectedItems"></statistics-selected-items>
                </div>
                <div class="table">
                    <div class="sort-title">
                        成员明细
                        <span>
                            <i>TIPS:</i>
                            列表可选，选中后按当前列表数据倒序排列。
                        </span>
                        <div class="export-members" @click="exportMembers" v-loading="loading">
                            导出成员明细
                        </div>
                    </div>
                    <sort-table 
                        ref="sortTable" 
                        :is-multiple-employee="true" 
                        :jump-detail="jumpDetail"
                        >
                    </sort-table>
                </div>
            </div>
        </div>
        <!-- 职业维度 -->
        <statistics-job
            v-show="activeNav == 1"
        ></statistics-job>

        <!-- <modal 
            ref="subscribedModal"
            size="lg"
            width="450px"
            :caption="subscribeTitle"
            :enable-cancel="false"
            :enable-confirm="false"
        >
            <subscribed-list 
                v-on:handleSubscribeTitle="handleSubscribeTitle"
                v-on:closeSubscribeModal="closeSubscribeModal"
                v-on:showAddSubscribe="showAddSubscribe">
            </subscribed-list>
        </modal> -->
    </div>
</template>

<script>
    import moment from 'moment';

    import StatisticsParamTime from '../component/statisticsParamTime.vue';
    import StatisticsTotal from '../component/statisticsTotal.vue';
    import StatisticsCharts from '../component/statistics-charts/statisticsCharts.vue';
    import StatisticsSelectedItems from '../component/statisticsSelectedItems.vue';
    import SortTable from '../component/sortTable.vue';
    import {statistics as statisticsUrl} from '#/js/config/api.json';
    import StatisticsJob from '../component/statistics-job/statisticsJob.vue';
    import {downloadFile} from '#/js/util/fileUtil.js'

    const DEFAULT_DATERANGE_FORMAT = "YYYY-MM-DD";
    const YESTODAY = moment().subtract(1, 'days').format(DEFAULT_DATERANGE_FORMAT);
    const lastMonth = moment().subtract(1, 'months').format(DEFAULT_DATERANGE_FORMAT);

    const DEFAULT_PARAM = {
        startDate: lastMonth,
        endDate: YESTODAY,
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1
    }

    export default {
        name: "statisticPage",
        components: {
            StatisticsParamTime,
            StatisticsTotal,
            StatisticsCharts,
            StatisticsSelectedItems,
            SortTable,

            StatisticsJob,
            // SubscribedList,
            // Modal
        },
        data() {
            return {
                param: Object.assign({}, DEFAULT_PARAM, {
                    userIds: []
                }),
                loading: false,
                subscribeTitle:'已订阅',
                topNavs: [
                    {
                        navText: '成员维度',
                    },
                    {
                        navText: '职位维度',
                    }
                ],
                activeNav: 0,
                dataRangeData: {startDate: lastMonth}
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isMultipleEmployee() {
                return this.param.userIds ? this.param.userIds.length > 1 : false
            }
        },
        mounted() {
            //成员维度
            this.statisticsParamTime = this.$refs.statisticsParamTime;
            this.statisticsTotal = this.$refs.statisticsTotal;
            this.statisticsCharts = this.$refs.statisticsCharts;
            this.sortTable = this.$refs.sortTable;
            this.statisticsSelectedItems = this.$refs.statisticsSelectedItems;
            this.statisticsParam = this.statisticsCharts.$refs.statisticsParam;
            this.teamSelect = this.statisticsParamTime.$refs.employees;

            this.statisticsParam.onDateRangeChange(this.param.startDate, this.param.endDate);
            // this.getStorageParam();
            //
            this.initEvent();
            // this.$refs.subscribedModal.show();
        },
        methods: {
            // 点击导出成员明细
            exportMembers() {
                this.loading = true;
                let params = {
                    // offset : 0,
                    // pageSize: 0,
                    orderBy: 1,
                    startDate: this.param.startDate,
                    endDate: this.param.endDate,
                    userIds: this.param.userIds
                };
                _request({
                    url: statisticsUrl.export_members,
                    method: 'POST',
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    responseType: 'blob',
                    data: params,
                }).then(res =>{
                    // console.log(res);
                    downloadFile(res);
                    this.loading = false;
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            },

            handleDateSelect(start, end) {
                let scale = this.statisticsParam.onDateRangeChange(start, end);
                Object.assign(this.param, {
                    startDate: start,
                    endDate: end,
                    scale: scale
                });
                if(this.$refs.statisticsParamTime.$refs.teamSelect) {
                    this.$nextTick(() => {
                        this.$refs.statisticsParamTime.$refs.teamSelect.getHistoryTeamData(true);
                    })
                } else {
                    this.refresh();
                }
                this.clearSelect();
            },
            initEvent() {
                let userIds = this.param.userIds;
                let self = this;

                this.statisticsParam.$on('param-change', (param) =>{
                    console.log(param)
                    this.refreshChartAndTable(param);
                    this.clearSelect();
                    return false;
                })

                this.sortTable.$on('item-check', (selects, headerItems) => {
                    this.compare(selects);
                    // console.log(headerItems);
                    this.statisticsSelectedItems.headerItems = headerItems;
                    this.statisticsSelectedItems.visible = selects.length > 0;
                    return false;
                });

                this.statisticsCharts.$on('tab-change', (newIndex, oldIndex) => {
                    // this.sortTable.switchUrl(newIndex);
                    if (newIndex) {
                        this.sortTable.enableCheck = false;
                        this.clearSelect();
                    } else {
                        this.statisticsSelectedItems.visible = this.statisticsCharts.hasSelected;
                    }
                    return false;
                });
            },
            handleNavTap(index) {
                this.activeNav = index;
            },

            employeeLoaded(employees) {
            },

            // 员工选取
            employeeSelect(userIds, text) {
                if(userIds && userIds.length > 0) {
                    this.param.userIds = userIds;
                    this.refresh();
                } else {
                    this.param.userIds = [this.userInfo.id];
                    this.refresh();
                }
                this.$refs.statisticsParamTime.teamMemberPopoverVisible = false;
                this.$refs.statisticsParamTime.selectedMembersText = text || '全部成员';
                this.$refs.statisticsTotal.selectedMembersText = text || '全部成员';
            },

            // jumpDetail(key, userId, field) {
            //     debugger
            //     let json = this.param;
            //     json.key = key;
            //     json.field = key;
            //     json.startDate = this.param.startDate;
            //     json.endDate = this.param.endDate;
            //     if (field === 'singlePerson') {
            //         json.userIds = [];
            //         json.userIds.push(userId);
            //     }
            //     if (key == 'conversionStatisticsRecommendationDetails' || key == 'performanceStatisticsRecommendationDetails') {
            //         if (this.statisticsCharts.showFirst) {
            //             json.key = 'conversionStatisticsRecommendationDetails';
            //         } else {
            //             json.key = 'performanceStatisticsRecommendationDetails';
            //         }
            //         json.userIds = [];
            //         json.userIds.push(userId);
            //         json.orderBy = field;
            //         extend(json, this.statisticsParam.getParam());
            //     }
            //     localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
            //     // window.open('/MyCompany/PerformancesDetail', '_blank');
            //     window.open('/Headhunting/MyCompany.html#/performancesDetail', '_blank');
            //     return false;
            // },

            // jumpDetail(key, userId, field) {
            jumpDetail(data) {
                let key = data.key;
                let json = {};
                // let json = this.param;
                // json.key = key;
                // json.field = key;
                let { userIds, startDate, endDate } = this.param;
                let isFromPerformance = true;
                json = { userIds, startDate, endDate, isFromPerformance };
                if(data.userIds) {
                    json.userIds = data.userIds;
                }
                json = Object.assign({}, json, data);
                // if (field === 'singlePerson') {
                //     json.userIds = [];
                //     json.userIds.push(userId);
                // }
                // if (key == 'conversionStatisticsRecommendationDetails' || key == 'performanceStatisticsRecommendationDetails') {
                //     if (this.statisticsCharts.showFirst) {
                //         json.key = 'conversionStatisticsRecommendationDetails';
                //     } else {
                //         json.key = 'performanceStatisticsRecommendationDetails';
                //     }
                //     json.userIds = [];
                //     json.userIds.push(userId);
                //     json.orderBy = field;
                //     extend(json, this.statisticsParam.getParam());
                // }
                sessionStorage.removeItem('perpormancesDetailData');
                localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
                // window.open('/MyCompany/PerformancesDetail', '_blank');
                window.open('/Headhunting/MyCompany.html#/performancesDetail', '_blank');
                // window.open('/#/performancesDetail', '_blank');
                return false;
            },

            clearSelect(){
                this.statisticsSelectedItems.visible = false;
                this.statisticsSelectedItems.clear();
                this.sortTable.clearSelect();
            },

            compare(selects) {
                this.statisticsSelectedItems.setData(selects);
                this.statisticsCharts.compare(selects);
            },

            refreshChartAndTable(param) {
                this.statisticsCharts.refresh(param);
                this.sortTable.refresh(param);
            },

            refresh(param) {
                this.param = Object.assign({}, this.param, param);
                param = this.param;
                this.statisticsTotal.refresh(param);
                this.statisticsCharts.refresh(param);
                this.sortTable.refresh(param);
            },

            getStorageParam(){
                let self = this, statisticsPageParamsStr = localStorage.getItem('perpormancesDetailData');
                if (statisticsPageParamsStr) {
                    let statisticsParams = JSON.parse(statisticsPageParamsStr);
                    localStorage.removeItem('perpormancesDetailData');

                    // this.param.startDate = statisticsParams.startDate;
                    // this.param.endDate= statisticsParams.endDate;
                    this.param.filterMarketJob = statisticsParams.filterMarketJob;
                    this.param.filterInternalJob = statisticsParams.filterInternalJob;
                    this.param.dimension = statisticsParams.dimension;
                    this.param.userIds = statisticsParams.userIds;
                }
            },
            // handleSubscribeTitle(str){
            //     //更改modal的title
            //     this.subscribeTitle = str;
            // },
            // closeSubscribeModal(){
            //     this.$refs.subscribedModal.hide();
            // },
            // showAddSubscribe(){
            //     //显示添加订阅的modal
            // }
        }
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';

.tab {
    @include clearfix();
    color: #b9b9b9;
    text-align: center;
    border-bottom: 1px solid #ececec;
    width: 100%;
    position: relative;
}

 .statistics-page{
    @include clearfix();
    background-color: #f6f6f6;
    box-sizing: border-box;
    padding: 18px 20px;

    .tab-wrapper{
        background-color: #f6f6f6;
        
        .tab-panel{
            width: 1200px;
            margin: 0 auto;
            padding-top: 20px;
            height: 120px;
            .tab{
                width: 580px;
                height: 100px;
                display: inline-block;
                background: #ffffff;
                line-height: 100px;
                font-size: 24px;
                color: #666666;
                font-weight: bold;
                &:first-child{
                    margin-right: 40px;
                }
                &.active{
                    color:#38bc9c;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    .sort-title{
        position: relative;
        font-size: 28px;
        color: #666;
        line-height: 78px;
        text-align: center;
        .export-members {
            cursor: pointer;
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #38BC9C;
            color: #fff;
            font-size: 14px;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -15px;
        }

        span {
            position: absolute;
            left: 0;
            bottom: 9px;
            font-size: 12px;
            color: #3f3f3f;
            line-height: 16px;

            i {
                color: $primary;
            }
        }
    }
    
    .detail-link{
        cursor: pointer;
    }
    .sort-table-header{
        .selection-wrapper{
            .doll{
                &.fa-edit{
                    //background-image: url('~@/icon/edit-small.png');
                }
                &.fa-folder{
                    //background-image: url('~@/icon/folder-small.png');
                }
                &.fa-time{
                    //background-image: url('~@/icon/time-small.png');
                    width: 17px;
                    height: 17px;
                }
                &.fa-profile{
                    //background-image: url('~@/icon/profile-small.png');
                }
            }
        }
    }
    .trigger-checkbox {
        margin-right: 20px;
    }

    .trigger .trigger-body {
        width: 16px;
        height: 16px;
    }

    .trigger .trigger-wrapper {
        width: 16px;
        height: 16px;
    }

    .trigger-checkbox .trigger-indicator {
        left: 2px;
        top: 2px;
        border-color: #fff;
    }

    .trigger .trigger-label {
        margin-left: 0;
    }
    .trigger .trigger-tag:checked+.trigger-body {
        background-color: $primary;
    }


    .sort-table-item{
        .trigger-checkbox {
            margin-right: 4px;
            vertical-align: baseline;
        }

        .trigger .trigger-body{
            border-radius: 8px;
        }
    }

    .pager-jump {
        padding: 0 20px;
    }
 }

 
.statistics-page .dropmenu-select .trigger-checkbox {
    margin-right: 8px;
}

.statistics-page .dropmenu-select .nickname {
    color: #b9b9b9;
}

</style>
