import statisticsDate from './work-chart-statistics.json';

const BASE_SIZE = '14px',
    GRAY_COLOR = '#666666';

export default {
    chart: {
        type: 'spline',
        width: 1160,
        style: {
            fontFamily: 'Arial, 微软雅黑'
        }
    },

    title: {
        text: null
    },

    xAxis: {
        lineColor: '#d3d3d3',
        tickWidth: 0,
        labels: {
            style: {
                color: GRAY_COLOR,
                fontSize: BASE_SIZE
            }
        }
    },

    yAxis: {
        allowDecimals: false,
        gridLineColor: '#eeeeee',
        title: {
            text: null
        },
        labels: {
            style: {
                color: GRAY_COLOR,
                fontSize: BASE_SIZE
            }
        }
    },

    tooltip: {
        shared: true,
        crosshairs: {
            color: 'rgba(0, 0, 0, .03)'
        },
        valueSuffix: '',
        useHTML: true,
        backgroundColor: 'rgba(255, 255, 255, .95)',
        headerFormat: '<h4 class="chart-tooltip-header">{point.key}</h4><table class="chart-tooltip-body">',
        pointFormat: '<tr><td class="chart-tooltip-name"><span style="color:{series.color}">\u25CF</span> {series.name}: </td>' +
        '<td class="chart-tooltip-value">{point.y}</td></tr>',
        footerFormat: '</table>'
    },

    legend: {
        itemStyle: {
            fontWeight: 'normal',
            fontSize: BASE_SIZE,
            color: GRAY_COLOR
        },
        itemMarginTop: 15,
        symbolWidth: 30,
        symbolPadding: 8,
        itemDistance: 40
    },

    plotOptions: {
        series: {
            lineWidth: 3, 
            events: {
                legendItemClick: null
            }
        }
    },

    series: function() {
            let series = [],
                statisticsKeys = Object.keys(statisticsDate);
            statisticsKeys.forEach((key, i) => {
                series[i] = {
                    name: statisticsDate[key].name,
                    marker: {
                        symbol: 'circle'
                    },
                    data: [],
                    visible: i < 4
                };
            })
        return series;
    }(),

    colors:['#9A63CB', '#44BEEC', '#E55DAA', '#FB7819', '#4A90E2', '#38BC9C', '#B8E986', '#001790', '#666666']
}