var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "statistics-selected-items",
    },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        { staticClass: "item" },
        [
          _c("sort-table-item", {
            attrs: {
              data: item,
              "header-items": _vm.headerItems,
              visible: _vm.visible,
              "enable-check": false,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }