<template>
    <div class="recommended-progress-table">
        <el-button 
            type="primary" 
            class="export-btn"
            @click="handleExport"
            :loading="isLoadingExport">
            导出明细
        </el-button>
        <el-table :data="tableData" border style="width: 100%" align="center" v-loading="loading">
            <el-table-column 
                prop="name"
                align="center" 
                width="60">
            </el-table-column>

            <el-table-column 
                prop="total" 
                align="center" 
                label="推荐次数（pm接受数）"
                width="175">
            </el-table-column>
            <el-table-column 
                prop="hrfeedBack" 
                align="center" 
                label="待反馈">
            </el-table-column>
            <el-table-column 
                prop="obsoletedFrontInterview" 
                align="center" 
                label="淘汰（未进入面试）"
                width="150">
            </el-table-column>
            <el-table-column 
                prop="interview" 
                align="center" 
                label="面试中">
            </el-table-column>
            <el-table-column 
                prop="obsoletedBackInterview" 
                align="center" 
                label="淘汰（面挂）"
                width="110">
            </el-table-column>
            <el-table-column 
                prop="offering" 
                align="center" 
                label="offering">
            </el-table-column>
            <el-table-column 
                prop="obsoletedOffering" 
                align="center" 
                label="淘汰（未进入offer）"
                width="155">
            </el-table-column>
            <el-table-column 
                prop="offer" 
                align="center" 
                label="已offer">
            </el-table-column>
            <el-table-column 
                prop="obsoletedOffer" 
                align="center" 
                label="淘汰（飞单）"
                width="110">
            </el-table-column>
            <el-table-column 
                prop="onboard" 
                align="center" 
                label="入职">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import performanceSetService from '#/js/service/performanceSetService.js';
import { downloadFile } from "#/js/util/fileUtil.js";

export default {
    props: {

    },

    components: {

    },

    data() {
        return {
            loading: false,
            isLoadingExport: false,
            param: {},
            tableData: []
        };
    },

    mounted() {

    },

    methods: {
        handleExport() {
            this.isLoadingExport = true;
            performanceSetService
                .exportRecommendProcessDetail(this.param)
                .then(res => {
                    // console.log(res);
                    shortTips(`导出成功！`);
                    downloadFile(res);
                    this.isLoadingExport = false;
                })
                .catch(err => {
                    console.log(err);
                    this.isLoadingExport = false;
                });
        },
        refresh(param){
            // console.log(param);
            this.param = {
                startDate: param.startDate,
                endDate: param.endDate,
                memberIds: param.userIds,
            }
            this.getRecommendProcessDetail(this.param);
        },
        getRecommendProcessDetail(params) {
            this.loading = true;
            performanceSetService
                .getRecommendProcessDetail(params)
                .then(res => {
                    // console.log(res);
                    this.tableData = [];
                    let tempCount = {
                        name: '数量',
                        total: res.total || 0,
                        hrfeedBack: res.hrfeedBack?.count || 0,
                        obsoletedFrontInterview: res.obsoletedFrontInterview?.count || 0,
                        interview: res.interview?.count || 0,
                        obsoletedBackInterview: res.obsoletedBackInterview?.count || 0,
                        offering: res.offering?.count || 0,
                        obsoletedOffering: res.obsoletedOffering?.count || 0,
                        offer: res.offer?.count || 0,
                        obsoletedOffer: res.obsoletedOffer?.count || 0,
                        onboard: res.onboard?.count || 0,
                    }
                    let tempPercent = {
                        name: '占比',
                        total: '--',
                        hrfeedBack: res.hrfeedBack?.percent || '0%',
                        obsoletedFrontInterview: res.obsoletedFrontInterview?.percent || '0%',
                        interview: res.interview?.percent || '0%',
                        obsoletedBackInterview: res.obsoletedBackInterview?.percent || '0%',
                        offering: res.offering?.percent || '0%',
                        obsoletedOffering: res.obsoletedOffering?.percent || '0%',
                        offer: res.offer?.percent || '0%',
                        obsoletedOffer: res.obsoletedOffer?.percent || '0%',
                        onboard: res.onboard?.percent || '0%',
                    }
                    this.tableData.push(tempCount);
                    this.tableData.push(tempPercent);
                    // console.log('this.tableData----------------------------------');
                    // console.log(this.tableData);
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        }
    },
};
</script>

<style lang="scss">
.recommended-progress-table {
    padding: 0 20px 20px;
    background-color: #fff;
    .export-btn {
        float: right;
        margin-bottom: 20px;
    }
    thead.has-gutter {
        tr {
            th {
                background-color: #F8F8F8;
                color: #555;
            }
        }
    }
}
</style>