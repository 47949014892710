var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "performance-data",
    },
    [
      _vm._m(0),
      _c("ul", { staticClass: "performance-list" }, [
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_candidate" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "candidateDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("人才创建"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.candidates, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "candidateDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("完整简历"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.completeCandidates, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "candidateDetails",
                          achievementDetailType: "3",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("Profile数"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.profileCandidates, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_xunfang" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "candidateInquiryLogDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("有效寻访"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.validInquiryLogs, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "candidateInquiryLogDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("添加寻访"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.inquiryLogs, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_phone" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "callRecordDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("拨打电话"),
                    _c("i", [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.data.calls, "", 0))),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "callRecordDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("有效通话"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.validCalls, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_zhiwei" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "jobDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("创建职位"),
                    _c("i", [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.data.jobs, "", 0))),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "jobDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("职位相关信息更新"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.keyUpdateJobs, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_order" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "orderDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("接单次数"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.orders, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "orderDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("内部职位"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.internalOrders, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "orderDetails",
                          achievementDetailType: "3",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("外部职位"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.marketOrders, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_recommend" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "1",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("推荐人才"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.recommendCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "2",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("接受推荐"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.acceptedRecommendCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "3",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("推荐淘汰"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.rejectedRecommendCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_cishu" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "4",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("人才推荐"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.candidateRecommends,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "5",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("接受推荐"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.acceptedCandidateRecommends,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "6",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("推荐淘汰"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.rejectedCandidateRecommends,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_success" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "8",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("面试通过"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.interviewPassedCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "7",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("面试人才"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.interviewCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "recommendationDetails",
                          achievementDetailType: "9",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("面试失败"),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.data.interviewFailedCandidates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "performance-item",
            class: _vm.userInfo.isCFUser ? "" : "performance-item-single",
          },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-date_ic_candidate" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "trackingListDetails",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("Tracking List"),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.tackingLists, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm.isAdminOrFirstLevelTeamLeader
                ? _c("p", { staticClass: "sub-count" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "leaderFeedbackData",
                            })
                          },
                        },
                      },
                      [_vm._v("Tracking List周报反馈数据")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "performance-item" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-offer" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "main-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpOfferStatement($event, "offer")
                      },
                    },
                  },
                  [
                    _vm._v("Offer "),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.data.offers, "", 0))
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm.isOpenPerformanceCalculation
                ? _c("p", { staticClass: "sub-count" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.jumpOfferStatement($event, "relateOffer")
                          },
                        },
                      },
                      [
                        _vm._v("相关Offer "),
                        _c("i", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.data.relateOffers, "", 0)
                            )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpOfferStatement($event, "offerList")
                      },
                    },
                  },
                  [
                    _vm._v("Offering List \n                        "),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.data.offeringListCount, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm.userInfo.isAdministrator || _vm.userInfo.isAdminOrTeamLeader
          ? _c(
              "li",
              { staticClass: "performance-item" },
              [
                _c("font-icon", {
                  staticClass: "item-icon",
                  attrs: { href: "#icon-jiaofumubiao" },
                }),
                _c("div", { staticClass: "item-content" }, [
                  _c("p", { staticClass: "sub-count sub-count-padding" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "deliveryTargetReport",
                              deliveryTargetType: "0",
                            })
                          },
                        },
                      },
                      [_vm._v("交付目标-客户")]
                    ),
                  ]),
                  _c("p", { staticClass: "sub-count" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "deliveryTargetReport",
                              deliveryTargetType: "1",
                            })
                          },
                        },
                      },
                      [_vm._v("交付目标-成员")]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        (_vm.userInfo.isAdministrator ||
          _vm.userInfo.isAdminOrTeamLeader ||
          _vm.userInfo.roleCodeList.includes("InternalControl")) &&
        _vm.userInfo.isCFUser
          ? _c(
              "li",
              { staticClass: "performance-item performance-item-single" },
              [
                _c("font-icon", {
                  staticClass: "item-icon",
                  attrs: { href: "#icon-jifen" },
                }),
                _c("div", { staticClass: "item-content" }, [
                  !_vm.userInfo.roleCodeList.includes("InternalControl")
                    ? _c("p", { staticClass: "sub-count sub-count-padding" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.goToDetail($event, {
                                  key: "teamPointsReport",
                                  teamPointsType: "0",
                                })
                              },
                            },
                          },
                          [_vm._v("团队积分")]
                        ),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "sub-count sub-count-padding" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToDetail($event, {
                              key: "teamWeekReport",
                            })
                          },
                        },
                      },
                      [_vm._v("推荐面试情况")]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "performance-item performance-item-single" },
          [
            _c("font-icon", {
              staticClass: "item-icon",
              attrs: { href: "#icon-a-bianzu3" },
            }),
            _c("div", { staticClass: "item-content" }, [
              _c("p", { staticClass: "sub-count sub-count" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "evaluateCountReport",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("评价数 "),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.recommendationEvaluates, "", 0)
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count sub-count-padding" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "waitEvaluateNotExpireCountReport",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("待评价未过期数 "),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.canRecommendationNotExpireEvaluates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("p", { staticClass: "sub-count sub-count-padding" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail($event, {
                          key: "waitEvaluateExpireCountReport",
                        })
                      },
                    },
                  },
                  [
                    _vm._v("待评价已过期数 "),
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.canRecommendationExpiredEvaluates,
                            "",
                            0
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("subscribe-modal", {
        ref: "subscribeModal",
        attrs: { "subscribed-count": _vm.subscribedCount },
        on: { "refresh-list": _vm.getSubscribeData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pannel-title" }, [
      _c("div", { staticClass: "title-left" }, [
        _c("span", { staticClass: "title-text" }, [_vm._v("业绩数据")]),
      ]),
      _c("div", { staticClass: "title-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }