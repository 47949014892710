<template>
    <div class="job-order-status" v-loading="chartLoading">
        <!-- <spinner class="modal-box-spinner"></spinner> -->
        <div class="row info">
            <div class="col-4 text-right">
                <img src="@src/assets/images/icon/icon_order_modal.png" alt="order"/>
            </div>
            <div class="col-11 title">
                <p>你正在查看 <span data-bind="html:paramText"></span> 的订单数量变化</p>
            </div>
            <a href="javascript:;;" class="subscribeBtn col-2" @click="handleSuscribe">订阅数据</a>
        </div>
        
        <el-row class="row time">
            <el-col :span="4">
                <ul>
                    <li 
                        @click="changeType(3)"
                        :class="{'active': orderCreateStatisticsType == 3}"
                        >按月</li>
                    <li @click="changeType(2)"
                        :class="{'active': orderCreateStatisticsType == 2}"
                        >按周</li>
                    <li @click="changeType(1)"
                        :class="{'active': orderCreateStatisticsType == 1}"
                        >按天</li>
                </ul>
            </el-col>
            <el-col :span="18">
                <div class="charts" id="chartsDOM" style="width:900px;height:320px;"></div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import echarts from 'echarts';
import moment from 'moment';
const YESTODAY = moment().subtract(1, 'days').format("YYYY-MM-DD");
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
export default {
    components: {
    },
    data() {
        return {
            infoParams: {
                creatorIds: '',
                customerIds: '',
                startDate: '',
                endDate: '',
                type: '',
            },
            orderCreateStatisticsType: 2,
            chartLoading: false,
            option : {
                title: {text: ''},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#333'
                        },
                        label:{
                            precision:0
                        }
                    }
                },
                grid: {
                    left: '150px',
                    top: '5px',
                    width: '635px',
                    containLabel: true
                },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100
                    },
                    {
                        type: 'inside',
                        start: 0,
                        end: 100
                    }
                ],
                legend: {
                    data: ['职位', 'HC'],
                    right: "40px",
                    top: "0px",
                    orient: 'vertical'
                },
                color: ['#38bc9c', '#80e3cb'],
                xAxis: {
                    type: 'category',
                    data: ["9月12日", "9月15日", "9月18日", "9月21日", "8月26日", "8月27日", "8月28日", "8月29日", "8月30日", "8月31日", "9月1日", "9月2日", "9月3日", "9月4日", "9月5日", "9月6日", "9月7日", "9月8日", "9月9日", "9月10日", "9月11日", "9月13日", "9月14日", "9月16日", "9月17日", "9月19日", "9月20日", "9月22日", "9月23日", "9月24日", "9月25日"],
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999'
                        }
                    },
                    minInterval: 1,
                    splitNumber: 3
                },
                series: [{
                    name: '职位',
                    type: 'bar',
                    barWidth: '20%',
                    data: [6, 7, 7, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }, {
                    name: 'HC',
                    type: 'bar',
                    barWidth: '20%',
                    data: [24, 28, 28, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }]
            },
        }
    },
    computed: {
    },
    mixins: [
    ],
    mounted() {
    },
    methods: {
        setData(params) {
            this.infoParams = params;
            this.getChartData();
        },
        getChartData() {
            let params = {
                creatorIds: this.infoParams.creatorIds || null,
                customerIds: this.infoParams.customerIds || null,
                startDate: this.infoParams.startDate || lastMonth,
                endDate: this.infoParams.endDate || YESTODAY,
                type: this.orderCreateStatisticsType
            };
            this.chartLoading = true;

            _request({
                method: "POST",
                url: '/MyCompany/MyCompanyOrdeCreateCountStatistics',
                data: params || null
            }).then(res => {
                let myChart = echarts.init(document.getElementById('chartsDOM'));

                this.option.xAxis.data = res.x;
                this.option.series[0].data = res.orderCounts;
                this.option.series[1].data = res.recruitingCounts;
                
                myChart.setOption(this.option);
            }).finally(() => {
                this.chartLoading = false;
            });
        },
        changeType(data){
            this.orderCreateStatisticsType = data;
            this.getChartData();
        },
        handleSuscribe() {
            this.$emit('subscirbe-click');
        }
    }
}
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';

.job-order-status {
    margin: 0 0 20px;
    div.info {
        margin: 0 0 25px;
        .title {
            color: #9b9b9b;
            .team-text {
                display: inline-block;
                max-width: 116px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: top;
            }
            p {
                padding: 20px 0 0 10px;
                span {
                    color: #666;
                }
            }
        }
        .subscribeBtn{
            width: 100px;
            height: 35px;
            line-height: 35px;
            background: #38bc9c;
            color: #fff;
            text-align: center;
            border-radius: 2px;
            margin-top: 7px;
        }
    }
    .time {
        position: relative;
        ul {
            width: 60px;
            font-size: 16px;
            margin: 0 0 0 60px;
            position: absolute;
            z-index: 100;
            li {
                position: relative;
                margin-bottom: 20px;
                color: #333;
                cursor: pointer;
                &:after {
                    display: block;
                    position: absolute;
                    top: 9px;
                    left: -18px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    content: "";
                    background-color: #ccc;
                }
                &.active {
                    color: #38bc9c;
                    &:after {
                        background-color: #38bc9c;
                    }
                }
            }
        }
        .left-panel{
            z-index: 9;
        }
        .charts {
            // margin: 0 0 0 -150px;
            margin: 0;
        }
    }
}

</style>
