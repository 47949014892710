var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recommended-progress-table" },
    [
      _c(
        "el-button",
        {
          staticClass: "export-btn",
          attrs: { type: "primary", loading: _vm.isLoadingExport },
          on: { click: _vm.handleExport },
        },
        [_vm._v("\n        导出明细\n    ")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", align: "center" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", width: "60" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "total",
              align: "center",
              label: "推荐次数（pm接受数）",
              width: "175",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "hrfeedBack", align: "center", label: "待反馈" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "obsoletedFrontInterview",
              align: "center",
              label: "淘汰（未进入面试）",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "interview", align: "center", label: "面试中" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "obsoletedBackInterview",
              align: "center",
              label: "淘汰（面挂）",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "offering", align: "center", label: "offering" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "obsoletedOffering",
              align: "center",
              label: "淘汰（未进入offer）",
              width: "155",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "offer", align: "center", label: "已offer" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "obsoletedOffer",
              align: "center",
              label: "淘汰（飞单）",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "onboard", align: "center", label: "入职" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }