<template>
    <div class="statistics-charts">
        <div class="tab">
            <el-tabs type="border-card" v-model="activeShow" @tab-click="handleTabChange">
                <div class="param">
                    <statistics-param 
                        ref="statisticsParam" 
                        :show-unit-tab="showUnitTab" 
                        :is-multiple-employee="isMultipleEmployee"
                        v-show="activeShow != '0'"
                    ></statistics-param>
                </div>
                <el-tab-pane>
                    <span slot="label">
                        推荐进展情况
                        <el-popover
                            placement="right"
                            title=""
                            width="300"
                            trigger="hover"
                            content="在选定时间内的推荐在后续业务中的转化情况，其中的接受、面试、offer是由这段时间内推荐产生的业务跟进，不一定在选定时间内发生"
                        >
                            <i slot="reference" class="el-icon-info"></i>
                        </el-popover>
                    </span>
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label">
                        推荐转化分析
                        <el-popover
                            placement="right"
                            title=""
                            width="300"
                            trigger="hover"
                            content="在选定时间内的推荐在后续业务中的转化情况，其中的接受、面试、offer是由这段时间内推荐产生的业务跟进，不一定在选定时间内发生"
                        >
                            <i slot="reference" class="el-icon-info"></i>
                        </el-popover>
                    </span>
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label">
                        工作内容统计
                        <el-popover
                            placement="right"
                            title=""
                            width="300"
                            trigger="hover"
                            content="在选定时间内产生的推荐、接受、面试、offer等工作内容的数据，其中接受、面试、offer等数据不一定由推荐数据产生，但是在选定时间发生"
                        >
                            <i slot="reference" class="el-icon-info"></i>
                        </el-popover>
                    </span>
                </el-tab-pane>
            </el-tabs>
            <recommended-progress-table v-show="activeShow == '0'" ref="recommendedProgressTable"></recommended-progress-table>
            <statistics-conversion-funnel-chart v-show="activeShow == '1'" ref="statisticsConversionFunnelChart" :is-multiple-employee="isMultipleEmployee"></statistics-conversion-funnel-chart>
            <statistics-work-content-chart v-show="activeShow == '2'" ref="statisticsWorkContentChart"></statistics-work-content-chart>
        </div>
    </div>
</template>

<script>
    import StatisticsParam from './statisticsParam.vue';
    import RecommendedProgressTable from './recommended-progress-table.vue';
    import StatisticsConversionFunnelChart from './statisticsConversionFunnelChart.vue';
    import StatisticsWorkContentChart from './statisticsWorkContentChart.vue';

    export default {
        name: "statisticsCharts",
        components: {
            StatisticsParam,
            RecommendedProgressTable,
            StatisticsConversionFunnelChart,
            StatisticsWorkContentChart,
        },
        data() {
            return {
                activeShow: '0',
                param: {},
                selects: [],
                currentChart: '',
            }
        },
        props: {
            isMultipleEmployee: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            hasSelected() {
                return this.selects.length > 0;
            },
            showUnitTab() {
                return this.activeShow == '2';
            },
        },
        mounted() {
            this.recommendedProgressTable = this.$refs.recommendedProgressTable;
            this.statisticsConversionFunnelChart = this.$refs.statisticsConversionFunnelChart;
            this.statisticsWorkContentChart = this.$refs.statisticsWorkContentChart;
            this.statisticsParam = this.$refs.statisticsParam;

            // this.currentChart = this.statisticsConversionFunnelChart;
            this.currentChart = this.recommendedProgressTable;

            if(this.activeShow == '2'){
                this.statisticsParam.reset();
            }
        },
        methods: {
            handleTabChange(obj) {
                // console.log(obj);
                if(obj.index == '0') {
                    this.currentChart = this.recommendedProgressTable;
                } else if(obj.index == '1') {
                    this.currentChart = this.statisticsConversionFunnelChart;
                    this.$emit('tab-change', 0, 1); 
                    this.$nextTick(() => {
                        this.currentChart.renderChart();
                    });
                } else if(obj.index == '2') {
                    this.currentChart = this.statisticsWorkContentChart;
                    this.$emit('tab-change', 1, 0);
                }
                let param = this.statisticsParam.getParam();
                this.refresh(param);
            },
            refresh(param){
                // console.log('param****************************');
                // console.log(param);
                this.param = Object.assign({}, this.param, param);
                this.fetch();
            },
            fetch(){
                this.currentChart.refresh(this.param);
            }
        }
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';

$tabBackgroundColor: #f8f8f8;

 .statistics-charts{
    @include clearfix();
    
    .tab {
        border-bottom: none;
    }

    .tab .el-tabs {
        border: 0 none;
        box-shadow: unset;

        .el-tabs__header {
            border: none;
            background-color: #f8f8f8;
            
            .el-tabs__item {
                font-size: 18px;
                height:46px;
                line-height: 46px;
                border: 0 none;
            }
        }
    }

    >.tab {
        position: relative;
        width: 1200px;
        left: -20px;
        background-color: $tabBackgroundColor;

        .active .tab-wrapper {
            border: none;
        }

        .active::before {
            background-color: #fff!important;
        }

        .selection-item {
            width: 164px;
            line-height: 25px;
            bottom: 0;
            font-size: 18px;
            border: none;

            &::before {
                background-color: #f6f6f6;
            }
        }

        .icon-font {
            font-size: 14px;
            color: $primary;
        }
    }

    .tab .active .tab-wrapper {
        color: $primary;
        border-radius: 3px;
    }

 }

</style>
