<template>
    <div class="statistics-conversion-funnel-chart" v-loading="funnelChartLoading">
        <chart ref="chart" :default-param="param" :defer-show="deferShow"></chart>
        <div class="desc">
            <span class="legend-title">
                <span>
                    <i class="icon reverse-triangle"></i>
                    <span v-text="legendTitle"></span>
                </span>
                <span class="number">{{ totalConversionRate | tpl('%p%') }}</span>
            </span>
        </div>
    </div>
</template>

<script>
    import {statistics as statisticsUrls} from '#/js/config/api.json';
    import chartParam from './chart-setting';
    import { extendObject } from '#/js/util/tools.js';

    import Chart from '#/component/common/chart.vue';

    const DEFAULT_QUERY_PARAM = {
        startDate: '',
        endDate: '',
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1,
        orderBy: 1
    }

    const CHART_INTERVIEW_TIP_POPOVER = "电话面试、到场面试统称为面试。发生多轮面试的算作一次（后续会升级细化哦~）";
    const LEGEND_TITLE = "团队命中率";
    const PERSONAL_LEGEND_TITLE = '个人转化率';
    const LABELS = ['推荐', '接受', '安排面试', '<i class="icon-font icon-information-circle" data-toggle="popover" data-placement="top" data-trigger="hover" data-content="' + CHART_INTERVIEW_TIP_POPOVER + '"></i>面试', 'Offer', '入职'];
    const MAX_YAXIS = 10;

    export default {
        name: "statisticsConversionFunnelChart",
        components: {
            Chart
        },
        data() {
            return {
                legendTitle: LEGEND_TITLE,
                url: statisticsUrls.url_conversion_statistics_summary,
                param: extendObject({}, chartParam),
                queryParam: Object.assign({}, DEFAULT_QUERY_PARAM),
                interviewConversionRate: 0,
                acceptRate: 0,
                arrangeInterviewRate: 0,
                offerConversionRate: 0,
                onboardConversionRate: 0,
                totalConversionRate: 0,
                recommendationCount: 0,
                acceptCount: 0,
                arrangeInterviewCount: 0,
                interviewCount: 0,
                offerCount: 0,
                onboardCount: 0,
                comparedYaxisValue: MAX_YAXIS,
                funnelChartLoading: false,
            }
        },
        props: {
            deferShow: Boolean,
            isMultipleEmployee: Boolean,
        },
        watch: {
            isMultipleEmployee: function(val, oldVal) {
                this.legendTitle = val ? LEGEND_TITLE : PERSONAL_LEGEND_TITLE;
            }
        },
        created() {
            let _formatterXAxis = this.formatterXAxis;
            this.param.xAxis.labels.formatter = function formatterXAxis () {
                var idx = this.value;
                return _formatterXAxis(idx);
            };
        },
        mounted() {

            this.chart = this.$refs.chart;
        },
        methods: {
            renderChart(param){
                let _formatterXAxis = this.formatterXAxis;
                this.param.xAxis.labels.formatter = function formatterXAxis () {
                    var idx = this.value;
                    return _formatterXAxis(idx);
                };
                this.updateParam(param);
                this.chart.renderChart();
            },
            ceilTenfoldNumber(val){
                return Math.ceil(parseInt(val, 10) / 10) * 10;
            },
            formatLabel (title, extra) {
                title = '<span class="chart-title">' + title + '</span>';
                extra = extra ?  '<br /><span class="chart-extra">' + extra + '</span>' : '';
                return '<span class="chart-label">' + title + extra + '</span>';
            },
            formatterXAxis(idx){
                let title = LABELS[idx];
                let extra = '';
                switch(idx){
                    case 0:
                        extra = this.acceptRate + '%';
                        break;
                    case 1:
                        extra = this.arrangeInterviewRate + '%';
                        break;
                    case 2:
                        extra = this.interviewConversionRate + '%';
                        break;
                    case 3:
                        extra = this.offerConversionRate + '%';
                        break;
                    case 4:
                        extra = this.onboardConversionRate + '%';
                        break;
                }
                return this.formatLabel(title, extra);
            },
            update(data){
                let param = Object.assign({}, this.queryParam);
                param.memberIds = param.userIds;
                delete param.userIds;
                this.funnelChartLoading = true;

                _request({
                    url: this.url,
                    method: 'POST',
                    data: param
                }).then(res => {
                    Object.keys(res).forEach(key => {
                        this[key] = res[key];
                    })
                    this.updateChart();
                }).finally(() => {
                    this.funnelChartLoading = false;
                })
            },
            updateChart(){
                let recommendationCount = this.recommendationCount, interviewCount = this.interviewCount, acceptCount = this.acceptCount, arrangeInterviewCount = this.arrangeInterviewCount, offerCount = this.offerCount, onboardCount = this.onboardCount;
                let maxValue = Math.max(recommendationCount, interviewCount, acceptCount, arrangeInterviewCount, offerCount, onboardCount, this.comparedYaxisValue);
                maxValue = this.ceilTenfoldNumber(maxValue);
                this.chart.updateYAxis({
                    max: maxValue
                });
                this.chart.updateData([this.recommendationCount, this.acceptCount, this.arrangeInterviewCount, this.interviewCount, this.offerCount, this.onboardCount]);
                this.chart.refresh();
            },
            refresh(param){
                this.queryParam = extendObject(this.queryParam, param);
                this.update();
            },
            updateChartData(data){
                Object.keys(data).forEach(key => {
                    this[key] = data[key];
                })
                this.updateChart();

            },
            updateParam(param){
                this.param = extendObject(this.param, param);
                this.chart.updateParam(this.param);
            },
            redraw(){
                this.chart.refresh();
            }
        }
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';
.statistics-conversion-funnel-chart{
    padding: 0 20px;
    background-color: #fff;

    @include clearfix();

    .chart-label {
        position: relative;
        display: block;
        text-align: center;
        width: 67px;

       .chart-title {
           display: inline-block;
           font-size: 14px;
           color: #333;
       }

        .chart-extra {
            position: absolute;;
            display: inline-block;
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            width: 100%;
            top: 70px;
            left: 0;
            height: 35px;
            background-color: #eee;
            color: $primary;
            overflow: hidden;

            &:after,
            &:before {
                position: absolute;
                content: ' ';
                border-width: 13px 33px;
                border-style: solid;
                bottom: -13px;
            }

            &:before {
                left: 0;
                border-color: transparent transparent #fff #fff;
            }

            &:after {
                right: 0;
                border-color: transparent #fff #fff transparent ;
            }
        }

    }

    .highcharts-legend-item > rect {
        display: none;
    }

    >.desc{
        @include clearfix();
        margin-top: 8px;
        text-align: left;
    }

    .legend-title{

        display: inline-block;
        padding: 20px 14px;
        border: 1px solid rgba(101,112,129,0.20);
        border-radius: 5px;
        color: #333;
        font-size: 16px;
        color: #333;

        .number {
            color: $primary;
            font-size: 28px;
            vertical-align: middle;
            margin-left: 18px;
        }
        .reverse-triangle{
            background-image: url('~@src/assets/images/icon/reverse-triangle.png');
            margin-right: 8px;
            vertical-align: text-top;
        }
    }

    .icon-font {
        font-size: 14px;
        color: $primary;
        margin-right: 5px;
    }
 }
</style>
