<template>
    <!-- 团队数据 —— 业绩数据 -->
    <div class="performance-data" v-loading="loading">
        <div class="pannel-title">
            <div class="title-left">
                <span class="title-text">业绩数据</span>
            </div>
            <div class="title-right">
            </div>
        </div>
        <ul class="performance-list">
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_candidate"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '1'})">人才创建<i>{{data.candidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '2'})">完整简历<i>{{data.completeCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateDetails', achievementDetailType: '3'})">Profile数<i>{{data.profileCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_xunfang"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '1'})">有效寻访<i>{{data.validInquiryLogs | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'candidateInquiryLogDetails', achievementDetailType: '2'})">添加寻访<i>{{data.inquiryLogs | currency('',0)}}</i></span></p>
                </div>
            </li>

            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_phone"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '1'})">拨打电话<i>{{data.calls | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'callRecordDetails', achievementDetailType: '2'})">有效通话<i>{{data.validCalls | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_zhiwei"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'jobDetails', achievementDetailType: '1'})">创建职位<i>{{data.jobs | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'jobDetails', achievementDetailType: '2'})">职位相关信息更新<i>{{data.keyUpdateJobs | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_order"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '1'})">接单次数<i>{{data.orders | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '2'})">内部职位<i>{{data.internalOrders | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'orderDetails', achievementDetailType: '3'})">外部职位<i>{{data.marketOrders | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_recommend"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '1'})">推荐人才<i>{{data.recommendCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '2'})">接受推荐<i>{{data.acceptedRecommendCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '3'})">推荐淘汰<i>{{data.rejectedRecommendCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_cishu"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '4'})">人才推荐<i>{{data.candidateRecommends | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '5'})">接受推荐<i>{{data.acceptedCandidateRecommends | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span  @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '6'})">推荐淘汰<i>{{data.rejectedCandidateRecommends | currency('',0)}}</i></span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-date_ic_success"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '8'})">面试通过<i>{{data.interviewPassedCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '7'})">面试人才<i>{{data.interviewCandidates | currency('',0)}}</i></span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'recommendationDetails', achievementDetailType: '9'})">面试失败<i>{{data.interviewFailedCandidates | currency('',0)}}</i></span></p>
                </div>
            </li>
            
            <li class="performance-item" :class="userInfo.isCFUser ? '' : 'performance-item-single'">
                <font-icon class="item-icon" href="#icon-date_ic_candidate"></font-icon>
                <div class="item-content">
                    <p class="main-count"><span @click="goToDetail($event, {key: 'trackingListDetails'})">Tracking List<i>{{data.tackingLists | currency('',0)}}</i></span></p>
                    <p v-if="isAdminOrFirstLevelTeamLeader" class="sub-count"><span @click="goToDetail($event, {key: 'leaderFeedbackData'})">Tracking List周报反馈数据</span></p>
                </div>
            </li>
            <li class="performance-item">
                <font-icon class="item-icon" href="#icon-offer"></font-icon>
                <div class="item-content">
                    <!-- <p class="main-count"><span @click="jumpOfferStatement($event, 'offer')">Offer <i>{{offerCount | currency('',0)}}</i></span></p>
                    <p class="sub-count" v-if="isOpenPerformanceCalculation"><span @click="jumpOfferStatement($event, 'relateOffer')">相关Offer <i>{{relateOfferCount | currency('',0)}}</i></span></p> -->
                    <p class="main-count"><span @click="jumpOfferStatement($event, 'offer')">Offer <i>{{data.offers | currency('',0)}}</i></span></p>
                    <p class="sub-count" v-if="isOpenPerformanceCalculation"><span @click="jumpOfferStatement($event, 'relateOffer')">相关Offer <i>{{data.relateOffers | currency('',0)}}</i></span></p>
                    <!-- v-if="userInfo.isAdministrator" -->
                    <p class="sub-count">   
                        <span @click="jumpOfferStatement($event, 'offerList')" >Offering List 
                            <i>{{data.offeringListCount | currency('',0)}}</i>
                        </span>
                    </p>
                </div>
            </li>
            <li v-if="userInfo.isAdministrator || userInfo.isAdminOrTeamLeader" class="performance-item">
                <font-icon class="item-icon" href="#icon-jiaofumubiao"></font-icon>
                <div class="item-content">
                    <p class="sub-count sub-count-padding"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '0'})">交付目标-客户</span></p>
                    <p class="sub-count"><span @click="goToDetail($event, {key: 'deliveryTargetReport', deliveryTargetType: '1'})">交付目标-成员</span></p>
                </div>
            </li>
            <!-- 团队积分 -->
            <li v-if="(userInfo.isAdministrator || userInfo.isAdminOrTeamLeader 
            || userInfo.roleCodeList.includes('InternalControl')) && userInfo.isCFUser" class="performance-item performance-item-single">
                <font-icon class="item-icon" href="#icon-jifen"></font-icon>
                <div class="item-content">
                    <p class="sub-count sub-count-padding" v-if="!userInfo.roleCodeList.includes('InternalControl')"><span @click="goToDetail($event, {key: 'teamPointsReport', teamPointsType: '0'})">团队积分</span></p>
                    <p class="sub-count sub-count-padding"><span @click="goToDetail($event, {key: 'teamWeekReport'})">推荐面试情况</span></p>
                </div>
            </li>
            
            <li class="performance-item performance-item-single">
                <font-icon class="item-icon" href="#icon-a-bianzu3"></font-icon>
                <div class="item-content">
                    <p class="sub-count sub-count"><span @click="goToDetail($event, {key: 'evaluateCountReport'})">评价数 <i>{{recommendationEvaluates | currency('',0)}}</i></span></p>
                    <p class="sub-count sub-count-padding"><span @click="goToDetail($event, {key: 'waitEvaluateNotExpireCountReport'})">待评价未过期数 <i>{{canRecommendationNotExpireEvaluates | currency('',0)}}</i></span></p>
                    <p class="sub-count sub-count-padding"><span @click="goToDetail($event, {key: 'waitEvaluateExpireCountReport'})">待评价已过期数 <i>{{canRecommendationExpiredEvaluates | currency('',0)}}</i></span></p>
                </div>
            </li>

        </ul>
          
        <subscribe-modal 
            ref="subscribeModal" 
            :subscribed-count="subscribedCount" 
            v-on:refresh-list="getSubscribeData"
        >
        </subscribe-modal>

    </div>
</template>
<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import { statistics as statisticsUrl } from '#/js/config/api.json';
import { offerReport as offerReportUrl } from '#/js/config/javaApi.json';
import moment from 'moment';
import SubscribeModal from './subscribeDialog.vue';
import talentPoolService from '#/js/service/talentPoolService.js';


const DEFAULT_PARAM = {
        startDate: '',
        endDate: '',
        filterMarketJob: true,
        filterInternalJob: true,
        dimension: 1
    }
export default {
    name: 'performanceTeamData',
    components:{
        TeamMemberSelect,
        SubscribeModal
    },
    props: {
        page: String  //personalData: 个人数据， teamData: 团队数据
    },
    data() {
        return {
            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            data: {
                "offers": '',
                "relatedOffers": '',
                "candidates": '',
                "completeCandidates": '',
                "profileCandidates": '',
                "inquiryLogs": '',
                "validInquiryLogs": '',
                "calls": '',
                "validCalls": '',
                "jobs": '',
                "keyUpdateJobs": '',
                "orders": '',
                "marketOrders": '',
                "internalOrders": '',
                "recommendCandidates": '',
                "acceptedRecommendCandidates": '',
                "rejectedRecommendCandidates": '',
                "candidateRecommends": '',
                "acceptedCandidateRecommends": '',
                "rejectedCandidateRecommends": '',
                "interviewCandidates": '',
                "interviewPassedCandidates": '',
                "interviewFailedCandidates": '',
                "tackingLists": "",
                "newJDTRegistrant": '',
                "jobShareInJDT": "",
                "receiveIMMessageByJDTUsers": "",
                "sendIMMessageToJDTUsers": "",
            },
            recommendationEvaluates: "",
            canRecommendationNotExpireEvaluates: "",
            canRecommendationExpiredEvaluates: "",
            // offerCount: 0,
            // relateOfferCount: 0,
            param: Object.assign({}, DEFAULT_PARAM,{
                    userIds: []
                }),
            dateRange: [],
            subscribedCountFetched: false,
            subscribedCount: 0,
            loading: false,
            
            isAdminOrFirstLevelTeamLeader: false
        }
    },
    mounted() {
        this.subscribeModal = this.$refs.subscribeModal;
        let yestoday = moment(new Date()).add(-1, 'days');
        this.dateRange = [yestoday, yestoday];
        this.param = Object.assign({},this.param,{
           startDate: this.dateRange[0].format('YYYY-MM-DD'),
           endDate: this.dateRange[1].format('YYYY-MM-DD')
        });
        if((this.page == 'teamData' && (this.$store.state.user.userInfo.isAdministrator || this.$store.state.user.userInfo.isAdminOrTeamLeader)) || this.isTeamDataPermission) {
            this.loading = true;
            // this.$refs.teamSelect.getHistoryTeamData(true);
            this.getSubscribeData();
            this.getTeamInfo();
        } else {
            shortTips("权限不足，请联系管理员")
            sessionStorage.setItem('tabItems', JSON.stringify([]));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
        if(this.page == 'personalData') {
            this.param.userIds = [this.userInfo.id];
            this.refresh();
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isOpenPerformanceCalculation() {
            return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
        },
        isTeamDataPermission() {
            return this.userInfo.privilegeCodeList?.includes('TeamData') || this.userInfo.privilegeCodeList?.includes('LimitedTeamData');
        },
        isHRBP() {
            return this.userInfo.roleCodeList?.includes('BRHRBP') || this.userInfo.roleCodeList?.includes('LRHRBP');
        },
    },
    methods: {
        getTeamInfo() {
            talentPoolService
                .getTeamInfo()
                .then((res) => {
                    if(res.isAdmin || res.isFirstLevelTeamLeader) {
                        this.isAdminOrFirstLevelTeamLeader = true;
                    }
                })
        },
        // 员工选取
        employeeSelect(userIds, text) {
            this.param.userIds = userIds;
            this.selectedMembersText = text;
            this.refresh();
            this.teamMemberPopoverVisible = false;
        },
        refresh(param){
            this.loading = true;
            let url = statisticsUrl.url_firm_statistics;
            this.param = Object.assign({},this.param,param);
            _request({
                method: 'POST',
                url: url,
                data: this.param
            }).then(res => {
                this.data = Object.assign({},this.data,res);
                this.recommendationEvaluates = res.recommendationEvaluates || 0;
                this.canRecommendationNotExpireEvaluates = res.canRecommendationNotExpireEvaluates || 0;
                this.canRecommendationExpiredEvaluates = res.canRecommendationExpiredEvaluates || 0;
                this.loading = false;
            }).catch(err => {
                console.log(err)
                this.loading = false;
            }).finally(() => {
                // Todo:这里异步请求不优雅，为了解决bug，后期优化
                // let getCountParam = {
                //     recommendStartTime: this.param.startDate.replace(/\//g, '-'),
                //     recommendEndTime: this.param.endDate.replace(/\//g, '-'),
                //     recommendOwnerIds: this.param.userIds
                // }

                // _request({
                //     method: 'POST',
                //     baseURL: 'LbdJavaApi',
                //     javaProject: 'performance',
                //     url: offerReportUrl.get_offer_count,
                //     data: getCountParam
                // }).then(res =>{
                //     this.offerCount = res.offerCount;
                //     this.relateOfferCount = res.relateOfferCount;
                // }).finally(() => {
                    // 从java获取部分统计数据，其它数据从以前的接口获取
                    const getSummaryParam = {
                        startDate: this.param.startDate.replace(/\//g, '-'),
                        endDate: this.param.endDate.replace(/\//g, '-'),
                        userIds: this.param.userIds,
                    };
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: offerReportUrl.get_statistics_summary,
                        data: getSummaryParam
                    }).then(res =>{
                        this.data = Object.assign({}, res);
                    });
                // })
            });

        },
        handleDate(value){
            this.param.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.param.endDate = moment(value[1]).format('YYYY-MM-DD');
            if(this.page == 'teamData') {
                this.loading = true;
                this.$refs.teamSelect.getHistoryTeamData(true);
            }else if(this.page == 'personalData') {
                this.refresh();
            }
        },
        goToDetail(ev, json){
            ev.stopPropagation();
            this.jumpDetail(json);
        },
        jumpDetail(data) {
            let key = data.key;

            if(key == 'trackingListDetails') {
                _tracker.track('openTrackingListReport');
            }

            let json = {};
            let { userIds, startDate, endDate } = this.param;
            let isFromPerformance = true;
            json = { userIds, startDate, endDate, isFromPerformance };
            if(data.userIds) {
                json.userIds = data.userIds;
            }
            json.pageType = this.page;
            Object.assign(json,data);
            sessionStorage.removeItem('perpormancesDetailData');
            localStorage.setItem('perpormancesDetailData', JSON.stringify(json));
            if(key == 'jdtReportData') {
                _tracker.track('openJdtReport', JSON.stringify({
                    type: data.jdtReportDataType
                }));
                window.open('/Headhunting/MyCompany.html#/jdtReport', '_blank');
                // window.open('/#/jdtReport', '_blank');
            } else if(key == 'deliveryTargetReport') {
                _tracker.track('openDeliveryTargetReport', JSON.stringify({
                    type: data.deliveryTargetType
                }));
                window.open('/Headhunting/MyCompany.html#/deliveryTarget', '_blank');
                // window.open('/#/deliveryTarget', '_blank');
            } else if(key == 'teamPointsReport') {
                _tracker.track('openTeamPointsReport', JSON.stringify({
                    type: data.teamPointsType
                }));
                window.open('/Headhunting/MyCompany.html#/teamPoints', '_blank');
                // window.open('/#/teamPoints', '_blank');
            } else if(key == 'leaderFeedbackData') {
                _tracker.track('openLeaderFeedbackData');
                window.open('/Headhunting/MyCompany.html#/leaderFeedbackData', '_blank');
                // window.open('/#/leaderFeedbackData', '_blank');
            } else if(key == 'evaluateCountReport') {
                sessionStorage.removeItem('evaluateCountReport');

                _tracker.track('openEvaluateCountReport', JSON.stringify({
                    evaluateStatus: 2
                }));
                window.open('/Headhunting/MyCompany.html#/evaluateCountReport?status=2', '_blank');
                // window.open('/#/evaluateCountReport?status=2', '_blank');
            } else if(key == 'waitEvaluateNotExpireCountReport') {
                sessionStorage.removeItem('evaluateCountReport');

                _tracker.track('openEvaluateCountReport', JSON.stringify({
                    evaluateStatus: 1
                }));
                window.open('/Headhunting/MyCompany.html#/evaluateCountReport?status=1', '_blank');
                // window.open('/#/evaluateCountReport?status=1', '_blank');
            } else if(key == 'waitEvaluateExpireCountReport') {
                sessionStorage.removeItem('evaluateCountReport');

                _tracker.track('openEvaluateCountReport', JSON.stringify({
                    evaluateStatus: 4
                }));
                window.open('/Headhunting/MyCompany.html#/evaluateCountReport?status=4', '_blank');
                // window.open('/#/evaluateCountReport?status=4', '_blank');
            } else if(key == 'teamWeekReport') {
                window.open('/Headhunting/MyCompany.html#/weekReport', '_blank');
                // window.open(`/#/weekReport`, '_blank');
            } else {
                window.open('/Headhunting/MyCompany.html#/performancesDetail', '_blank');
                // window.open('/#/performancesDetail', '_blank');
            }
            return false;
        },
        jumpOfferStatement(ev, type) {
            ev.stopPropagation();
            let json = {};
            let { userIds, startDate, endDate } = this.param;
            json = { userIds, startDate, endDate, isTeam: 1, selectedMembersText: this.selectedMembersText};
            sessionStorage.removeItem('offerReportData');
            localStorage.setItem('offerReportData', JSON.stringify(json));
            if(type == 'offer') {
                _tracker.track('clickOfferReportForm');
                // window.open('/Headhunting/MyCompany.html#/offerReport?type=offer');
            }else if(type == 'relateOffer') {
                _tracker.track('clickInvolvedOfferReportForm');
                // window.open('/Headhunting/MyCompany.html#/offerReport?type=relateOffer');
            }else if(type == 'offerList') {
                // window.open('/Headhunting/MyCompany.html#/offerReport?type=offerList');
            }
            window.open(`/Headhunting/MyCompany.html#/offerReport?type=${type}`);
            // window.open(`/#/offerReport?type=${type}`);
            return false;
        },
        handleShowSubscribe() {
            if(this.subscribedCount > 0) {
                this.subscribeModal.addSubscribeShow = false;
            }else {
                this.subscribeModal.addSubscribeShow = true;
            }
            this.subscribeModal.showModal();
        },
        getSubscribeData() {
            let url = statisticsUrl.subscribe_list;
            _request({
                method: 'POST',
                url: url,
            }).then(res => {
                this.subscribedCountFetched = true;
                this.subscribedCount = res.length;
            }).catch(err => {
                console.log(err)
            })

        },
        
    }
}
</script>
<style lang="scss" scoped>
.performance-data{
    position: relative;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 20px;
    .pannel-title{
        .title-left{
            .title-text{
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
        }
        // .title-right{}
    }

    /deep/ .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-pagination-bullets {
            bottom: 0;

            .swiper-pagination-bullet {
                width:12px;
                height: 12px;
            }
            .swiper-pagination-bullet-active {
                background: $primary;
            }
        }
    }

    .performance-list{
        height: 100%;
        padding: 20px 0;
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 0 8px;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        .performance-item{
            // width: 25%;
            // width: 16%;
            // max-width: 16%;
            text-align: center;
            align-self: center;
            margin-bottom: 40px;
            &:nth-child(6n){
                .item-content{
                    border: none;
                }
            }

            &.performance-item-single .item-content {
                border: none;
            }

            .item-icon{
                font-size: 30px;
            }
            .item-content{
                border-right: 1px solid #eee;
                height: 66px;
                margin-top: 10px;
                p{
                    margin-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #38BC9D;
                        }
                    }
                }
                i{
                    margin-left: 10px;
                }
                .main-count{
                    // font-size: 16px;
                    font-size: 14px;
                    color: #444;
                    margin-bottom: 10px;
                    i{
                        // font-size: 16px;
                        font-size: 14px;
                        color: #38BC9D;
                        font-weight: bold;
                    }
                }
                .sub-count{
                    font-size: 14px;
                    color: #666;
                    margin-top: 6px;
                    i{
                        font-weight: bold;
                        color: #38BC9D;
                    }
                }
                .sub-count-padding {
                    padding-top: 6px;
                }
            }
        }
    }
    .offer-count{
        display: inline-block;
        background: rgba(56,188,157,.1);
        border-radius: 10px;
        font-size: 16px;
        color: #444;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        margin: 0 13px;
        cursor: pointer;
        &:hover{
            color: #38BC9D;
        }

        &.relate-offer-count {
            margin-left: 0;
        }

        >i{
            font-size: 16px;
            color: #38BC9D;
            margin-left: 7px;
        }
    }
    .employee-select-wrap{
        position: relative;
        margin-left: 10px;

        .employee-select-text {
            display: inline-block;
            width: 110px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #d3d3d3;
            border-radius: 16px;
            padding: 0 36px 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover{
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }

        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
            }
            .employee-select-text{
                background: #38BC9D;
                border-color: #38BC9D;
                color: #fff;
            }
        }
    }
    .data-btn{
        display: inline-block;
        border: 1px solid #DDD;
        border-radius: 16px;
        height: 32px;
        line-height: 32px;
        padding-left: 16px;
        color: #666;
        background-color: #fff;
        cursor: pointer;
        &.subscribe-btn{
            position: absolute;
            right: 20px;
            bottom: 0;
            border: none;
            white-space: nowrap;
            &:hover{
                // background: #38BC9D;
                color: $primary;
            }
        }
    }
    /deep/ .date-wrap{
        position: relative;
        cursor: pointer;
        &:hover{
            .el-date-editor--daterange.el-input__inner{
                border-color:#38BC9D;
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
        }
        .el-range-editor.is-active{
            background: #38BC9D;
            .el-range-input{
                background: #38BC9D;
                color: #fff;
            }
            .el-range-separator{
                color: #fff;
            }
        }
        &.triangle-drop-up{
            &::after{
                border-bottom: 12px solid #fff;
            }
        }
        .el-date-editor--daterange.el-input__inner{
            width: 190px;
            border-radius: 16px;
        }
        .el-date-editor .el-range__icon{
            display: none;
        }

        .el-date-editor .el-range__close-icon{
            display: none;
        }
    }
    .triangle-drop{
        &:after{
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
        }
        &-down{
            &:after{
                border-top: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-top: 12px solid #fff;
                }
            }

        }
        &-up{
            &:after{
                border-bottom: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            &:hover{
                &:after{
                    border-bottom: 12px solid #fff;
                }
            }
        }
    }


}
</style>
<style lang="scss">
.team-member-popover.data-team-member-popover {
    transform: translateX(0);
}
.performance-data-range.el-picker-panel{
    .el-date-range-picker__content.is-left{
        border-right: none;
    }
    .el-date-table{
        .el-date-table__row{
            .normal.disabled{
                div{
                    color: #666;
                }
            }
        }
    }
}
</style>
