<template>
    <div class="statistics-param">
        <div class="col-6 source">
            数据源：
            <el-checkbox v-model="checkboxMarketJobActive" @change="checkboxMarketJobChange">市场职位</el-checkbox>
            <el-checkbox v-model="checkboxInternalJobActive" @change="checkboxInternalChange">内部职位</el-checkbox>
        </div>
        <div class="col-6 dimension">
            <span class="label">
                维度统计：
            </span>

            <ul class="tab-list">
                <li class="tab-item" :class="type == 1 ? 'active' : ''" @click="handleDimensionSelect(1)">按次累计</li>
                <li class="tab-item" :class="type == 2 ? 'active' : ''" @click="handleDimensionSelect(2)">按候选人累计</li>
            </ul>
            
        </div>
        <div class="col-6 unit" v-show="showUnitTab">
            <span class="label">
                横轴单位：
            </span>

            <ul class="tab-list">
                <li class="tab-item" :class="scale == 1 ? 'active' : ''" :disabled="disableByDay" @click="handleUnitSelect(1)">日</li>
                <li class="tab-item" :class="scale == 2 ? 'active' : ''" :disabled="disableByWeek" @click="handleUnitSelect(2)">周</li>
                <li class="tab-item" :class="scale == 3 ? 'active' : ''" :disabled="disableByMonth" @click="handleUnitSelect(3)">月</li>
            </ul>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    const FILTER_MARKET_JOB = localStorage.getItem('perpormancesDetailData') && JSON.parse(localStorage.getItem('perpormancesDetailData')).hasOwnProperty('filterMarketJob') ? JSON.parse(localStorage.getItem('perpormancesDetailData')).filterMarketJob : true;
    const FILTER_INTERNAL_JOB = localStorage.getItem('perpormancesDetailData') && JSON.parse(localStorage.getItem('perpormancesDetailData')).hasOwnProperty('filterInternalJob') ? JSON.parse(localStorage.getItem('perpormancesDetailData')).filterInternalJob : true;
    const DIMENSION = localStorage.getItem('perpormancesDetailData') ? JSON.parse(localStorage.getItem('perpormancesDetailData')).dimension : null;

    export default {
        name: "statisticsParam",
        components: {
        },
        data() {
            return {
                checkboxMarketJobActive: FILTER_MARKET_JOB,
                checkboxInternalJobActive: FILTER_INTERNAL_JOB,
                type: DIMENSION || 1,
                scale: 1,
                disableByDay: true,
                disableByWeek: true,
                disableByMonth: true,
                showCompared: true,
            }
        },
        props: {
            showUnitTab: Boolean,
            isMultipleEmployee: Boolean,
        },
        watch: {
        },
        mounted() {
        },
        methods: {
            checkboxMarketJobChange() {
                if(!this.checkJobTypeAvaiable()){
                    setTimeout(() => {
                        this.checkboxMarketJobActive = true;
                    }, 300);
                    return false;
                }
                this.onChange();
            },
            checkboxInternalChange() {
                if(!this.checkJobTypeAvaiable()){
                    setTimeout(() => {
                        this.checkboxInternalJobActive = true;
                    }, 300);
                    return false;
                }
                this.onChange();
            },
            handleDimensionSelect(value) {
                this.type = value;
                this.onChange();
            },
            handleUnitSelect(value) {
                this.scale = value;
                this.onChange();
            },

            checkJobTypeAvaiable() {
                if(!this.checkboxMarketJobActive && !this.checkboxInternalJobActive) {
                    shortTips('最少选择一种职位类别');
                    return false;
                }
                return true;
            },

            onChange() {
                let param = this.getParam();
                this.$emit('param-change', param);
            },

            getParam() {
                return {
                    filterMarketJob: this.checkboxMarketJobActive,
                    filterInternalJob: this.checkboxInternalJobActive,
                    dimension: this.type,
                    scale: this.scale
                }
            },

            onDateRangeChange(startDate, endDate) {
                endDate = moment(endDate);
                startDate = moment(startDate);
                let dateRange = moment.duration(endDate.diff(startDate)).asDays();
                this.disableByDay = dateRange > 14;
                this.disableByWeek = dateRange < 7 || dateRange > 100;
                this.disableByMonth = dateRange < 14;
                let index = dateRange < 14 ? 0 : (dateRange < 60 ? 1 : 2);
                // selection.select(index, null, true);
                let unitValue = [1, 2, 3][index];
                this.scale = unitValue;
                return unitValue;
            },

            updateHeaderSelects(selects) {
                // this.statisticsConversionFunnelCompareHead.selects = selects;
            },

            reset(){
                // this.statisticsConversionFunnelCompareHead.reset();
            }
        },
    }
</script>

<style lang="scss">
@import '#/css/scss/mixins/_clearfix.scss';
statistics-param{
    @include clearfix();
}
 .statistics-param{
    @include clearfix();
    padding: 40px 0 60px;
    text-align: left;

    .tab-list {
        display: inline-block;

        .tab-item {
            display: inline-block;
            padding: 0 4px;
            min-width: 44px;
            height: 30px;
            line-height: 30px;
            margin-right: 10px;
            color: #666;
            background-color: #fff;
            cursor: pointer;

            &.active {
                color: #fff;
                background-color: $primary;
                cursor: default;
            }
        }
    }

    .dimension{
        .tab-list .tab-item {
            background-color: #f6f6f6;

            &.active {
                color: $primary;
                background-color: #fff;
            }
        }
    }

    .active .tab-wrapper {
        color: $primary;
        border-color: $primary;
    }

    .label {
        color: #333;
        line-height: 30px;
        font-weight: normal;
    }

    .unit {
        .tab-item {
            &:first-child {
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }
        }

        .tab-wrapper {
            border: 1px solid #d3d3d3;
            padding: 0;
            width: 44px;
            height: 30px;
            line-height: 28px;
            margin-left: -1px;
            background-color: #fff;
        }

        .tab .active .tab-wrapper {
            color: #fff;
            background-color: $primary;
            border-color: $primary;
        }

        .tab .disabled {
            cursor: not-allowed;

            .tab-wrapper {
                color: #b9b9b9;
            }
        }
    }

    .source{
        line-height: 32px;
        
        .el-checkbox {
            margin-right: 20px;

            .el-checkbox__label {
                padding-left: 4px;
            }
        }
    }

 }

</style>
