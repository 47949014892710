<template>
<div class="job-recommend-change-info" v-loading="loading">
    <!-- <spinner class="modal-box-spinner"></spinner> -->
    <el-row class="row title">
        <el-col :span="6">
            发布时间
        </el-col>
        <el-col :span="6">
            推荐人数
        </el-col>
        <el-col :span="6">
            职位数量
        </el-col>
        <el-col :span="6">
            比例
        </el-col>
    </el-row>
    <div data-bind="foreach:jobRecommendList"
        v-for="(item, index) in jobRecommendList"
        :key="'jobRecommendList_' + index"
        >
        <!-- <div class="row list"> -->
        <el-row class="row list">
            <div class="col-4 week">
                <i v-text="item.text"></i>
                <p v-text="item.day"></p>
            </div>
            <div class="col-12 num">
                <!-- ko if: week -->
                <ul v-if="item.week">
                    <!-- ko if: jobCount -->
                    <li class="clearfix" 
                        v-for="(subItem, subIndex) in item.item"
                        :key="'subItem_'+ subIndex"
                        :class="{'error': ((item.index ==0 && subItem.recommendCount==0) || (item.index !=0 && subItem.recommendCount!=3))}"
                        @click="gotoCompanyJob(index, subIndex)"
                        >
                        <!-- data-bind="click:$parents[1].gotoCompanyJob.bind($data,recommendCount,$parent),css:{error:($parent.index===0&&recommendCount===0)||($parent.index!==0&&recommendCount!==3)}" -->
                        <!-- ko if: recommendCount===3 -->
                        <span v-if="subItem.recommendCount==3">3个以上</span>
                        <!-- /ko -->
                        <!-- ko if: recommendCount===2 -->
                        <span v-if="subItem.recommendCount==2">2个</span>
                        <!-- /ko -->
                        <!-- ko if: recommendCount===1 -->
                        <span v-if="subItem.recommendCount==1">1个</span>
                        <!-- /ko -->
                        <!-- ko if: recommendCount===0 -->
                        <span v-if="subItem.recommendCount==0">0个</span>
                        <!-- /ko -->
                        <span v-text="subItem.jobCount"></span>
                        <span v-text="subItem.scale"></span>
                    </li>
                    <!-- /ko -->
                </ul>
                <!-- /ko -->
                <!-- ko if: !week -->
                <div v-else>无职位发布</div>
                
                <!-- /ko -->
            </div>
        </el-row>
        <!-- </div> -->
    </div>
</div>
  


</template>

<script>


export default {
    components: {
        
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            jobRecommendList: [],
            weekInfo: [
                {
                    text: '第一周',
                    day: '1-7天'
                }, {
                    text: '第二周',
                    day: '8-14天'
                }, {
                    text: '第三周',
                    day: '15-21天'
                }, {
                    text: '第四周',
                    day: '22-30天'
                }, {
                    text: '更久',
                    day: '30天以后'
                }
            ],
            customerIds: '',
            teamIndex: '',
            creatorIds: '',
            customerIds: '',
            
        }
    },
    computed: {
        
    },
    mixins: [
        
    ],
    mounted() {
        
    },
    methods: {
        setData(data, customerIds, teamIndex, creatorIds){
            this.customerIds = customerIds;
            this.teamIndex = teamIndex;
            this.creatorIds = creatorIds;
            if(data.delivery.length){
                data.delivery[0].index = 0;
            }
            data.delivery.forEach((item, index) => {
                item.day = this.weekInfo[index].day;
                item.week = false;
                item.item.forEach(list => {
                    if (list.jobCount) {
                        item.week = true;
                    }
                })
                item.item = item.item.filter(item => item.jobCount);
            })
            this.jobRecommendList = data.delivery;
        },
        // gotoCompanyJob(recommendDegree, parent, $data){
        gotoCompanyJob(index, subIndex){
            const parent = this.jobRecommendList[index];
            const recommendDegree = this.jobRecommendList[index].item[subIndex].recommendCount;
            localStorage.setItem('myCompanyJobsListParams', JSON.stringify({
                recommendDegree: recommendDegree,
                startDate: new Date(parent.startDate).format('yyyy-MM-dd'),
                endDate: new Date(parent.endDate).format('yyyy-MM-dd'),
                customerIds: this.customerIds,
                teamIndex: this.teamIndex,
                creatorIds: this.creatorIds
            }));
            window.open('/MyCompany/Jobs', '_blank');
        }
        
    }
}
</script>

<style lang="scss">
.col-4,.col-12{
    display: inline-block;
}
.col-4{
    width: 25%;
}
.col-12{ width: 75%; }
.job-recommend-change-info {
    text-align: center;
    .title {
        font-size: 16px;
        color: #9b9b9b;
        margin-bottom: 20px;
    }
    .list {
        margin-bottom: 25px;
        // display: table;
        > div {
            // display: table-cell;
            // float: none;
            vertical-align: middle;
        }
        .week {
            i, p {
                vertical-align: middle;
            }
            i {
                font-size: 16px;
                color: #666;
                color: black;
                margin: 0 0 5px;
            }
            p {
                color: #9b9b9b;
                margin: 0;
            }
        }
        .num {
            padding: 0;
            ul {
                li {
                    position: relative;
                    line-height: 30px;
                    background-color: #f8f8f8;
                    margin: 0 0 5px;
                    color: #666;
                    cursor: pointer;
                    text-align: left;
                    // font-size: 0;
                    &:hover {
                        box-shadow: 0 0 3px rgba(50, 50, 50, .3);
                    }
                    &.error {
                        position: relative;
                        color: #dc7070;
                        &:before {
                            position: absolute;
                            font-size: 18px;
                            font-weight: bold;
                            font-family: icon-font !important;
                            left: 20px;
                            top: 1px;
                            content: "\e680";
                        }
                        .icon-font {
                            position: absolute;
                            left: 20px;
                            top: 5px;
                            font-size: 20px;
                            color: #dc7070;
                        }
                    }
                    span {
                        display: inline-block;
                        line-height: 30px;
                        width: 33%;
                        text-align: center;
                        // height: 30px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

</style>
