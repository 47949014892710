<template>
    <statistics-page></statistics-page>
</template>

<script>
    import StatisticsPage from './layout/statisticsPage.vue';

    export default {
        name: "TeamData",
        components: {
            StatisticsPage
        },
        data() {
            return {
            }
        },
        props: {
            user: Object
        }
    }
</script>

<style lang="scss">
    .shortcuts {
        width: 1200px;
        margin: 20px auto 0;
    }

    .data-detail {
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .data-detail > .charts,
    .data-detail > .selected,
    .data-detail > .table {
        width: 1200px;
        margin: auto;
        padding: 0 20px 0;
    }

    .data-detail > div {
        background-color: #fff;
        padding: 0 10px;
    }
    .data-detail > .table {
        min-width: 1200px;
        min-height: 400px;
    }

</style>
