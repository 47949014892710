const BASE_SIZE = '14px',
    GRAY_COLOR = '#666666';

export default {
    chart: {
        type: "column",
        inverted: true,
        height: 800,
        style: {
            fontFamily: 'Arial, 微软雅黑'
        }
    },
    title: {
        text: null
    },
    series: [
        {
            data: [],
            name: '数量',
            minPointLength: 2,
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '',
                pointFormatter: function(){
                    let name = '';
                    switch(this.index){
                        case 0:
                            name = '推荐';
                            break;
                        case 1:
                            name = '接受';
                            break;
                        case 2:
                            name = '安排面试';
                            break;
                        case 3:
                            name = '面试';
                            break;
                        case 4:
                            name = 'Offer';
                            break;
                        default:
                            name = '入职';
                    }
                    return name + '：<b>' + this.y + '</b><br/>';
                }
            }
        }
    ],
    plotOptions: {
        column: {
            color: "#38bc9c",
            pointWidth: 48,
            borderRadius: 4
        }
    },
    xAxis: {
        labels: {
            useHTML: true
        },
        tickWidth: 0,
        lineColor: '#DCDCDC',
        lineWidth: 2,
        allowDecimals: false
    },
    yAxis: {
        title: {
            text: null
        },
        allowDecimals: false,
        showEmpty: true,
        max: 10,
        labels: {
            style: {
                color: GRAY_COLOR,
                fontSize: BASE_SIZE
            }
        },
        gridLineColor: "#F6F6F6",
        gridLineWidth: 2
    },
    legend: {
        useHTML: true,
        align: 'left',
        enabled: false
    },
    tooltip: {
        positioner: function (labelWidth, labelHeight, point) {
            var tooltipX, tooltipY, chart = this.chart;
            if (point.plotX + labelWidth > chart.plotWidth) {
                tooltipX = point.plotX + chart.plotLeft - labelWidth - 20;
            } else {
                tooltipX = point.plotX + chart.plotLeft + 20;
            }
            tooltipY = point.plotY + chart.plotTop - 20;
            return {
                x: tooltipX,
                y: tooltipY
            };
        }
    }
}
