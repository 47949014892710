var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "statistics-param" }, [
    _c(
      "div",
      { staticClass: "col-6 source" },
      [
        _vm._v("\n        数据源：\n        "),
        _c(
          "el-checkbox",
          {
            on: { change: _vm.checkboxMarketJobChange },
            model: {
              value: _vm.checkboxMarketJobActive,
              callback: function ($$v) {
                _vm.checkboxMarketJobActive = $$v
              },
              expression: "checkboxMarketJobActive",
            },
          },
          [_vm._v("市场职位")]
        ),
        _c(
          "el-checkbox",
          {
            on: { change: _vm.checkboxInternalChange },
            model: {
              value: _vm.checkboxInternalJobActive,
              callback: function ($$v) {
                _vm.checkboxInternalJobActive = $$v
              },
              expression: "checkboxInternalJobActive",
            },
          },
          [_vm._v("内部职位")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "col-6 dimension" }, [
      _c("span", { staticClass: "label" }, [
        _vm._v("\n            维度统计：\n        "),
      ]),
      _c("ul", { staticClass: "tab-list" }, [
        _c(
          "li",
          {
            staticClass: "tab-item",
            class: _vm.type == 1 ? "active" : "",
            on: {
              click: function ($event) {
                return _vm.handleDimensionSelect(1)
              },
            },
          },
          [_vm._v("按次累计")]
        ),
        _c(
          "li",
          {
            staticClass: "tab-item",
            class: _vm.type == 2 ? "active" : "",
            on: {
              click: function ($event) {
                return _vm.handleDimensionSelect(2)
              },
            },
          },
          [_vm._v("按候选人累计")]
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showUnitTab,
            expression: "showUnitTab",
          },
        ],
        staticClass: "col-6 unit",
      },
      [
        _c("span", { staticClass: "label" }, [
          _vm._v("\n            横轴单位：\n        "),
        ]),
        _c("ul", { staticClass: "tab-list" }, [
          _c(
            "li",
            {
              staticClass: "tab-item",
              class: _vm.scale == 1 ? "active" : "",
              attrs: { disabled: _vm.disableByDay },
              on: {
                click: function ($event) {
                  return _vm.handleUnitSelect(1)
                },
              },
            },
            [_vm._v("日")]
          ),
          _c(
            "li",
            {
              staticClass: "tab-item",
              class: _vm.scale == 2 ? "active" : "",
              attrs: { disabled: _vm.disableByWeek },
              on: {
                click: function ($event) {
                  return _vm.handleUnitSelect(2)
                },
              },
            },
            [_vm._v("周")]
          ),
          _c(
            "li",
            {
              staticClass: "tab-item",
              class: _vm.scale == 3 ? "active" : "",
              attrs: { disabled: _vm.disableByMonth },
              on: {
                click: function ($event) {
                  return _vm.handleUnitSelect(3)
                },
              },
            },
            [_vm._v("月")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }